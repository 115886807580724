import { useEffect, useState } from "react";


const useTimer = (targetDate) => {

    const [timeLefts, setTimeLeft] = useState();

    const date = new Date(targetDate)


    const calculateTimeLeft = () => {

      const now = new Date().getTime();
      const distance = date?.getTime() - now;

        let timeLeft = {};

        if (distance > 0) {
            timeLeft = {
               days : Math.floor(distance / (1000 * 60 * 60 * 24)),
               hours : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
               minutes : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
               seconds : Math.floor((distance % (1000 * 60)) / 1000),
            };
        }else{
          timeLeft = {
            dateOver: true,
          }
        }

        return timeLeft;
    }

    useEffect(() => {
        const timer = setTimeout(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearTimeout(timer);
      }, [calculateTimeLeft()]);


    return [timeLefts];
};

export default useTimer;