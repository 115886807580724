import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "../../Utils/Axios.config";
import attendance1 from "../../assets/icons/attendance2.png";

const AttendanceCreateName = () => {
  const [AttendanceClass, setAttendanceClass] = useState("Six");

  const handleAttendanceName = () => {
    const formData = {
      AttendanceName: AttendanceClass,
    };

    axios
      .post("/attendance/create", formData)
      .then((response) => {
        console.log(response.data);
        toast.success("Successfully create the attendance Name");
        // do something with the response
      })
      .catch((error) => {
        console.error(error);
        toast.error("Opps No Already crated this name..!!");
        // handle the error
      });
  };
  return (
    <div>
      <input type="checkbox" id="show-attendance" className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box pb-8 mb-10 sm:mb-0 bg-blue-100">
          <div className="text-center">
            <img src={attendance1} className="w-20 mx-auto" alt="delete" />
          </div>
          <div>
            <div className="w-full bg-white p-5 mt-5 rounded-3xl">
              <h1 className="text-3xl text-secondary font-bold text-center my-5">
                Select Attendance Class section
              </h1>
              <p className="text-green-800 mb-1">Select Class</p>
              <select
                onChange={(e) => setAttendanceClass(e.target.value)}
                className="select sm:select-md select-sm select-bordered select-success rounded-md w-full"
              >
                <option value="Six" select>
                  Six
                </option>
                <option value="Play">Play</option>
                <option value="Nursery">Nursery</option>
                <option value="One">One</option>
                <option value="Two">Two</option>
                <option value="Three">Three</option>
                <option value="Four">Four</option>
                <option value="Five">Five</option>
                <option value="SevenBoys">SevenBoys</option>
                <option value="SevenGirls">SevenGirls</option>
                <option value="EightBoys">EightBoys</option>
                <option value="EightGirls">EightGirls</option>
                <option value="NineBoys">NineBoys</option>
                <option value="NineGirls">NineGirls</option>
                <option value="TenBoys">TenBoys</option>
                <option value="TenGirls">TenGirls</option>
              </select>
              <div className="p-4 flex items-center justify-center gap-3 mt-5 rounded-md bg-white">
                <button
                  onClick={handleAttendanceName}
                  className="btn w-[100px] btn-primary text-white btn-sm"
                >
                  submit
                </button>
                <label
                  htmlFor="show-attendance"
                  className="btn btn-sm w-[100px]"
                >
                  Cancel
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceCreateName;
