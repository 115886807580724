import React from 'react';
import Footer from '../../Shared/Footer';
import Navbar from '../../Shared/Navbar';
import Banner from './Banner';
import BannerTop from './BannerTop';
import Classes from './Classes';
import ContactUs from './ContactUs';
import Director from './Director';
import FAQ from './FAQ';
import Features from './Features';
import Review from './Review';
import Rewords from './Rewords';
import Teachers from './Teachers';
import bg1 from '../../assets/images/banks.jpg'
import RewordStudent from './RewordStudent';


const Home = (userClass) => {
    return (
        <div className="w-full relative bg-[#d7ebe4]">
            <Navbar userClass={userClass}></Navbar>
            <Banner></Banner>
            <BannerTop />
            <div>
                <Features />
            </div>
            <div className=' my-10 py-10 bg-[#fff]'>
                <RewordStudent />
            </div>
            <div className='max-w-7xl mx-auto px-2 md:px-8 lg:px-16 ox-hidden'>
                <Classes />
            </div>
            <div className='my-10 bg-[#fff] py-10'>
                <Director />
            </div>
            <div className='max-w-7xl mx-auto px-2 md:px-8 lg:px-16 ox-hidden'>
                <FAQ />
            </div>
            <div className=' my-10 py-10 bg-[#fff]'>
                <Teachers />
            </div>
            <div className='max-w-7xl mx-auto px-2 md:px-8 lg:px-16 ox-hidden'>
                <Rewords />
                <ContactUs />
                <Review />
            </div>
            <Footer />
        </div>
    );
};

export default Home;