
import React from 'react';
import question from '../../assets/icons/question.png'

const FAQ = () => {
    return (
        <div className='my-12 sm:my-20'>
            <div className='sm:flex w-full gap-4 justify-between'>
                <div className="w-full sm:text-left text-center">
                    <div className='w-20 sm:w-28 sm:mx-0 mx-auto'>
                        <img src={question} className="w-full" alt="question" />
                    </div>
                    <div className='my-5'>
                        <h1 className='text-3xl mb-3 sm:text-4xl font-all font-bold'>সচরাচর জানতে চাওয়া</h1>
                        <h1 className='text-3xl sm:text-4xl font-all font-bold text-purple-700'>প্রশ্নের উত্তর</h1>
                    </div>
                    <p className='font-all text-xl'>আপনাদের কমন কিছু প্রশ্নের উত্তর আমরা এখানে লিস্ট করে দিয়েছি। আমাদের কে প্রশ্ন করার পূর্বে এই লিস্টটি একবার পড়ে নেয়ার অনুরোধ থাকলো। তাহলে আমাদের উত্তরের জন্য আপনাকে অপেক্ষা করতে হবেনা এবং আপনার মূল্যবান সময় বেঁচে যাবে।</p>
                </div>
                <div className="w-full font-all min-w-300 mt-6 sm:mt-0 overflow-y-scroll rounded-lg py-2 max-h-[400px]">
                    
                    <div tabIndex={0} className="collapse group collapse-arrow border border-base-200 bg-base-100 rounded-t-lg">
                        <div className="collapse-title text-[14px] sm:text-[16px] group-focus:text-white group-focus:bg-[#7a2fb8] font-bold">
                        1.কি কি ডকুমেন্ট দরকার ভর্তি প্রক্রিয়ায়? 
                        </div>
                        <div className="collapse-content"> 
                            <p className='mt-1 sm:text-md text-sm'>উত্তর: স্কুলের ভর্তি প্রক্রিয়া প্রকাশিত হয় স্কুলের ওয়েবসাইটে। সাধারণত একটি জন্মসনদ, একটি প্রমাণপত্র, একটি পাসপোর্ট সাইজ ছবি এবং একটি ঠিকানা প্রমাণ করতে হতে পারে।</p>
                        </div>
                    </div>
                    
                    <div tabIndex={0} className="collapse group collapse-arrow border border-base-200 bg-base-100">
                        <div className="collapse-title text-[14px] sm:text-[16px] group-focus:text-white group-focus:bg-[#7a2fb8] font-bold">
                        2.ভর্তি ফরম কীভাবে পূরণ করতে হবে? 
                        </div>
                        <div className="collapse-content"> 
                            <p className='mt-1 sm:text-md text-sm'>উত্তর: স্কুলের ভর্তি প্রক্রিয়া সম্পর্কে জানতে পারেন স্কুলের ওয়েবসাইটে বা স্কুলে ফরম পূরণের জন্য সাধারণত একটি অনলাইন ফরম এবং পেপার ফরম দুটি পদ্ধতি ব্যবহার করা হয়। আপনি স্কুলের ওয়েবসাইটে লগ ইন করে অনলাইন ফরম পূরণ করতে পারেন। আপনি যদি পেপার ফরম ব্যবহার করতে চান তবে স্কুলে গিয়ে একটি ফরম পান এবং এটি পূরণ করতে হবে।</p>
                        </div>
                    </div>

                    <div tabIndex={0} className="collapse group collapse-arrow border border-base-200 bg-base-100">
                        <div className="collapse-title text-[14px] sm:text-[16px] group-focus:text-white group-focus:bg-[#7a2fb8] font-bold">
                        3.স্কুলে যে পরীক্ষাগুলি হতে পারে তা কী? 
                        </div>
                        <div className="collapse-content"> 
                            <p className='mt-1 sm:text-md text-sm'>উত্তর: স্কুলে ভর্তি প্রক্রিয়ায় সাধারণত প্রাথমিক শিক্ষা পর্যায়ে পরীক্ষা হতে পারে। এছাড়াও, কিছু স্কুল একটি ভর্তি পরীক্ষা নেওয়ার পরিকল্পনা করে। পরীক্ষার জন্য সময় সম্পর্কে জানতে স্কুলের ওয়েবসাইট পরিদর্শন করুন বা স্কুলে ফোন করে জানুন।</p>
                        </div>
                    </div>

                    <div tabIndex={0} className="collapse group collapse-arrow border border-base-200 bg-base-100">
                        <div className="collapse-title text-[14px] sm:text-[16px] group-focus:text-white group-focus:bg-[#7a2fb8] font-bold">
                        4.স্কুলে ভর্তি হওয়ার জন্য পরীক্ষা নেওয়া লাগবে কি না? 
                        </div>
                        <div className="collapse-content"> 
                            <p className='mt-1 sm:text-md text-sm'>উত্তর: স্কুলে ভর্তি হওয়ার জন্য পরীক্ষা নেওয়া বাধ্যতামূলক নয়। তবে স্কুলের নীতিমালা প্রকাশ করে উল্লেখ করে থাকে যে প্রয়োজন হলে পরীক্ষা নেওয়া হতে পারে। </p>
                        </div>
                    </div>

                    <div tabIndex={0} className="collapse group collapse-arrow border border-base-200 bg-base-100">
                        <div className="collapse-title text-[14px] sm:text-[16px] group-focus:text-white group-focus:bg-[#7a2fb8] font-bold">
                        5.স্কুলে ভর্তি হওয়ার জন্য কত বয়স থাকতে হবে? 
                        </div>
                        <div className="collapse-content"> 
                            <p className='mt-1 sm:text-md text-sm'>উত্তর: স্কুলে ভর্তি হওয়ার জন্য স্থানীয় স্কুলের নীতিমালা অনুসারে বয়স সীমা নির্ধারণ করা হয়। সাধারণত বয়স সীমা ৪-৫ বছর হতে পারে। তবে আরও কম বয়সের শিশুদের জন্য প্রাথমিক শিক্ষা উপলব্ধ করানো হয়। স্থানীয় স্কুলের নীতিমালা অনুসারে বয়স সীমা নির্ধারণ করা হয় যাতে সমস্ত ছাত্রদের সঠিক মানসিক এবং শারীরিক উন্নয়ন হতে পারে। সেই সাথে ছাত্রদের মজার এবং শিক্ষাপ্রদ উপকরণগুলির ব্যবহার করার জন্য ও সংগঠিত একটি শিক্ষামূলক পরিবেশ তৈরি করার জন্য স্কুল বয়স সীমা নির্ধারণ করে।</p>
                        </div>
                    </div>

                    <div tabIndex={0} className="collapse group collapse-arrow border border-base-200 bg-base-100 rounded-b-lg">
                        <div className="collapse-title text-[14px] sm:text-[16px] group-focus:text-white group-focus:bg-[#7a2fb8] font-bold">
                        6.স্কুলে ভর্তি হলে মোবাইল ফোন চালাতে পারবে কি? 
                        </div>
                        <div className="collapse-content"> 
                            <p className='mt-1 sm:text-md text-sm'>উত্তর মোবাইল ফোন চালাতে পারবেন না। যদি মোবাইল ফোন শিক্ষার্থীকে দেখা যায় তাহলে তার জন্য শাস্তির ব্যবস্থা রয়েছে। কোনভাবেই শিক্ষার্থীদের মোবাইল ফোন, অ্যান্ড্রয়েড ফোন ব্যবহার করা যাবে না। </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default FAQ;