import React from 'react';
import { AiOutlineCamera, AiOutlineFileMarkdown } from 'react-icons/ai';
import { BiMessageDetail } from 'react-icons/bi';
import { MdCastForEducation } from 'react-icons/md';
import { RiProjector2Line } from 'react-icons/ri';
import { GiTeacher } from 'react-icons/gi';

const Features = () => {
    return (
        <div className='max-w-7xl mb-10 mx-auto px-2 md:px-8 lg:px-16 ox-hidden'>
            <div className='text-center font-all my-12'>
                <h1 className='text-2xl sm:text-4 mt-5 mb-1 md:text-5xl sm:mb-8 font-bold'>একনজরে স্কুলের <span className='text-purple-700'>সুবিধা</span> সমূহ</h1>
                <p className='font-bold text-sm sm:text-[18px]'>স্কুলের সুবিধা গুলো দেখে নিন</p>
            </div>
            <div className='border feature-grid p-3 sm:p-5 sm:gird border-[#e9ecef] bg-[#f8fafc] rounded-xl'>
                <div className='text-center overflow-hidden sm:border-r border-b border-[#e9ecef] p-4'>
                    <div className='hover:scale-[1.1] duration-500'>
                        <span className="text-center flex justify-center text-purple-700">
                            <AiOutlineCamera size={40} />
                        </span>
                        <div>
                            <h3 className='font-all sm:element-font-1 my-1 font-bold text-[20px]'>সিসি ক্যামেরার দ্বারা ক্লাস পর্যবেক্ষণ করা হয়।</h3>
                            <p className='font-all'>সর্বক্ষণ প্রত্যেক শ্রেণিকক্ষে সিসি ক্যামেরা দ্বারা ক্লাস পর্যবেক্ষণ করা হয়।</p>
                        </div>
                    </div>
                </div>
                <div className='text-center p-4 overflow-hidden lg:border-r border-b border-[#e9ecef]'>
                    <div className="hover:scale-[1.1] duration-500">
                        <span className="text-center flex justify-center text-purple-700">
                            <BiMessageDetail size={40} />
                        </span>
                        <div>
                            <h3 className='font-all sm:element-font-1 my-1 font-bold text-[20px]'>অনুপস্থিত মেসেজ পাঠানো হবে</h3>
                            <p className='font-all'>শিক্ষার্থী স্কুলে না আসলে অনুপস্থিত মেসেজ পাঠানোর মাধ্যমে যোগাযোগ করা হবে</p>
                        </div>
                    </div>
                </div>
                <div className='text-center overflow-hidden p-4 sm:border-r lg:border-r-0 border-b border-[#e9ecef]'>
                    <div className='hover:scale-[1.1] duration-500'>
                        <span className="text-center flex justify-center text-purple-700">
                            <AiOutlineFileMarkdown size={40} />
                        </span>
                        <div>
                            <h3 className='font-all sm:element-font-1 my-1 font-bold text-[20px]'>অনলাইনে রেজাল্ট দেখার সুবিধা</h3>
                            <p className='font-all'>অনলাইনে রেজাল্ট দেখাও ফিডব্যাক সহকারে অনলাইনে রেজাল্ট দেখার সুবিধা ডাউনলোড করতে পারবেন।</p>
                        </div>
                    </div>
                </div>
                <div className='text-center overflow-hidden p-4 border-b md:border-b-0 lg:border-r border-[#e9ecef]'>
                    <div className='hover:scale-[1.1] duration-500'>
                        <span className="text-center flex justify-center text-purple-700">
                            <RiProjector2Line size={40} />
                        </span>
                        <div>
                            <h3 className='font-all sm:element-font-1 my-1 font-bold text-[20px]'>প্রজেক্টরের মাধ্যমে ক্লাস নেওয়া হবে।</h3>
                            <p className='font-all'>কঠিন এবং আইসিটি সাবজেক্ট লোক প্রজেক্টরের মাধ্যমে ক্লাস নিয়ে বুঝানো হবে.</p>
                        </div>
                    </div>
                </div>
                <div className='text-center overflow-hidden border-b md:border-b-0 sm:border-r border-[#e9ecef] p-4'>
                    <div className='hover:scale-[1.1] duration-500'>
                        <span className="text-center flex justify-center text-purple-700">
                            <MdCastForEducation size={40} />
                        </span>
                        <div>
                            <h3 className='font-all sm:element-font-1 my-1 font-bold text-[20px]'>উন্নত মানের শিক্ষা ব্যবস্থা দেওয়া হবে</h3>
                            <p className='font-all'>দক্ষ শিক্ষক দ্বারা শিক্ষার্থীদের উন্নতমানের  শিক্ষা দেওয়া হবে এবং  উন্নত মানের শিক্ষাব্যবস্থা থাকবে।</p>
                        </div>
                    </div>
                </div>
                <div className='text-center overflow-hidden p-4'>
                    <div className='hover:scale-[1.1] duration-500'>
                        <span className="text-center flex justify-center text-purple-700">
                            <GiTeacher size={40} />
                        </span>
                        <div>
                            <h3 className='font-all sm:element-font-1 my-1 font-bold text-[20px]'>পরীক্ষার মাধ্যমে শিক্ষার মান যাচাই</h3>
                            <p className='font-all'>পরীক্ষার মাধ্যমে শিক্ষার্থীদের প্রতিষ্ঠানের নির্দিষ্ট পাঠ্যক্রমে উন্নয়নের মান যাচাই করা হয়।</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Features;