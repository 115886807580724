import React, { useState } from 'react';
import { toast} from 'react-toastify';
import { ProgressBar } from 'react-loader-spinner';
import {AiOutlineSend} from "react-icons/ai"
import axios from 'axios';


const QuickSms = ({setQuickSms, quickSms}) => {
    const [disable, setDisable] = useState(false);
    const [error, setError] = useState();
    const [message, setMessage] = useState('প্রিয় অভিভাবক, ধন্যবাদ। নবজাগরণ একাডেমি');
    console.log(message.length, "charesters")
    // send Absent Sms
    const API_URL = 'https://bulksmsbd.net/api/smsapi';
    const apiKey = 'I6dXEMHybK6zQZzr2UJd';
    const SENDER_ID = '8809617611038';
    const RECIPIENT_NUMBERS = quickSms?.phoneNumber2 ? quickSms?.phoneNumber2 : quickSms?.phoneNumber;

    const handleQuickSms = () => {
        setDisable(true)

        const data = {
            api_key: apiKey,
            senderid: SENDER_ID,
            number: RECIPIENT_NUMBERS,
            message: message,
        };

        axios.post(API_URL, data)
        .then(response => {
            if(response.data.response_code === 202){
                toast.success('SMS sent successfully!')
                setError('')
                setQuickSms(null);
            }else{
                toast.error(response.data.error_message)
                setError(response.data.error_message)

            }
            console.log(response.data);
            setDisable(false);
        })
        .catch(error => {
            console.error('Failed to send SMS:', error);
            toast.error('some this is worng')
            setDisable(false);
        });
    }


    return (
        <div>
            <input type="checkbox" id="Quick-sms" className="modal-toggle" />
            <div className="modal h-screen modal-bottom sm:modal-middle">
                <div className="modal-box pb-8 mb-10 bg-blue-100 h-auto px-2 py-4 sm:py-8 sm:px-4">
                    <div className='text-xl text-purple-700 text-center'>
                       <div className='w-full flex justify-center'>
                        <AiOutlineSend size={40} />
                       </div>

                       <div className='text-center my-4'>
                            <h3 className='text-xl font-bold'>{quickSms?.firstName} {quickSms?.lastName}</h3>
                            <h3>{quickSms?.class}</h3>
                       </div>
                    
                       <div className="my-4 relative">
                            <textarea name='Message' value={message} onChange={(e) => setMessage(e.target.value) } className="textarea w-full h-[130px] textarea-bordered" placeholder="Enter Your Message"></textarea>
                            <p className='absolute -top-1 -right-1 text-[12px] text-purple-700 bg-purple-100 leading-4 px-1 rounded-md'>{message.length}</p>
                        </div>
                    </div>
                    <div className='p-4 mt-5 shadow-md rounded-md bg-white'>
                        { disable &&

                            <div className='w-full flex items-center justify-center'>
                                <ProgressBar
                                    height="80"
                                    width="80"
                                    ariaLabel="progress-bar-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="progress-bar-wrapper"
                                    borderColor = '#F4442E'
                                    barColor = '#51E5FF'
                                />
                            </div>

                        }
                        <p className='text-sm text-accent mb-2 text-center'>{error}</p>
                        <div className='flex items-center justify-center gap-x-3'>
                            <button onClick={handleQuickSms} disabled={disable} className="btn w-[100px] btn-primary text-white btn-sm">Send</button>
                            <label htmlFor="Quick-sms" className="btn btn-sm w-[100px]">Cancel</label>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    );
};

export default QuickSms;

// disabled={ ((new Date(ClassAttendance?.updatedAt).toLocaleDateString() === date.toLocaleDateString()) || disable || (confirmAttendance?.newArr?.length <= 0) )}