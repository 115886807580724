import React, { useState, useContext } from "react";
import daimon from "../../assets/icons/diamond1.png";
import attendance from "../../assets/icons/attendance1.png";
import userAply from "../../assets/icons/user.png";
import sms from "../../assets/icons/sms.png";
import { attendanceContext, MeContext, userContext } from "../../App";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineSms } from "react-icons/md";

const ProfileInformation = ({
  user,
  present,
  setQuickSms,
  meLoading,
  absent,
  setAttendanceToday,
}) => {
  const [users, userLoading, userRefetch, userError, ApplyUsers] =
    useContext(userContext);
  const [Attendances, AttendanceLoading, atRefetch] =
    useContext(attendanceContext);
  const [me] = useContext(MeContext);
  const location = useLocation();
  const { pathname } = location;

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const [attDate, setAttDate] = useState(formatDate(yesterday));

  const year = new Date().getFullYear()?.toString().slice(2);
  const studentId = user?.phoneNumber?.slice(10) + year + user?.roleNo;

  // date formate
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month =
      date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return `${year}-${month}-${day}`;
  }

  let AllAttendance = [];

  Attendances?.map((i) => {
    i?.Attendance?.map((x) => {
      if (
        new Date(x?.date).toLocaleDateString() ===
        new Date(attDate).toLocaleDateString()
      ) {
        x?.newArr?.map((a) => {
          AllAttendance.push(a);
        });
      }
    });
  });

  const pre = AllAttendance?.filter((x) => x?.status?.includes("P"));
  const abs = AllAttendance?.filter((x) => x?.status?.includes("A"));

  return (
    <div className="w-full border bg-[#e3f5ff]  shadow-md !z-[900] rounded-2xl sm:p-3 p-2">
      <div className="flex justify-between w-full gap-2">
        {(user?.role === "student" || user?.role === "teacher") && (
          <div className="p-1 sm:p-2 w-full flex items-center gap-x-2 border bg-[#d2f8fb] border-[#b1f3fc] rounded-lg">
            <div className="w-8 sm:w-12">
              <img src={daimon} className="w-full" alt="" />
            </div>
            <div>
              <h3 className="text-secondary font-bold sm:text-3xl">
                {user?.gam}
              </h3>
              <p className="text-sm">Gam</p>
            </div>
          </div>
        )}

        {user?.role === "admin" && (
          <Link to="/dashboard/applyUser" className="w-full">
            <div className="p-1 sm:p-2 w-full flex items-center gap-x-2 border bg-[#d2f8fb] border-[#b1f3fc] rounded-lg">
              <div className="w-6 sm:w-12">
                <img src={userAply} className="w-full" alt="" />
              </div>
              <div>
                <h3 className="text-secondary font-bold sm:text-3xl">
                  {ApplyUsers?.length}
                </h3>
                <p className="text-xs sm:text-sm">Aply Users</p>
              </div>
            </div>
          </Link>
        )}

        {user?.role === "student" && (
          <div className="p-1 sm:p-2 w-full flex items-center gap-x-2 border bg-[#d2f8fb] border-[#b1f3fc] rounded-lg">
            <div className="w-8 sm:w-12">
              <img src={attendance} className="w-full" alt="" />
            </div>
            <div>
              <h3 className="text-secondary font-bold sm:text-3xl">
                {present?.length + " | " + absent?.length}
              </h3>
              <p className="text-sm">Attendance</p>
            </div>
          </div>
        )}

        {(user?.role === "admin" || user?.role === "teacher") && (
          <div className="p-1 sm:p-2 w-full flex items-center gap-x-2 border bg-[#d2f8fb] border-[#b1f3fc] rounded-lg">
            <div className="w-6 sm:w-12">
              <img src={attendance} className="w-full" alt="" />
            </div>
            <div>
              <div className="flex items-center gap-x-2">
                <label
                  onClick={() => setAttendanceToday([pre, attDate, setAttDate])}
                  htmlFor="attendance-today"
                  className="text-primary cursor-pointer font-bold sm:text-3xl"
                >
                  {pre?.length}{" "}
                </label>
                <div className="font-bold sm:text-2xl text-secondary"> | </div>
                <label
                  onClick={() => setAttendanceToday([abs, attDate, setAttDate])}
                  htmlFor="attendance-today"
                  className="text-accent cursor-pointer font-bold sm:text-3xl"
                >
                  {" "}
                  {abs?.length}
                </label>
              </div>
              <p className="text-xs sm:text-sm">Attendance</p>
            </div>
          </div>
        )}

        {user?.role === "admin" && (
          <Link to="/dashboard/bulkSms" className="w-full">
            <div className="p-1 sm:p-2 w-full flex items-center gap-x-2 border bg-[#d2f8fb] border-[#b1f3fc] rounded-lg">
              <div className="w-6 sm:w-12">
                <img src={sms} className="w-full" alt="" />
              </div>
              <div>
                <h3 className="text-secondary font-bold sm:text-3xl">Sms</h3>
                <p className="text-xs sm:text-sm">Send Sms</p>
              </div>
            </div>
          </Link>
        )}

        {me?.role === "admin" && pathname === "/dashboard/users" && (
          <label
            onClick={() => setQuickSms(user)}
            htmlFor="Quick-sms"
            className="rounded-lg text-purple-700 flex items-center p-2 px-3 sm:px-5 sm:p-3 cursor-pointer font-bold bg-purple-100"
          >
            <div>
              <MdOutlineSms size={36} />
            </div>
          </label>
        )}
      </div>
      <div className="flex gap-4 text-sm sm:text-md items-center mt-2 sm:mt-3 justify-between">
        {!(user?.role === "teacher" || user?.role === "admin") && (
          <div className="w-full">
            <div className="flex py-1 sm:py-2 border-b border-[#f1e3fc] justify-between items-center">
              <h3 className="">Class:</h3>
              <h3 className="">{user?.class}</h3>
            </div>
            <div className="flex py-1 sm:py-2 border-b border-[#f1e3fc] justify-between items-center">
              <h3 className="">Roll No:</h3>
              <h3 className="">{user?.roleNo}</h3>
            </div>
          </div>
        )}
        <div className="w-full">
          <div className="flex py-1 sm:py-2 border-b border-[#f1e3fc] justify-between items-center">
            <h3 className="">Role:</h3>
            <h3 className="">{user?.role}</h3>
          </div>
          <div className="flex py-1 sm:py-2 border-b border-[#f1e3fc] justify-between items-center">
            <h3 className="">Gender:</h3>
            <h3 className="">{user?.gender}</h3>
          </div>
        </div>
      </div>
      <div className="w-full text-sm sm:text-md border-b border-[#f1e3fc] sm:flex items-center justify-between gap-4">
        <div className="flex w-full py-1 sm:py-2 sm:border-b-0 border-b border-[#f1e3fc] justify-between items-center">
          <h3 className="">Phone Number:</h3>
          <h3 className="font-Oswald">
            {user?.phoneNumber2 ? user?.phoneNumber2 : user?.phoneNumber}
          </h3>
        </div>
        <div className="flex w-full py-1 sm:py-2 justify-between items-center">
          <h3 className="">Address:</h3>
          <h3 className="">{user?.address}</h3>
        </div>
      </div>
      {user?.role === "student" && (
        <div className="w-full text-sm sm:text-md border-[#f1e3fc] sm:flex items-center justify-between gap-4">
          <div className="flex w-full py-1 sm:py-2 sm:border-b-0 border-b border-[#f1e3fc] justify-between items-center">
            <h3 className="">Father Name:</h3>
            <h3 className="">{user?.fatherName}</h3>
          </div>
          <div className="flex w-full py-1 sm:py-2 sm:border-b-0 border-b border-[#f1e3fc] justify-between items-center">
            <h3 className="">Mother Name:</h3>
            <h3 className="">{user?.motherName}</h3>
          </div>
        </div>
      )}
      <div className="w-full text-sm sm:text-md sm:flex items-center justify-between gap-4">
        {user?.role === "student" && (
          <div className="flex w-full py-1 sm:py-2 sm:border-b-0 border-b border-[#f1e3fc] justify-between items-center">
            <h3 className="">Student ID:</h3>
            <h3
              onClick={() => navigator.clipboard.writeText(studentId)}
              className="font-Oswald"
            >
              {studentId}
            </h3>
          </div>
        )}
        <div className="flex text-sm sm:text-md w-full py-1 sm:py-2 justify-between items-center">
          <h3 className="">Date of Birth:</h3>
          <h3 className="font-Oswald">{user?.age}</h3>
        </div>
      </div>
    </div>
  );
};

export default ProfileInformation;
