import React, { useState } from "react";
import { toast } from "react-toastify";
import Loading from "../../Shared/Loading";
import create1 from "../../assets/icons/create (1).png";
import create2 from "../../assets/icons/create (2).png";
import axios from "../../Utils/Axios.config";
import { ProgressBar } from "react-loader-spinner";

const CreateNotice = ({ setOpenCreateNotice, refetch, openCreateNotice }) => {
  const xm = openCreateNotice[0];

  const [loading, setLoading] = useState(false);
  const [noticeTitle, setNoticeTitle] = useState(xm?.noticeTitle);
  const [NoticeClass, setNoticeClass] = useState("All");
  const [noticeDescription, setNoticeDescription] = useState(xm?.description);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const NoticeData = {
      noticeTitle: noticeTitle,
      noticeClass: NoticeClass,
      description: noticeDescription,
    };

    console.log(NoticeData);

    if (openCreateNotice[1] === "create") {
      axios
        .post("/notice/create", NoticeData)
        .then((response) => {
          console.log(response.data);
          refetch();
          setLoading(false);
          toast.success("Successfully create the Notice");
          setOpenCreateNotice(null);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          toast.error("Opps No some this is Wrong..!!");
        });
    } else {
      axios
        .patch(`/notice/${xm?._id}`, NoticeData)
        .then((response) => {
          console.log(response.data);
          refetch();
          setLoading(false);
          toast.success("Successfully update the Notice");
          setOpenCreateNotice(null);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          toast.error("Opps No some this is Wrong..!!");
        });
    }
  };

  return (
    <div>
      <input type="checkbox" id="create-notice" className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="bg-blue-100 pb-8 mb-10 h-auto modal-box p-3 py-5 sm:p-4">
          {openCreateNotice[1] === "create" && (
            <div className="w-full text-center">
              <img src={create2} className="w-20 mx-auto" alt="create" />
              <h2 className="text-xl uppercase text-center font-bold text-primary">
                Create Notice
              </h2>
            </div>
          )}
          {openCreateNotice[1] === "update" && (
            <div className="w-full text-center">
              <img src={create1} className="w-20 mx-auto" alt="create" />
              <h2 className="text-xl uppercase text-center font-bold text-primary">
                Update Notice
              </h2>
            </div>
          )}
          <form onSubmit={handleSubmit} className="w-full mt-3">
            <div className="rounded-xl p-3 bg-white w-full">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-cyan-900 font-bold">
                    Notice Title
                  </span>
                </label>
                <input
                  required
                  type="text"
                  placeholder="Notice Title"
                  value={noticeTitle}
                  onChange={(e) => setNoticeTitle(e.target.value)}
                  className="input input-bordered bg-transparent input-sm sm:input-md input-primary w-full"
                />
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-cyan-900 font-bold">
                    Select Class
                  </span>
                </label>
                <select
                  required
                  className="input input-bordered bg-transparent input-sm sm:input-md input-primary w-full"
                  onChange={(e) => setNoticeClass(e.target.value)}
                >
                  <option value="All" select>
                    All
                  </option>
                  <option value="Play">Play</option>
                  <option value="Nursery">Nursery</option>
                  <option value="One">One</option>
                  <option value="Two">Two</option>
                  <option value="Three">Three</option>
                  <option value="Four">Four</option>
                  <option value="Five">Five</option>
                  <option value="Six">Six</option>
                  <option value="Seven">Seven</option>
                  <option value="Eight">Eight</option>
                  <option value="Nine">Nine</option>
                  <option value="Ten">Ten</option>
                  <option value="teacher">Teacher</option>
                </select>
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-cyan-900 font-bold">
                    Notice Description
                  </span>
                </label>
                <textarea
                  required
                  placeholder="Notice Description"
                  value={noticeDescription}
                  onChange={(e) => setNoticeDescription(e.target.value)}
                  className="input input-bordered bg-transparent min-h-[120px] input-primary w-full"
                />
              </div>
            </div>
            <div className=" mt-5 p-3 py-5 rounded-xl bg-white">
              {loading && (
                <div className="w-full flex items-center justify-center">
                  <ProgressBar
                    height="80"
                    width="80"
                    ariaLabel="progress-bar-loading"
                    wrapperStyle={{}}
                    wrapperClass="progress-bar-wrapper"
                    borderColor="#F4442E"
                    barColor="#51E5FF"
                  />
                </div>
              )}
              <div className="flex items-center justify-center gap-3">
                <input
                  type="submit"
                  value="Submit"
                  className="btn w-[100px] btn-primary text-white btn-sm"
                />
                <label
                  htmlFor="create-notice"
                  className="btn btn-sm w-[100px] "
                >
                  cancel
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateNotice;
