import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { HiPlus } from "react-icons/hi";
import { userContext } from "../App";
import { BiEdit } from "react-icons/bi";
import {
  MiddleSub,
  jscSub,
  primarySub,
  secondarySub,
  sscSub,
} from "../data/subjectData";

const ResultCart = ({ exam, setEditSubmit }) => {
  const [users] = useContext(userContext);
  const [xmSub, setXmSub] = useState([...sscSub]);

  const userNm = (Phone) => {
    const user = users?.find((i) => i?.phoneNumber?.includes(Phone));
    return user;
  };
  const xmClass = userNm(exam[0]?.studentPh)?.class;

  // Subject select
  useEffect(() => {
    if (
      xmClass === "Play" ||
      xmClass === "Nursery" ||
      xmClass === "One" ||
      xmClass === "Two"
    ) {
      setXmSub(primarySub);
    } else if (
      xmClass === "Three" ||
      xmClass === "Four" ||
      xmClass === "Five"
    ) {
      setXmSub(secondarySub);
    } else if (xmClass === "Six" || xmClass === "Seven") {
      setXmSub(MiddleSub);
    } else if (xmClass === "Eight") {
      setXmSub(jscSub);
    } else if (xmClass === "Ten" || xmClass === "Nine") {
      setXmSub([...sscSub]);
    }
  }, [xmClass]);

  const calculateMark = (x) => {
    const i = parseFloat(x?.mcq);
    const w = parseFloat(x?.writing);
    const p = parseFloat(x?.pecticNun);

    if (x?.pecticNun && x?.writing) {
      return i + w + p;
    } else if (x.mcq && x?.writing) {
      return i + w;
    } else if (x.mcq && x?.pecticNun) {
      return i + p;
    } else {
      return w;
    }
  };

  const handleWMP = (i, sub) => {
    const w = Math.floor((sub?.wMax / 100) * 33);
    const m = Math.floor((sub?.mcq / 100) * 33);
    if (i?.writing < w || i?.mcq < m) {
      return "F";
    } else {
      return "P";
    }
  };

  const handleGreat = (p, i) => {
    const crnSub = xmSub?.find((i) => i?.name === exam[0]?.subject);
    const status = handleWMP(i, crnSub);
    if (crnSub?.mcq && status === "F") {
      return "F";
    } else if (p < (crnSub?.maxNum / 100) * 33) {
      return "F";
    } else if (
      p >= (crnSub?.maxNum / 100) * 33 &&
      p < (crnSub?.maxNum / 100) * 40
    ) {
      return "D";
    } else if (
      p >= (crnSub?.maxNum / 100) * 40 &&
      p < (crnSub?.maxNum / 100) * 50
    ) {
      return "C";
    } else if (
      p >= (crnSub?.maxNum / 100) * 50 &&
      p < (crnSub?.maxNum / 100) * 60
    ) {
      return "B";
    } else if (
      p >= (crnSub?.maxNum / 100) * 60 &&
      p < (crnSub?.maxNum / 100) * 70
    ) {
      return "A-";
    } else if (
      p >= (crnSub?.maxNum / 100) * 70 &&
      p < (crnSub?.maxNum / 100) * 80
    ) {
      return "A";
    } else if (
      p >= (crnSub?.maxNum / 100) * 80 &&
      p <= (crnSub?.maxNum / 100) * 100
    ) {
      return "A+";
    }
  };

  return (
    <>
      {exam &&
        exam?.map((results, index) => (
          <div
            key={index}
            tabIndex="0"
            className="collapse collapse-arrow shadow-sm bg-[#f6eefb] mb-2 w-full rounded-md py-1 px-2"
          >
            <div className="text-xl font-medium">
              <div className="flex items-center w-full justify-between">
                <div className="flex text-[12px] w-full sm:text-[16px] items-center">
                  <p className=" ">{userNm(results?.studentPh)?.roleNo}</p>
                  <p className=" ml-2">
                    {userNm(results?.studentPh)?.firstName}{" "}
                    {userNm(results?.studentPh)?.lastName}
                  </p>
                </div>
                <p className="text-[12px] w-full hidden sm:block sm:text-[16px]">
                  {results.subject}
                </p>
                <div className="text-[12px] w-[120px] justify-end sm:text-[16] flex items-center">
                  <p className="font-bold">{results.writing}</p>
                  {results.mcq && results.writing && (
                    <span className="mx-1 text-purple-800">
                      <HiPlus />{" "}
                    </span>
                  )}
                  {results.mcq && <p className="font-bold">{results.mcq}</p>}
                  {results.pecticNun && (
                    <span className="mx-1 text-purple-800">
                      <HiPlus />{" "}
                    </span>
                  )}
                  {results.pecticNun && (
                    <p className="font-bold">{results.pecticNun}</p>
                  )}
                  {results.mcq && (
                    <span className="mx-1 text-purple-800">=</span>
                  )}
                  {results.mcq && (
                    <p className="font-bold"> {calculateMark(results)}</p>
                  )}
                  <p className="font-bold ml-2 text-purple-700">
                    {handleGreat(calculateMark(results), results)}
                  </p>
                </div>
              </div>
            </div>
            <div className="collapse-content !mx-0 !px-0 w-full grid gap-2">
              <div className="border relative p-2 pb-1 mt-3 !mx-0 rounded-lg border-[#ebc5ff] bg-[#f6e6ff]">
                <div className="">
                  <p className="text-sm mt-0.5 text-purple-700">
                    {results.feedback}
                  </p>
                  <div className="flex mt-1 text-[#b968e4] items-center justify-between">
                    <h1 className="text-xs">{`Feedback--> ${results.sir} Sir`}</h1>
                    <h1 className="text-xs">
                      {format(new Date(results.date), "PP")}
                    </h1>
                  </div>
                </div>
                {
                  <div className="absolute -top-[11px] right-2">
                    <label
                      htmlFor="Edit-submit"
                      onClick={() =>
                        setEditSubmit([results, userNm(results?.studentPh)])
                      }
                      className="border capitalize bg-[#eeddfc] flex items-center rounded-xl leading-loose border-[#ebc5ff] text-purple-700 px-3 font-bold cursor-pointer !py-0 text-[10px]"
                    >
                      Edit - <BiEdit />
                    </label>
                  </div>
                }
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default ResultCart;
