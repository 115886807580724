import React, { useContext } from "react";
import { useRef } from "react";
import { useState } from "react";
import axios from "../../Utils/Axios.config";
import { toast } from "react-toastify";
import profile from "../../assets/images/Student-avatar.jpg";
import { ProgressBar } from "react-loader-spinner";
import { MeContext } from "../../App";

const UpdateModul = ({ setUpdateModal, refetch, updateModal }) => {
  const [me] = useContext(MeContext);

  const user = updateModal;
  const [image, setImage] = useState();
  const [img, setImg] = useState();
  const imageRef = useRef();
  const [firstName, setFirstName] = useState(user?.firstName);
  const [fatherName, setFatherName] = useState(user?.fatherName);
  const [motherName, setMotherName] = useState(user?.motherName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [phoneNumber, setPhoneNumber] = useState(
    user?.phoneNumber2 ? user?.phoneNumber2 : user?.phoneNumber
  );
  const [email, setEmail] = useState(user?.email);
  const [roleNo, setRoleNo] = useState(user?.roleNo);
  const [age, setAge] = useState(user?.age);
  const [classes, setClasses] = useState(user?.class);
  const [gender, setGender] = useState(user?.gender);
  const [address, setAddress] = useState(user?.address);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(
    user?.title ? user.title : "Enter Your Education Title"
  );

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setImg(img);
      setImage({
        image: URL.createObjectURL(img),
      });
    }
  };

  // const imageStorageKey = '290c7a0f169eabc5cf1f1fe286564c38';

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    const fromData = new FormData();
    fromData.append("file", img);
    fromData.append("upload_preset", "nabojagoron_academy");
    fromData.append("cloud_name", "ddlrfuyzp");

    // const url = `https://api.imgbb.com/1/upload?key=${imageStorageKey}`
    const url = `https://api.cloudinary.com/v1_1/ddlrfuyzp/image/upload`;
    fetch(url, {
      method: "POST",
      body: fromData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result) {
          const img = result.secure_url;
          console.log(result);

          const data = {
            firstName: firstName,
            fatherName: fatherName,
            motherName: motherName,
            lastName: lastName,
            phoneNumber2: phoneNumber,
            email: email,
            address: address,
            title: title,
            imageURL: img,
            age: age,
            class: classes,
            gender: gender,
            roleNo: roleNo,
          };

          console.log(data);
          // send data backend
          axios
            .patch(`/user/${user?._id}`, data)
            .then((response) => {
              toast.success("successfully Update the user");
              console.log(response);
              setLoading(false);
              refetch();
              setUpdateModal(null);
            })
            .catch((error) => {
              console.log(error);
              toast.error("Ops No..!! Something is wrong");
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      });
  };
  return (
    <div>
      <input type="checkbox" id="add-update-modal" className="modal-toggle" />
      <div className="modal modal-bottom h-screen sm:modal-middle">
        <form
          onSubmit={handleUpdate}
          className="modal-box bg-[#e3f5ff] pt-0 px-0  h-auto w-full sm:w-11/12 mb-10 pb-8"
        >
          <div className="w-full flex items-center justify-center relative h-[100px] rounded-b-[100%] bg-gradient-to-t from-cyan-500 to-blue-500">
            <div className="text-center -mb-20">
              <div
                onClick={() => imageRef.current.click()}
                className="avatar cursor-pointer"
              >
                <div className="w-28 rounded-full ring bg-blue-200 ring-secondary ring-offset-base-100 ring-offset-2">
                  {!image && !user?.imageURL && (
                    <img src={profile} alt="profile" />
                  )}
                  {user?.imageURL && !image && (
                    <img src={user?.imageURL} alt="profile" />
                  )}
                  {image && <img src={image.image} alt="profile" />}
                </div>
                {(me?.role === "admin" || user?.role === "teacher") && (
                  <div style={{ display: "none" }} className="hidden">
                    <input
                      type="file"
                      name="images"
                      onChange={onImageChange}
                      ref={imageRef}
                      id=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="px-3 mt-16">
            <div className="w-full">
              <div className="w-full flex items-center mt-3 justify-between sm:gap-3 gap-2">
                <div className="w-full">
                  <span className="text-secondary text-sm mb-1">
                    First Name
                  </span>
                  <input
                    type="text"
                    name="name"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    placeholder={user?.firstName}
                    className="input input-bordered input-secondary sm:input-md input-sm  w-full"
                  />
                </div>
                <div className="w-full">
                  <span className="text-secondary text-sm mb-1">Last Name</span>
                  <input
                    type="text"
                    name="address"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    placeholder={user?.lastName}
                    className="input input-secondary input-bordered sm:input-md input-sm  w-full"
                  />
                </div>
              </div>

              <div className=" w-full flex items-center mt-3 justify-between sm:gap-3 gap-2">
                <div className=" w-5/12">
                  <span className="text-secondary text-sm mb-1">
                    Your Address
                  </span>
                  <input
                    type="text"
                    name="address"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    placeholder={user?.address}
                    className="input input-bordered input-secondary sm:input-md input-sm  w-full"
                  />
                </div>

                <div className="w-3/12">
                  <span className="text-secondary text-sm mb-1">Class</span>
                  <input
                    type="text"
                    name="class"
                    onChange={(e) => setClasses(e.target.value)}
                    placeholder="class"
                    value={classes}
                    className="input input-secondary input-bordered font-Oswald sm:input-md input-sm  w-full"
                  />
                </div>

                <div className="w-4/12">
                  <span className="text-secondary text-sm mb-1">Age</span>
                  <input
                    type="date"
                    name="age"
                    onChange={(e) => setAge(e.target.value)}
                    placeholder="mm/dd/yyyy"
                    value={age}
                    className="input input-secondary input-bordered font-Oswald sm:input-md input-sm  w-full"
                  />
                </div>
              </div>

              <div className="w-full flex items-center mt-3 justify-between sm:gap-3 gap-2">
                <div className="w-full">
                  <span className="text-secondary text-sm mb-1">
                    Phone Number
                  </span>
                  <input
                    type="text"
                    name="phoneNumber2"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    value={phoneNumber}
                    placeholder="Enter Your Phone Number"
                    className="input input-bordered font-Oswald input-secondary sm:input-md input-sm  w-full"
                  />
                </div>
                {me?.role === "admin" && user?.role === "teacher" && (
                  <div className="w-full">
                    <span className="text-secondary text-sm mb-1">Email</span>
                    <input
                      type="text"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      placeholder="Enter Your Email"
                      className="input input-secondary input-bordered sm:input-md input-sm  w-full"
                    />
                  </div>
                )}
                {me?.role === "admin" && user?.role === "student" && (
                  <div className="flex items-center gap-x-2 justify-between w-full">
                    <div className="w-full">
                      <span className="text-secondary text-sm mb-1">
                        Role No
                      </span>
                      <input
                        type="text"
                        name="roleNo"
                        onChange={(e) => setRoleNo(e.target.value)}
                        value={roleNo}
                        placeholder="Student Role No"
                        className="input font-Oswald input-secondary input-bordered sm:input-md input-sm  w-full"
                      />
                    </div>
                    <div className="w-full">
                      <span className="text-secondary text-sm mb-1">
                        Gender
                      </span>
                      <select
                        name="gender"
                        onChange={(e) => setGender(e.target.value)}
                        value={gender}
                        placeholder="Student Role No"
                        className="input input-secondary px-1 input-bordered sm:input-md input-sm  w-full"
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flex items-center mt-3 justify-between sm:gap-3 gap-2">
              {(user?.role === "teacher" || user?.role === "admin") && (
                <div className="w-full">
                  <span className="text-secondary text-sm mb-1">
                    Education Title
                  </span>
                  <input
                    type="text"
                    name="title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    placeholder="Enter Education Title"
                    className="input input-secondary input-bordered sm:input-md input-sm  w-full"
                  />
                </div>
              )}
              {user?.role === "student" && (
                <div className="w-full">
                  <span className="text-secondary text-sm mb-1">
                    Father Name
                  </span>
                  <input
                    type="text"
                    name="fatherName"
                    onChange={(e) => setFatherName(e.target.value)}
                    value={fatherName}
                    placeholder="Father Name"
                    className="input input-secondary input-bordered sm:input-md input-sm  w-full"
                  />
                </div>
              )}
              {user?.role === "student" && (
                <div className="w-full">
                  <span className="text-secondary text-sm mb-1">
                    Mother Name
                  </span>
                  <input
                    type="text"
                    name="motherName"
                    onChange={(e) => setMotherName(e.target.value)}
                    value={motherName}
                    placeholder="Mother Name"
                    className="input input-secondary input-bordered sm:input-md input-sm  w-full"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="p-4 mx-3  mt-5 shadow-md rounded-md bg-white">
            {loading && (
              <div className="w-full flex items-center justify-center">
                <ProgressBar
                  height="80"
                  width="80"
                  ariaLabel="progress-bar-loading"
                  wrapperStyle={{}}
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              </div>
            )}
            <div className="flex items-center justify-center gap-3">
              <input
                type="submit"
                value="Save"
                className="btn w-[100px] btn-primary text-white btn-sm"
              />
              <label
                htmlFor="add-update-modal"
                className="btn btn-sm w-[100px]"
              >
                cancel
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateModul;
