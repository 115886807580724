import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { userContext } from "../../App";
import Loading from "../../Shared/Loading";
import DeleteModalConfirm from "../Modul/DeleteModalConfirm";
import { AnimatePresence, motion } from "framer-motion";
// import UsersRow from './UsersRow';
import UserDetails from "../Modul/UserDetails";
import BlockUser from "../Modul/BlockUser";
import { lazy } from "react";
import { Suspense } from "react";
import UserCardSkeleton from "../../Utils/UserCardSkeleton";
import UpdateModul from "../Modul/UpdateModul";

const UsersRow = lazy(() => import("./UsersRow"));

const Users = () => {
  const [users, userLoading, userRefetch, userError, , blocked] =
    useContext(userContext);
  const [updateModal, setUpdateModal] = useState(null);
  const [deletingModal, setDeletingModal] = useState(null);
  const [openStudent, setOpenStudent] = useState(null);
  const [openBlock, setOpenBlock] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState(users);
  const [Class, setClass] = useState("All");

  // useEffect(() => {
  //     setData(users)
  // }, [users])

  if (userLoading) {
    return <Loading />;
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = data?.filter((user) =>
    user?.roleNo?.toLowerCase()?.startsWith(searchTerm?.toLowerCase())
  );
  const students = users?.filter((i) => i?.role?.includes("student"));
  const teachers = users?.filter((i) => i?.role?.includes("teacher"));
  const gardens = users?.filter((i) => i?.role?.includes("garden"));

  const handleClass = (classData) => {
    if (classData === "All") {
      return setData(users);
    } else if (classData === "Blocked") {
      return setData(blocked);
    } else if (classData === "OldTen") {
      const OldTen = students?.filter((i) => i?.class === classData);
      return setData(OldTen);
    } else if (
      classData === "teacher" ||
      classData === "garden" ||
      classData === "student"
    ) {
      const data = users?.filter((i) => i?.role?.includes(classData));
      return setData(data);
    } else if (
      classData === "SevenBoys" ||
      classData === "SevenGirls" ||
      classData === "EightBoys" ||
      classData === "EightGirls" ||
      classData === "NineBoys" ||
      classData === "NineGirls" ||
      classData === "TenBoys" ||
      classData === "TenGirls"
    ) {
      const studentValue = students?.filter((i) => {
        if (
          classData?.slice(0, 5) === "Seven" ||
          classData?.slice(0, 5) === "Eight"
        ) {
          return i?.class === classData?.slice(0, 5);
        } else if (classData?.slice(0, 4) === "Nine") {
          return i?.class === classData?.slice(0, 4);
        } else if (classData?.slice(0, 3) === "Ten") {
          return i?.class === classData?.slice(0, 3);
        }
      });
      const st = studentValue?.filter((i) => {
        if (classData?.slice(5) === "Boys") {
          return i?.gender === "male";
        }
        if (classData?.slice(4) === "Boys") {
          return i?.gender === "male";
        }
        if (classData?.slice(3) === "Boys") {
          return i?.gender === "male";
        } else {
          return i?.gender === "female";
        }
      });
      const sorted = st?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      return setData(sorted);
    } else {
      const studentValue = students?.filter((i) =>
        i?.class?.includes(classData)
      );
      const sorted = studentValue?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      setData(sorted);
    }
  };

  // setData(users)

  const method = "user";
  return (
    <div className="p-2 sm:p-0">
      <div className="text-center w-full flex items-center gap-3 justify-between p-3 py-4 shadow-md sm:mb-5 mb-3 rounded-md bg-white">
        <div className="">
          <input
            type="search"
            value={searchTerm}
            onChange={handleSearch}
            className="border rounded-3xl w-[150px] sm:w-auto ring-[#b06fff]-focus ring-[#eddeff] border-[#eddeff] ring-2 outline-0 p-1 px-3"
            placeholder="Search by Roll No"
            name=""
            id=""
          />
        </div>
        <div
          className={`flex bg-[#f3f9fb] max-w-[500px] border border-[#eddeff] rounded-3xl w-full`}
        >
          <div className="w-[100px] bg-[#eddeff] flex items-center justify-center rounded-r-none rounded-3xl">
            <span className="text-xl font-bold element-font-3 text-purple-700">
              {data?.length}
            </span>
          </div>
          <select
            onChange={(e) => handleClass(e.target.value)}
            className="text-sm border px-3 py-2 rounded-l-none rounded-3xl w-full"
          >
            <option value="All" select>
              All
            </option>
            <option value="teacher">Teacher</option>
            <option value="student">Student</option>
            <option value="garden">Garden</option>
            <option value="Play">Play</option>
            <option value="Nursery">Nursery</option>
            <option value="One">One</option>
            <option value="Two">Two</option>
            <option value="Three">Three</option>
            <option value="Four">Four</option>
            <option value="Five">Five</option>
            <option value="Six">Six</option>
            <option value="Seven">Seven</option>
            <option value="Eight">Eight</option>
            <option value="Nine">Nine</option>
            <option value="Ten">Ten</option>
            <option value="Blocked">Blocked</option>
            <option value="SevenBoys">SevenBoys</option>
            <option value="SevenGirls">SevenGirls</option>
            <option value="EightBoys">EightBoys</option>
            <option value="EightGirls">EightGirls</option>
            <option value="NineBoys">NineBoys</option>
            <option value="NineGirls">NineGirls</option>
            <option value="TenBoys">TenBoys</option>
            <option value="TenGirls">TenGirls</option>
            <option value="OldTen">OldTen</option>
          </select>
        </div>
      </div>
      <div className="gap-3 h-auto justify-between">
        <div className="w-full max-h-screen overflow-y-scroll">
          <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
            <AnimatePresence>
              {filteredUsers?.slice(0, 100)?.map((user, index) => (
                <motion.div
                  key={user._id}
                  layout
                  animate={{ opacity: 1, scale: 1 }}
                  initial={{ opacity: 0, scale: 0 }}
                  exit={{ opacity: 0, scale: 0 }}
                >
                  <Suspense fallback={<UserCardSkeleton />}>
                    <UsersRow
                      user={user}
                      index={index}
                      setUpdateModal={setUpdateModal}
                      setOpenStudent={setOpenStudent}
                      setDeletingModal={setDeletingModal}
                      setOpenBlock={setOpenBlock}
                    ></UsersRow>
                  </Suspense>
                </motion.div>
              ))}
              {!filteredUsers?.length && (
                <div className="text-center w-full mt-8">
                  <h3 className="text-3xl text-purple-700 font-bold">
                    No User Found
                  </h3>
                </div>
              )}
            </AnimatePresence>
          </motion.div>
        </div>
      </div>
      {deletingModal && (
        <DeleteModalConfirm
          deletingModal={deletingModal}
          setDeletingModal={setDeletingModal}
          method={method}
          refetch={userRefetch}
        />
      )}
      {openStudent && (
        <UserDetails
          openStudent={openStudent}
          setOpenStudent={setOpenStudent}
          refetch={userRefetch}
          method={method}
        />
      )}
      {openBlock && (
        <BlockUser
          blocked={openBlock}
          setOpenBlock={setOpenBlock}
          refetch={userRefetch}
        />
      )}
      {updateModal && (
        <UpdateModul
          updateModal={updateModal}
          refetch={userRefetch}
          setUpdateModal={setUpdateModal}
        />
      )}
    </div>
  );
};

export default Users;
