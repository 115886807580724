import React from 'react';

const UserCardSkeleton = () => {
  return (
    <div className="p-3 rounded-xl relative overflow-hidden bg-[#f1f0f0] border border-[#e1dfe2]">
      
      <div className='w-full'>
        <div className='absolute -top-1.5 grid place-content-center -right-1.5 w-20 h-8 rounded-full bg-gray-300 animate-pulse'>
          <h1 className='capitalize mb-0 font-bold bg-gray-300'></h1>
        </div>

        <div className="flex justify-between items-end w-full">
          <div className='flex items-center gap-x-3'>
            <div className='w-9 h-9 rounded-full bg-gray-300 animate-pulse'></div>
            <div className="w-full ">
              <div className='mb-1 font-bold bg-gray-300 rounded animate-pulse w-24 h-3'></div>
              <div className='bg-gray-300 rounded animate-pulse w-16 h-2'></div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex justify-between items-center border-t border-gray-200 mt-2 pt-2'>
        <div className="">
          <div className='text-sm capitalize bg-gray-300 font-bold rounded animate-pulse w-20 h-3'></div>
        </div>
        <div className="flex gap-x-1 items-center">
          <div className='bg-gray-300 font-bold rounded animate-pulse w-6 h-6'></div>
          <div className='bg-gray-300 rounded animate-pulse w-6 h-6'></div>
          <div className='bg-gray-300 rounded animate-pulse w-6 h-6'></div>
          <div className='bg-gray-300 rounded animate-pulse w-6 h-6'></div>
        </div>
      </div>
    </div>
  );
};

export default UserCardSkeleton