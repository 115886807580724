import React from "react";
import rohul from "../assets/images/rohul.png";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import WhatsAppLink from "./WhatsAppLink";
import { FiFacebook, FiLinkedin } from "react-icons/fi";

const DeveloperProfile = () => {
  return (
    <div className="max-w-[400px] w-full gap-x-2 rounded-xl flex items-center border-2 border-[#bc8efd] bg-[#e7d2fc] p-2">
      <div className="w-[84px] h-full border-[3px] border-[#bc8efd] overflow-hidden rounded-[10px]">
        <img src={rohul} alt="Rohul" />
      </div>
      <div className="border-l-2 border-[#bc8efd] font-Oswald pl-2">
        <div className="text-purple-700 font-reem">
          <h3 className="font-bold text-[20px]">Rohul Amin</h3>
          <h5 className="font-bold text-[16px]">Web Developer</h5>
        </div>
        <div className="flex items-center gap-x-1 mt-2">
          <div className="p-1.5 bg-[#551eb4] border-2 border-[#bc8efd] text-white rounded-md">
            <a className="block" href={`tel:+8801831294559`}>
              <span className="font-bold">
                <AiOutlinePhone size={16} />
              </span>
            </a>
          </div>
          <div className="p-1.5 bg-[#551eb4] border-2 border-[#bc8efd] text-white rounded-md">
            <a className="block" href={`mailto:rohulaminwd@gmail.com`}>
              <span className="font-bold">
                <AiOutlineMail size={16} />
              </span>
            </a>
          </div>
          <div className="p-1.5 bg-[#551eb4] border-2 border-[#bc8efd] text-white rounded-md">
            <WhatsAppLink
              phoneNumber="+8801831294559"
              size={16}
              message="Hello, how are you?"
            />
          </div>
          <div className="p-1.5 bg-[#551eb4] border-2 border-[#bc8efd] text-white rounded-md">
            <a
              className="block"
              href="https://web.facebook.com/rohulaminmia.rohulamin"
            >
              <span className="font-bold">
                <FiFacebook size={16} />
              </span>
            </a>
          </div>
          <div className="p-1.5 bg-[#551eb4] border-2 border-[#bc8efd] text-white rounded-md">
            <a
              className="block"
              href="https://www.linkedin.com/in/rohulaminwd/"
            >
              <span className="font-bold">
                <FiLinkedin size={16} />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeveloperProfile;
