
import { useState } from 'react';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css"
import "aos/dist/aos.css";
import AOS from "aos";
import 'react-day-picker/dist/style.css';
import { createContext } from 'react';
import useUsers from './Hooks/useUsers';
import useAttendance from './Hooks/useAttendance';
import RouteList from "./RouteList/RouteList";
import useMe from './Hooks/useMe';
import useExam from './Hooks/useExam';
import useNotice from './Hooks/useNotice';
export const MeContext = createContext()
export const userContext = createContext()
export const ExamContext = createContext()
export const NoticeContext = createContext()
export const attendanceContext = createContext()

function App() {
  AOS.init();

  const [me, meLoading, meRefetch, meError, setMeData] = useMe();
  const [users, userLoading, userRefetch, userError, ApplyUsers, blocked] = useUsers();
  const [attendance, AttendanceLoading, atRefetch, myAttendance, present, absent, attError ] = useAttendance(me);
  const [xms, xmLoading, xmRefetch, myXm, xmError ] = useExam(me);
  const [notices, noticeLoading, noticeRefetch, myNotice, noticeError ] = useNotice(me);


  return (
    <div className=''>

      <userContext.Provider value={[users, userLoading, userRefetch, userError, ApplyUsers, blocked]} >
        <attendanceContext.Provider value={[attendance, AttendanceLoading, atRefetch, myAttendance, present, absent, attError ]}>
          <MeContext.Provider value={[me, meLoading, meRefetch, meError, setMeData]}>
            <ExamContext.Provider value={[xms, xmLoading, xmRefetch, myXm, xmError]} >
              <NoticeContext.Provider value={[notices, noticeLoading, noticeRefetch, myNotice, noticeError]} >
                  <RouteList />
              </NoticeContext.Provider>
            </ExamContext.Provider>
          </MeContext.Provider>
        </attendanceContext.Provider>
      </userContext.Provider>

      <ToastContainer />
    </div>
  );
}
export default App;
