import React from "react";
import logo from "../assets/images/logo.png";
import DeveloperProfile from "../Utils/DeveloperProfile";

const Footer = () => {
  return (
    <div className="w-full h-full bg-cyan-900">
      <div className="text-center w-full pt-8 gap-x-3">
        <div className="w-40 mx-auto flex justify-center items-center">
          <img src={logo} className="w-[80px]" alt="logo" />
          {/* <div className='text-white text-4xl ml-1 element-font-4 flex gap-1 font-bold text-left w-full'>
                        <h3 className='pb-0'>N</h3>
                        <h3 className='pt-0'>A</h3>
                    </div> */}
        </div>
      </div>
      <div className="w-full font-all max-w-7xl mx-auto grid grid-clos-1 sm:grid-cols-2 py-5 text-white px-4 gap-5">
        <div className="text-center sm:text-left">
          <h1 className="text-3xl my-5 md:text-4xl text-white font-bold">
            নবজাগরণ একাডেমি
          </h1>
          <p className="">
            শিক্ষা আলোর পথ যেন জ্বলতে থাকে সর্বদা, তাই আমরা শিক্ষার উজ্জ্বল পথে
            অগ্রসর করে যাচ্ছি । আমরা সম্পূর্ণ নির্ভরযোগ্য ও বিশ্বস্ত পথে অগ্রসর
            করে যাচ্ছি একটি পরিপূর্ণ সমস্ত শিক্ষার প্রস্তুতি দেওয়ার জন্য।
          </p>
        </div>
        <div className="flex items-center justify-between">
          <div className="mx-auto">
            <ul>
              <li className="cursor-pointer hover:text-green-500 duration-200 my-2 hover:translate-x-2">
                হোম
              </li>
              <li className="cursor-pointer hover:text-green-500 duration-200 my-2 hover:translate-x-2">
                এবাউট{" "}
              </li>
              <li className="cursor-pointer hover:text-green-500 duration-200 my-2 hover:translate-x-2">
                কন্টাক্ট
              </li>
            </ul>
          </div>
          <div className="mx-auto">
            <ul>
              <li className="cursor-pointer hover:text-green-500 duration-200 my-2 hover:translate-x-2">
                সাপোর্ট
              </li>
              <li className="cursor-pointer hover:text-green-500 duration-200 my-2 hover:translate-x-2">
                ফিচার{" "}
              </li>
              <li className="cursor-pointer hover:text-green-500 duration-200 my-2 hover:translate-x-2">
                গ্যালারি
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full p-3 pt-0 flex items-center justify-center">
        <DeveloperProfile />
      </div>
      <p className="text-white py-3 font-all text-center">
        {new Date().getFullYear()} &copy; নবজাগরণ একাডেমি
      </p>
    </div>
  );
};

export default Footer;
