import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import axios from "../../Utils/Axios.config";
import { toast } from "react-toastify";
import { attendanceContext, MeContext, userContext } from "../../App";
import Loading from "../../Shared/Loading";
import AttendanceRow from "./AttendanceRow";
import AttendanceTable from "../../Shared/AttendanceTable";
import AttendanceCreateName from "../Modul/AttendanceCreateName";
import ConfirmAttendance from "../Modul/ConfirmAttendance";
import LateAttendance from "../Modul/LateAttendance";

const Attendance = () => {
  const [me, meLoading, meRefetch, meError] = useContext(MeContext);
  const [Attendances, AttendanceLoading, atRefetch] =
    useContext(attendanceContext);
  const [users, userLoading, userRefetch, userError] = useContext(userContext);
  const [date, setDate] = useState(new Date());
  const formateDate = format(date, "PP");
  const [showAttName, setShowAttName] = useState(null);
  const [late, setLate] = useState(null);
  const [confirmAttendance, setConfirmAttendance] = useState(null);

  const students = users?.filter((i) => i?.role?.includes("student"));

  const handleClass = (classData) => {
    if (
      classData === "SevenBoys" ||
      classData === "SevenGirls" ||
      classData === "EightBoys" ||
      classData === "EightGirls" ||
      classData === "NineBoys" ||
      classData === "NineGirls" ||
      classData === "TenBoys" ||
      classData === "TenGirls"
    ) {
      const studentValue = students?.filter((i) => {
        if (
          classData?.slice(0, 5) === "Seven" ||
          classData?.slice(0, 5) === "Eight"
        ) {
          return i?.class === classData?.slice(0, 5);
        } else if (classData?.slice(0, 4) === "Nine") {
          return i?.class === classData?.slice(0, 4);
        } else if (classData?.slice(0, 3) === "Ten") {
          return i?.class === classData?.slice(0, 3);
        }
      });
      const st = studentValue?.filter((i) => {
        if (classData?.slice(5) === "Boys") {
          return i?.gender === "male";
        }
        if (classData?.slice(4) === "Boys") {
          return i?.gender === "male";
        }
        if (classData?.slice(3) === "Boys") {
          return i?.gender === "male";
        } else {
          return i?.gender === "female";
        }
      });
      const sorted = st?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      setData(sorted);
      setClass(classData);
    } else {
      const studentValue = students?.filter((i) =>
        i?.class?.includes(classData)
      );
      const sorted = studentValue?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      setData(sorted);
      setClass(classData);
    }
  };

  const studentValue = students?.filter((i) => i?.class?.includes("Six"));
  const sorted = studentValue?.sort(function (a, b) {
    return a.roleNo - b.roleNo;
  });

  const [data, setData] = useState(sorted);
  const [subject, setSubject] = useState("Bangla");
  const [classes, setClass] = useState("Six");

  const ClassAttendance = Attendances?.find(
    (i) => i?.AttendanceName === classes
  );

  useEffect(() => {
    setClass("Six");
  }, []);

  if (userLoading || AttendanceLoading || meLoading) {
    return <Loading />;
  }

  let allAttendance = [];

  ClassAttendance?.Attendance?.map((i) => {
    i?.newArr?.map((a) => {
      if (new Date(a.date).toLocaleDateString() === date.toLocaleDateString()) {
        allAttendance.push(a);
      }
    });
  });

  let attendance = [];

  data &&
    data?.map((item) => {
      const data = {
        status: "P",
        date: new Date(),
        phoneNumber: item.phoneNumber,
        roleNo: item.roleNo,
      };

      attendance.push(data);
    });

  const newArr = [...attendance];

  const attendanceData = {
    subject: subject,
    date: new Date(),
    class: classes,
    name: me?.firstName + " " + me?.lastName,
    newArr,
  };

  return (
    <div className="p-2 sm:p-0">
      <div className="">
        <div className="w-full">
          <div className="bg-white rounded-lg w-full p-3">
            <div className="w-full flex element-font-4 justify-between">
              <h1 className="text-xl text-cyan-600 font-bold">
                {me?.firstName} {me?.lastName}
              </h1>
              <h1 className="text-xl text-purple-700 font-bold">
                {formateDate}
              </h1>
            </div>
            <div className="flex w-full element-font-4 mt-3 gap-x-3 justify-between">
              <div className="w-full">
                <p className="text-green-800 mb-1">Select Class</p>
                <select
                  onChange={(e) => handleClass(e.target.value)}
                  className="select sm:select-md select-sm select-bordered select-success rounded-md w-full"
                >
                  <option value="Six" select>
                    Six
                  </option>
                  <option value="Play">Play</option>
                  <option value="Nursery">Nursery</option>
                  <option value="One">One</option>
                  <option value="Two">Two</option>
                  <option value="Three">Three</option>
                  <option value="Four">Four</option>
                  <option value="Five">Five</option>
                  <option value="SevenBoys">SevenBoys</option>
                  <option value="SevenGirls">SevenGirls</option>
                  <option value="EightBoys">EightBoys</option>
                  <option value="EightGirls">EightGirls</option>
                  <option value="NineBoys">NineBoys</option>
                  <option value="NineGirls">NineGirls</option>
                  <option value="TenBoys">TenBoys</option>
                  <option value="TenGirls">TenGirls</option>
                </select>
              </div>
              <div className="w-full">
                <p className="text-green-800 mb-1">Select Subject</p>
                <select
                  onChange={(e) => setSubject(e.target.value)}
                  className="select sm:select-md select-sm select-bordered select-success rounded-md w-full"
                >
                  <option value="Bangla" select>
                    Bangla
                  </option>
                  <option value="English">English</option>
                  <option value="Math">Math</option>
                  <option value="Physics">Physics</option>
                  <option value="Biology">Biology</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-xl w-full px-2 sm:px-5 p-3 sm:p-5 mt-5">
          <div className="attendance-grid">
            {data &&
              data.map((student, index) => (
                <AttendanceRow
                  key={student._id}
                  index={index}
                  student={student}
                  newArr={newArr}
                  attendance={attendance}
                  attendanceData={attendanceData}
                />
              ))}
          </div>
          <div className="mt-5">
            <label
              htmlFor="confirm-attendance"
              onClick={() => setConfirmAttendance(attendanceData)}
              className="btn hover:bg-purple-800 bg-purple-700 cursor-pointer grid place-items-center mx-auto border-[8px] border-[#dfbced] hover:border-[#e2a7fa] w-full sm:h-14 h-12 rounded-full text-white font-bold"
            >
              Create Attendance
            </label>
          </div>
        </div>
        {allAttendance?.length > 0 && (
          <div className="my-5 max-w-full mx-auto p-4 bg-white rounded-2xl w-full">
            <h1 className="my-3 text-2xl font-bold text-center text-secondary">
              Today Attendance
            </h1>
            {allAttendance?.map((i, index) => (
              <AttendanceTable key={index} setLate={setLate} atds={i} />
            ))}
          </div>
        )}
        {me?.role === "admin" && (
          <div className="w-full hidden mt-5 mx-auto max-w-[500px]">
            <label
              // disable={(new Date().getFullYear()) === 2023}
              className="bg-purple-700 btn cursor-pointer grid place-items-center mx-auto border-[8px] border-[#dfbced] w-full h-16 rounded-full text-white text-xl font-bold"
              onClick={() => setShowAttName("Show")}
              htmlFor="show-attendance"
            >
              Create Attendance
            </label>
          </div>
        )}
      </div>
      {showAttName && (
        <AttendanceCreateName
          setShowAttName={setShowAttName}
          showAttName={showAttName}
        />
      )}
      {late && (
        <LateAttendance
          setLate={setLate}
          late={late}
          refetch={atRefetch}
          classes={classes}
          id={ClassAttendance?._id}
        />
      )}
      {confirmAttendance && (
        <ConfirmAttendance
          setConfirmAttendance={setConfirmAttendance}
          confirmAttendance={confirmAttendance}
          atRefetch={atRefetch}
          id={ClassAttendance?._id}
          ClassAttendance={ClassAttendance}
          date={date}
        />
      )}
    </div>
  );
};

export default Attendance;
