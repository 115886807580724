import React from "react";
import banner from "../../assets/images/banner.png";
import bannerJson from "../../assets/images/banner.json";
import WatchAnimateBtn from "../../Components/AnimateButtons/WatchAnimateBtn";
import NoticeMarquee from "./NoticeMarquee";
import Lottie from "lottie-react";

const Banner = () => {
  return (
    <div className="md:!pb-24 pb-5 lg:py-16 md:rounded-b-[80px] -z-10 bg-cyan-900 lg:w-full">
      <div className="hero  ">
        <div className="hero-content px-2 sm:px-4 md:px-8 lg:px-16 flex-col md:flex-row-reverse">
          <div className="order-1 pb-5">
            <img src={banner} className=" md:max-w-md" alt="banner" />
          </div>
          {/* <div className="order-1 pb-5">
            <Lottie
              animationData={bannerJson}
              loop={true}
              style={{ height: "100%" }}
            />
          </div> */}
          <div className="ox-hidden text-center mb-5 md:mb-0 md:text-left -order-3 md:order-3">
            <h1
              className="text-[36px] font-all pt-4 mb-2 sm:mb-5 md:mt-2 sm:text-6xl md:text-[80px] text-white font-bold"
              data-aos="zoom-in-up"
              data-aos-delay="300"
              data-aos-duration="800"
            >
              <span className="text-purple-400">নবজাগরণ</span> একাডেমি!
            </h1>
            <p
              className="my-1 font-all sm:text-xl md:my-4 text-white lg:my-4 md:w-3/4"
              data-aos="zoom-in-up"
              data-aos-delay="500"
              data-aos-duration="800"
            >
              প্রজন্মের দিকনির্দেশনা অঙ্গীকারবদ্ধ, শিক্ষা আলোর পথ যেন জ্বলতে
              থাকে সর্বদা, তাই আমরা শিক্ষার উজ্জ্বল পথে এবং সম্পূর্ণ নির্ভরযোগ্য
              ও বিশ্বস্ত পথে অগ্রসর করে যাচ্ছি একটি পরিপূর্ণ সমস্ত শিক্ষার
              প্রস্তুতি দেওয়ার জন্য।
            </p>
            <div
              className="flex justify-center md:justify-start"
              data-aos="zoom-in-right"
              data-aos-delay="800"
              data-aos-duration="800"
            >
              <WatchAnimateBtn />
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <NoticeMarquee />
      </div>
    </div>
  );
};

export default Banner;
