import { useQuery } from 'react-query';
import axios from '../Utils/Axios.config';

const useNotice = (me) => {

    const {data, isLoading, refetch, error, } = useQuery('notice', () =>
    axios.get('/notice').then((res) => res.data)
  );

  const myNotice = data?.filter(i => i?.examClass === me?.class);

    return [data, isLoading, refetch, myNotice, error];
}
export default useNotice;