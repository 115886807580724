import React, { useState } from "react";
import { toast } from "react-toastify";
import create1 from "../../assets/icons/create (1).png";
import create2 from "../../assets/icons/create (2).png";
import axios from "../../Utils/Axios.config";
import { ProgressBar } from "react-loader-spinner";

const CreateExam = ({ setOpenCreateExam, refetch, openCreateExam }) => {
  const xm = openCreateExam[0];

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month =
      date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return `${year}-${month}-${day}`;
  }

  const [loading, setLoading] = useState(false);
  const [examDate, setExamDate] = useState(formatDate(xm?.examDate));
  const [examName, setExamName] = useState(xm?.examName);
  const [examClass, setExamClass] = useState("All");
  const [examDescription, setExamDescription] = useState(xm?.description);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const examData = {
      examName: examName,
      examDate: examDate,
      examClass: examClass,
      description: examDescription,
    };

    console.log(examData);

    if (openCreateExam[1] === "create") {
      axios
        .post("/exam/create", examData)
        .then((response) => {
          console.log(response.data);
          refetch();
          setLoading(false);
          toast.success("Successfully create the Exam");
          setOpenCreateExam(null);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          toast.error("Opps No some this is Wrong..!!");
        });
    } else {
      axios
        .patch(`/exam/${xm?._id}`, examData)
        .then((response) => {
          console.log(response.data);
          refetch();
          setLoading(false);
          toast.success("Successfully update the Exam");
          setOpenCreateExam(null);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          toast.error("Opps No some this is Wrong..!!");
        });
    }
  };

  return (
    <div>
      <input type="checkbox" id="create-Exam" className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="bg-blue-100 pb-8 mb-10 h-auto modal-box p-3 py-5 sm:p-4">
          {openCreateExam[1] === "create" && (
            <div className="w-full text-center">
              <img src={create2} className="w-20 mx-auto" alt="create" />
              <h2 className="text-xl uppercase text-center font-bold text-primary">
                Create Exam
              </h2>
            </div>
          )}
          {openCreateExam[1] === "update" && (
            <div className="w-full text-center">
              <img src={create1} className="w-20 mx-auto" alt="create" />
              <h2 className="text-xl uppercase text-center font-bold text-primary">
                Update Exam
              </h2>
            </div>
          )}
          <form onSubmit={handleSubmit} className="w-full mt-3">
            <div className="rounded-xl p-3 bg-white w-full">
              <div className="flex items-center justify-between gap-3">
                <div className="form-control element-font-3 w-full">
                  <label className="label">
                    <span className="label-text text-cyan-900 font-bold">
                      Select Exam Date
                    </span>
                  </label>
                  <input
                    type="date"
                    required
                    value={examDate}
                    onChange={(e) => setExamDate(e.target.value)}
                    className="input input-bordered bg-transparent input-sm sm:input-md input-primary w-full"
                    name="examDate"
                    id=""
                  />
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-cyan-900 font-bold">
                      Select Class
                    </span>
                  </label>
                  <select
                    required
                    className="input input-bordered bg-transparent input-sm sm:input-md input-primary w-full"
                    onChange={(e) => setExamClass(e.target.value)}
                  >
                    <option value="All" select>
                      All
                    </option>
                    <option value="Play">Play</option>
                    <option value="Nursery">Nursery</option>
                    <option value="One">One</option>
                    <option value="Two">Two</option>
                    <option value="Three">Three</option>
                    <option value="Four">Four</option>
                    <option value="Five">Five</option>
                    <option value="Six">Six</option>
                    <option value="Seven">Seven</option>
                    <option value="Eight">Eight</option>
                    <option value="Nine">Nine</option>
                    <option value="Ten">Ten</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-cyan-900 font-bold">
                    Exam Name
                  </span>
                </label>
                <input
                  required
                  type="text"
                  placeholder="Exam Name"
                  value={examName}
                  onChange={(e) => setExamName(e.target.value)}
                  className="input input-bordered bg-transparent input-sm sm:input-md input-primary w-full"
                />
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-cyan-900 font-bold">
                    Exam Description
                  </span>
                </label>
                <textarea
                  required
                  placeholder="Exam Description"
                  value={examDescription}
                  onChange={(e) => setExamDescription(e.target.value)}
                  className="input min-h-[130px] input-bordered bg-transparent input-primary w-full"
                />
              </div>
            </div>
            <div className=" mt-5 p-3 py-5 rounded-xl bg-white">
              {loading && (
                <div className="w-full flex items-center justify-center">
                  <ProgressBar
                    height="80"
                    width="80"
                    ariaLabel="progress-bar-loading"
                    wrapperStyle={{}}
                    wrapperClass="progress-bar-wrapper"
                    borderColor="#F4442E"
                    barColor="#51E5FF"
                  />
                </div>
              )}
              <div className="flex items-center justify-center gap-3">
                <input
                  type="submit"
                  value="Submit"
                  className="btn w-[100px] btn-primary text-white btn-sm"
                />
                <label htmlFor="create-Exam" className="btn btn-sm w-[100px] ">
                  cancel
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateExam;
