import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Blog from '../Pages/Blog/Blog';
import AddResult from '../Pages/Dashboard/AddResult';
import AddReview from '../Pages/Dashboard/AddReview';
import ApplyUser from '../Pages/Dashboard/ApplyUser';
import Attendance from '../Pages/Dashboard/Attendance';
import Dashboard from '../Pages/Dashboard/Dashboard';
import MyProfile from '../Pages/Dashboard/MyProfile';
import Users from '../Pages/Dashboard/Users';
import Home from '../Pages/Home/Home';
import Login from '../Pages/Registation/SignIn';
import Registaion from '../Pages/Registation/Registaion';
import Notfound from '../Shared/Notfound';
import WalcomeApply from '../Pages/Registation/WalcomeApply';
import ExamControl from '../Pages/Dashboard/ExamControl';
import About from '../Pages/About/About';
import ShowResult from '../Pages/Dashboard/ShowResult';
import MyLeaderBoard from '../Pages/Dashboard/MyLeaderBoard';
import RequireAuth from '../Pages/Registation/RequireAuth';
import Announcement from '../Pages/Dashboard/Announcement';
import BulkSms from '../Pages/Dashboard/BulkSms';
import NoticeBoard from '../Pages/Dashboard/NoticeBoard';
import RewordBoard from '../Pages/Dashboard/RewordBoard';
import ResultCard from '../Pages/Dashboard/ResultCard';


const RouteList = () => {
    return (
        <div>
            <Routes>
                <Route path='/' element={<Home></Home>}></Route>
                <Route path='/login' element={<Login />}></Route>
                <Route path='/welcomeApply' element={<WalcomeApply />}></Route>
                <Route path='/register' element={<Registaion />}></Route>
                <Route path='/blog' element={<Blog />}></Route>
                <Route path='/about' element={<About />}></Route>
                <Route path='/dashboard' element={
                <RequireAuth>
                    <Dashboard  />
                </RequireAuth>
                }>
                    <Route index element={<MyProfile />}></Route>
                    <Route path='addReview' element={<AddReview />}></Route>
                    <Route path='users' element={<Users />}></Route>
                    <Route path='result' element={<ShowResult />}></Route>
                    <Route path='result-card' element={<ResultCard />}></Route>
                    <Route path='leaderBoard' element={<MyLeaderBoard />}></Route>
                    <Route path='examControl' element={<ExamControl />}></Route>
                    <Route path='applyUser' element={<ApplyUser />}></Route>
                    <Route path='announcement' element={<Announcement />}></Route>
                    <Route path='addResult' element={<AddResult />}></Route>
                    <Route path='bulkSms' element={<BulkSms />}></Route>
                    <Route path='notice' element={<NoticeBoard />}></Route>
                    <Route path='reword' element={<RewordBoard />}></Route>
                    <Route path='attendance' element={<Attendance />}></Route>
                </Route>
                <Route path='*' element={<Notfound />}></Route>
            </Routes>
        </div>
    );
};

export default RouteList;