import { Link } from "react-router-dom";
import bgCongrets from "../../assets/images/bgCongrets.jpg";
import bgComplete from "../../assets/images/complete2.png";
import { motion } from "framer-motion";
import ParticalBg from "../../Utils/particalBg/ParticalBg";

const WalcomeApply = () => {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${bgCongrets})` }}
        className="relative w-full bg-cover h-screen"
      >
        <div className="bg-[#2e715bab] absolute top-0 left-0 w-full h-screen flex sm:items-center justify-center items-end">
          <motion.div
            initial={{
              y: "20vw",
              transition: { type: "spring", damping: 2, duration: 0.1 },
            }}
            animate={{ y: 0, transition: { type: "spring", duration: 1.5 } }}
            exit={{ y: "20vw", scale: [1, 0], transition: { duration: 0.3 } }}
            className="max-w-2xl text-center p-5 pb-16 sm:pb-8 sm:p-8 rounded-3xl sm:rounded-b-3xl rounded-b-none shadow-2xl bg-white"
          >
            <div className="relative w-full sm:h-5 h-7">
              <div
                data-aos="zoom-in-up"
                data-aos-delay="500"
                data-aos-duration="500"
                className="w-[150px] h-[150px] absolute -top-28 sm:-top-32 sm:left-[34%] left-[30%]"
              >
                <img src={bgComplete} alt="" />
              </div>
            </div>
            <h3 className="font-bold text-green-500 text-4xl mt-5">
              Congratulations
            </h3>
            <p className="py-4 text-xl font-bold text-[#2b745e] mx-auto">
              You are successfully apply Nobojagormon Academy
            </p>
            <div className="rounded-md my-3 p-3 bg-slate-200">
              <p className="element-font-5">
                ধন্যবাদ, আপনার আবেদনটি সফল হয়েছে ২৪ থেকে ৪৮ ঘন্টার ভিতরে আপনার
                সাথে যোগাযোগ করা হবে এবং স্কুলে ভর্তি কনফার্ম হয়ে গেলে এসএমএসের
                মাধ্যমে পাসওয়ার্ড এবং ওয়েবসাইটের লিংক দেওয়া হবে।{" "}
              </p>
            </div>
            <Link className="" to="/">
              <p className="bg-[#267023] grid place-items-center mx-auto border-[8px] border-[#86eabd] w-32 h-16 rounded-full text-white text-xl font-bold">
                OK
              </p>
            </Link>
          </motion.div>
        </div>
      </div>
      <ParticalBg></ParticalBg>
    </>
  );
};

export default WalcomeApply;
