import React from 'react';
import { Link } from 'react-router-dom';

const ClassDetails = ({classDetails}) => {

    const {img, tittle, describe} = classDetails;
    return (
        <div>
            <input type="checkbox" id="class-details" className="modal-toggle" />
            <div className="modal h-screen w-full modal-bottom sm:modal-middle">
                <div className="modal-box w-full sm:!w-11/12 !max-w-3xl bg-white h-auto px-2 py-4 sm:py-8 sm:px-4">
                <label htmlFor="class-details" className="btn z-50 btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <div className='w-full font-all'>
                       <div>
                        <img src={img} className='w-full rounded-t-xl mx-auto' alt="complete" />
                       </div>
                       <h2 className='text-3xl mt-5 font-bold text-secondary'>{classDetails?.class} শ্রেণী</h2>
                       <p className='mt-3 font-bold text-xl'>{tittle}</p>
                       <ul className='my-3'>
                        {
                            describe?.map( (i, index) => (
                                <li key={index} className=' flex rounded-lg py-1 gap-x-1 my-2 px-2 bg-purple-50'>
                                   <span className='font-bold clement-font-5'>{(index === 0)? "০১-" : (index === 1)? "০২-" : (index === 2)? "০৩-" : (index === 0)? "০৪-" : (index === 0)? "" : "০৫-"}</span> 
                                   <p>{i?.title}</p>
                                </li>
                            ))
                        }
                       </ul>
                       <div className="w-full bg-[#fffcff] md:flex items-center justify-between gap-5 rounded-lg mx-auto my-3 p-5">                      
                            <Link to="/register" className="w-full">
                                <label htmlFor='class-details' className='bg-purple-700 grid place-items-center mx-auto cursor-pointer border-[8px] border-[#dfbced] w-full h-16 rounded-full text-white text-xl font-bold font-all'>রেজিস্ট্রেশন করুন</label>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClassDetails;