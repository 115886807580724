import React from "react";

const LeaderBoardInfo = ({ setLeaderBoardInfo }) => {
  return (
    <div>
      <input type="checkbox" id="Leader-board-info" className="modal-toggle" />
      <div className="modal h-screen modal-middle">
        <div className="modal-box pb-8 mb-10 sm:mb-0 bg-[#ffffff] border border-[#e7e7e7] h-auto px-2 py-4 sm:py-8 sm:px-4">
          <label
            htmlFor="Leader-board-info"
            className="z-50 w-8 h-8 grid place-content-center rounded-full text-white bg-[#662f93] absolute right-2 top-2"
          >
            ✕
          </label>
          <div className="text-lg sm:text-xl font-all">
            <p className="my-2 mt-5  ">
              <span className="font-bold">১-</span> প্ররত্যাক স্টুডেন্টের
              পরীক্ষার এক্সামিন করা হয়ে গেলে একটি নির্দিষ্ট সময় পর লিডার বোর্ড
              আপডেট করা হয়।
            </p>
            <p className="my-2 mt-5 ">
              <span className="font-bold">২-</span> পরীক্ষার ফলাফলের মানে
              ভিত্তিতে এই লিডারবোর্ড রেঙ্কিং করা হয়।
            </p>
            <p className="my-2 mt-5">
              <span className="font-bold">৩-</span> তুমি যদি নিজেকে এই
              লিডারবোর্ড দেখতে চাও তাহলে আরেকটু পরিশ্রম করতে হবে।
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoardInfo;
