import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../Shared/Loading';
import create1 from '../../assets/icons/create (1).png'
import axios from '../../Utils/Axios.config'
import { ProgressBar } from 'react-loader-spinner';

const UpdateExamStatus = ({setOpenUpdateExam, refetch, method, openUpdateExam}) => {

    const xm = openUpdateExam[0]
    const [loading, setLoading] = useState(false)


    const handleUpdate = (e) => {
        setLoading(true)

        const examData ={
            status: openUpdateExam[1],
        }

        axios.patch(`/${method}/${xm?._id}`, examData)
            .then(response => {
                console.log(response.data);
                refetch()
                setLoading(false)
                toast.success("Successfully update the Exam")
                setOpenUpdateExam(null)
            })
            .catch(error => {
                console.error(error);
                setLoading(false)
                toast.error("Opps No some this is Wrong..!!")
            });  

    }
    
    return (
        <div>
            <input type="checkbox" id="Update-Exam" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="bg-blue-100 pb-8 mb-10 h-auto modal-box p-3 py-5 sm:p-4">
                    <div className='w-full text-center'>
                        <img src={create1} className='w-20 mx-auto' alt="create" />
                        <h2 className='text-xl uppercase text-center font-bold text-primary'>Update {method}</h2>
                    </div>
                    <p className='text-xl mt-3 font-bold text-secondary text-center'>Are You Sure you Want to add {openUpdateExam[1]} step this {method}</p>
                    <div className=" mt-5 p-3 py-5 rounded-xl bg-white">
                        { loading &&
                            <div className='w-full flex items-center justify-center'>
                                <ProgressBar
                                height="80"
                                width="80"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{}}
                                wrapperClass="progress-bar-wrapper"
                                borderColor = '#F4442E'
                                barColor = '#51E5FF'
                                />
                            </div>
                        }
                        <div className='flex items-center justify-center gap-3'>
                            <button onClick={handleUpdate} className="btn w-[100px] btn-primary text-white btn-sm">Update</button>
                            <label htmlFor="Update-Exam" className="btn btn-sm w-[100px] ">cancel</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateExamStatus;