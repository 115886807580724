import React, { useContext } from 'react';
import { useState } from 'react';
import AddModulConfirm from '../Modul/AddModulConfirm';
import Loading from '../../Shared/Loading';
import { AnimatePresence, motion } from "framer-motion"
import ApplyUserCard from './ApplyUserCard';
import DeleteModalConfirm from '../Modul/DeleteModalConfirm';
import { userContext } from '../../App';

const ApplyUser = ({setApplyUsers}) => {
    const [users, userLoading, userRefetch, userError, ApplyUsers] = useContext(userContext);
    const [deletingModal, setDeletingModal] = useState(null)
    const [addModal, setAddModal] = useState(null)
    const [searchTerm, setSearchTerm] = useState('');
    

    if(userLoading){
        return <Loading />
    }

    
    const handleSearch = event => {
        setSearchTerm(event.target.value);
    };
    
    const filteredUsers = ApplyUsers?.filter(user => user.firstName.toLowerCase().startsWith(searchTerm.toLowerCase()));
    
    
    const method = 'user'
    return (
        <div className='mt-3 sm:mt-5 px-2 sm:px-0'>
            <div className='text-center w-full flex items-center justify-between p-3 py-4 shadow-md sm:mb-5 mb-3 mt-0 rounded-md bg-white'>
                <div className=''>
                    <input type="search" value={searchTerm} onChange={handleSearch} className='border rounded-3xl w-[150px] sm:w-auto ring-[#b06fff]-focus ring-[#eddeff] border-[#eddeff] ring-2 outline-0 p-1 px-3' placeholder='Search User' name="" id="" />
                </div>
                <h1 className='font-bold'>Applet: <span className='text-purple-700 '>{filteredUsers?.length}</span></h1>
            </div>
            <div className="overflow-x-auto">
                <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-4">
                <AnimatePresence>
                    {
                        filteredUsers && filteredUsers.map((user, index) => (
                            <motion.div
                            key={user._id}
                            layout 
                            animate={{ opacity: 1, scale: 1 }}
                            initial={{ opacity: 0, scale: 0 }}
                            exit={{ opacity: 0, scale: 0 }}
                            >
                                <ApplyUserCard 
                                    user={user}
                                    index={index}
                                    setDeletingModal={setDeletingModal}
                                    setAddModal={setAddModal}
                                    refetch={userRefetch} 
                                />
                            </motion.div>
                        ))
                    }
                </AnimatePresence>  
                </motion.div>
            </div>
            {
                deletingModal && <DeleteModalConfirm 
                deletingModal={deletingModal} 
                refetch={userRefetch} 
                setDeletingModal={setDeletingModal}
                method={method}
                />
            }
            {
                addModal && <AddModulConfirm 
                addModal={addModal} 
                refetch={userRefetch} 
                setAddModal={setAddModal}
                method={method}
                />
            }
        </div>
    );
};

export default ApplyUser;