import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ExamContext, MeContext, userContext } from "../../App";
import axios from "../../Utils/Axios.config";
import useTimer from "../../Hooks/useTimer";
import Loading from "../../Shared/Loading";
import { Link } from "react-router-dom";
import ResultCart from "../../Shared/ResultCart";
import AllResults from "../Modul/AllResults";
import { ProgressBar } from "react-loader-spinner";
import {
  MiddleSub,
  jscSub,
  primarySub,
  secondarySub,
  sscSub,
} from "../../data/subjectData";
import { GoodFeedback, badFeedback, normalFeedback } from "../../data/feedback";
import { allClassData } from "../../data/classData";
import EditeSubmition from "../Modul/EditeSubmition";

const AddResult = () => {
  const [me, meLoading] = useContext(MeContext);
  const [xms, xmLoading, xmRefetch, myXm, xmError] = useContext(ExamContext);
  const [users, userLoading, userRefetch, userError] = useContext(userContext);
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const students = users?.filter((i) => i?.role?.includes("student"));
  const [date, setDate] = useState(new Date());
  const [showResults, setShowResults] = useState(null);
  const [editSubmit, setEditSubmit] = useState(null);
  const [feedback, setFeedback] = useState(normalFeedback);
  const [disable, setDisable] = useState(false);
  const [sub, setSub] = useState(primarySub);
  const [classes, setClass] = useState("Play");
  const [subject, setSubject] = useState(sub[0]?.name);

  const formateDate = format(date, "PP");

  const xmdate = xms?.find((i) => i?.status?.includes("pending"));
  const xmRunning = xms?.find((i) => i?.status?.includes("running"));
  const [timeLefts] = useTimer(xmdate?.examDate);

  if (userLoading || meLoading || xmLoading) {
    <Loading />;
  }

  // ============ class ===========
  const handleClass = (classData) => {
    if (
      classData === "Seven-Boys" ||
      classData === "Seven-Girls" ||
      classData === "Eight-Boys" ||
      classData === "Eight-Girls" ||
      classData === "Nine-Boys" ||
      classData === "Nine-Girls" ||
      classData === "Ten-Boys" ||
      classData === "Ten-Girls"
    ) {
      const studentValue = students?.filter(
        (i) => i?.class === classData?.split("-")[0]
      );

      const st = studentValue?.filter((i) => {
        if (classData?.split("-")[1] === "Boys") {
          return i?.gender === "male";
        } else {
          return i?.gender === "female";
        }
      });
      const sorted = st?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      setData(sorted);
      setClass(classData?.split("-")[0]);
    } else {
      const studentValue = students?.filter((i) =>
        i?.class?.includes(classData)
      );
      const sorted = studentValue?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      setData(sorted);
      setClass(classData);
    }

    // Subject select
    if (
      classData === "Play" ||
      classData === "Nursery" ||
      classData === "One" ||
      classData === "Two"
    ) {
      setSub(primarySub);
      setSubject(primarySub[0].name);
    } else if (
      classData === "Three" ||
      classData === "Four" ||
      classData === "Five"
    ) {
      setSub(secondarySub);
      setSubject(secondarySub[0].name);
    } else if (classData === "Six" || classData?.split("-")[0] === "Seven") {
      setSub(MiddleSub);
      setSubject(MiddleSub[0].name);
    } else if (classData?.split("-")[0] === "Eight") {
      setSub(jscSub);
      setSubject(jscSub[0].name);
    } else {
      setSub(sscSub);
      setSubject(sscSub[0].name);
    }
  };

  // By default role set
  const studentValue = students?.filter((i) => i?.class?.includes(classes));
  const sorted = studentValue?.sort(function (a, b) {
    return a.roleNo - b.roleNo;
  });

  const [data, setData] = useState(sorted);

  // ========= Role ===========
  const handleRole = (role) => {
    const student = data?.find((i) => i?.roleNo?.includes(role));
    setRole(student);
    // setSubject(sub[0]?.name);
  };

  const student = data?.find((i) => i?.roleNo?.includes("01"));
  const [Role, setRole] = useState(student);

  // calculate subject and practical subject
  const currentSub = sub?.find((i) => i?.name?.includes(subject));

  // ======= add result =======
  const allSub = [];

  const Myxm = xms?.find((i) => i?.status?.includes("processing"));
  const xmResult = Myxm?.Results?.filter((i) => i?.class?.includes(classes));
  const subXm = xmResult?.map((i) => {
    const sub = i?.subResult?.find((x) => x?.subject === subject);
    if (sub) {
      sub.studentPh = i?.studentPh;
      allSub.push(sub);
    }
  });

  const mydisable = () => {
    const exsistResult = Myxm?.Results?.find((i) =>
      i?.studentPh?.includes(Role?.phoneNumber)
    );
    const exsistSubject = exsistResult?.subResult?.find(
      (i) => i?.subject === subject
    );

    if (exsistSubject?.subject === subject) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (data) => {
    setDisable(true);

    const result = {
      studentPh: Role?.phoneNumber,
      class: Role?.class,
      roleNo: Role?.roleNo,
      subResult: [
        {
          subject: subject,
          writing: data.writing ? data.writing : undefined,
          mcq: data.mcq ? data.mcq : undefined,
          pecticNun: data.pecticNun ? data.pecticNun : undefined,
          sir: me?.firstName + " " + me?.lastName,
          feedback: feedback,
          date: date,
        },
      ],
    };

    axios
      .patch(`/exam/addResult/${Myxm?._id}`, result)
      .then((response) => {
        console.log(response.data);
        toast.success("Successfully submit the Result");
        reset();
        xmRefetch();
        setDisable(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Opps No something is Wrong..!!");
        setDisable(false);
      });
  };

  return (
    <div className="p-2 sm:p-0">
      <div className="w-full">
        <div className="bg-white rounded-lg w-full p-3">
          <div className="w-full flex justify-between">
            <h1 className="text-xl text-cyan-600 font-bold">
              {me?.firstName} {me?.lastName}
            </h1>
            <h1 className="text-xl text-purple-700 font-bold">{formateDate}</h1>
          </div>
          <div className="flex w-full mt-3 gap-x-2 sm:gap-x-3 justify-between">
            <div className="w-full">
              <p className="text-green-800 mb-1">Select Class</p>
              <select
                onChange={(e) => handleClass(e.target.value)}
                className="select sm:select-md select-sm select-bordered px-1 select-success rounded-md w-full"
              >
                {allClassData?.map((item, index) => (
                  <option key={index} value={item?.className}>
                    {item?.className}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full">
              <p className="text-green-800 mb-1">Select Subject</p>
              <select
                onChange={(e) => setSubject(e.target.value)}
                className="select sm:select-md select-sm select-bordered px-1 select-success rounded-md w-full"
              >
                {sub?.map((item, index) => (
                  <option key={index} value={item?.name.toString()}>
                    {item?.name.toString()}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-40 sm:w-52">
              <p className="text-green-800 mb-1">RollNo</p>
              <select
                onChange={(e) => handleRole(e.target.value)}
                className="select sm:select-md select-sm select-bordered px-1 select-success rounded-md w-full"
              >
                {data?.map((item, index) => (
                  <option key={index} value={item?.roleNo}>
                    {item?.roleNo}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="rounded-lg w-full bg-white p-3 mt-2 sm:mt-3">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center w-full">
              <div className="">
                {Role?.imageURL && (
                  <div className="w-9 h-9 ring ring-[#91f2dc] ring-offset-base-100 ring-offset-2 bg-primary rounded-full">
                    <img
                      src={Role?.imageURL}
                      className="w-9 h-9 rounded-full"
                      alt="profile"
                    />
                  </div>
                )}
                {!Role?.imageURL && (
                  <div className="w-full">
                    <div className="w-10 h-10 bg-primary rounded-full flex items-center justify-center ring ring-[#91f2dc] ring-offset-base-100 ring-offset-2">
                      <h2 className="text-xl element-font-5 uppercase font-bold text-white">
                        {Role?.firstName?.slice(0, 1)}
                        {Role?.lastName?.slice(0, 1)}
                      </h2>
                    </div>
                  </div>
                )}
              </div>
              <div className="ml-2 element-font-4">
                <h1 className="text-cyan-900 mb-0 font-bold">
                  {Role?.firstName} {Role?.lastName}
                </h1>
                <h1 className="text-sm text-green-900 font-bold">
                  {Role?.class}
                </h1>
              </div>
            </div>
            <div className="">
              <h1 className="text-secondary element-font-4 text-xl mb-0 font-bold">
                {Role?.roleNo}
              </h1>
            </div>
          </div>
          <div className="w-full">
            <form onSubmit={handleSubmit(onSubmit)} className="w-full">
              <div className="mt-3">
                <div className="flex text-center font-reem gap-2 sm:gap-3 justify-between items-center">
                  {currentSub?.wMax && (
                    <div className="w-full">
                      <input
                        type="number"
                        placeholder="Writing part"
                        className="input input-bordered input-success w-full"
                        {...register("writing", {
                          required: {
                            value: true,
                            message: "Result required",
                          },
                          max: {
                            value: currentSub?.wMax,
                            message: `Please enter a number less than or equal to ${currentSub?.wMax}.`,
                          },
                        })}
                      />
                      <label className="label text-xs">
                        {errors.writing?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.writing.message}
                          </span>
                        )}
                        {errors.writing?.type === "max" && (
                          <span className="label-text-alt text-red-500">
                            {errors.writing.message}
                          </span>
                        )}
                      </label>
                    </div>
                  )}
                  {currentSub?.mcq && (
                    <div className="w-full">
                      <input
                        type="number"
                        placeholder="MCQ part"
                        className="input input-bordered input-success w-full"
                        {...register("mcq", {
                          required: {
                            value: true,
                            message: "MCQ required",
                          },
                          max: {
                            value: currentSub?.mcq,
                            message: `Please enter a number less than or equal to ${currentSub?.mcq}.`,
                          },
                        })}
                      />
                      <label className="label text-xs">
                        {errors.mcq?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.mcq.message}
                          </span>
                        )}
                        {errors.mcq?.type === "max" && (
                          <span className="label-text-alt text-red-500">
                            {errors.mcq.message}
                          </span>
                        )}
                      </label>
                    </div>
                  )}
                  {currentSub?.practical && (
                    <div className="w-full">
                      <input
                        type="number"
                        placeholder="Practical Number"
                        className="input input-bordered input-success w-full"
                        {...register("pecticNun", {
                          required: {
                            value: true,
                            message: "Practical required",
                          },
                          max: {
                            value: currentSub?.practical,
                            message: `Please enter a number less than or equal to ${currentSub?.practical}.`,
                          },
                        })}
                      />
                      <label className="label text-xs">
                        {errors.pecticNun?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.pecticNun.message}
                          </span>
                        )}
                        {errors.pecticNun?.type === "max" && (
                          <span className="label-text-alt text-red-500">
                            {errors.pecticNun.message}
                          </span>
                        )}
                      </label>
                    </div>
                  )}
                </div>
                <div className="relative font-reem my-1.5">
                  <textarea
                    className="textarea leading-normal min-h-[130px] p-3 w-full textarea-success"
                    placeholder="Exam Feedback"
                    onChange={(e) => setFeedback(e.target.value)}
                    value={feedback}
                  ></textarea>
                  <div className="absolute flex gap-x-1 -top-3 right-2">
                    <span
                      onClick={() => setFeedback(normalFeedback)}
                      className="btn btn-xs btn-secondary"
                    >
                      Normal
                    </span>
                    <span
                      onClick={() => setFeedback(GoodFeedback)}
                      className="btn btn-xs btn-success text-white"
                    >
                      good
                    </span>
                    <span
                      onClick={() => setFeedback(badFeedback)}
                      className="btn btn-xs btn-error"
                    >
                      bad
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full mb-1">
                {disable && (
                  <div className="w-full flex items-center justify-center">
                    <ProgressBar
                      height="80"
                      width="80"
                      ariaLabel="progress-bar-loading"
                      wrapperStyle={{}}
                      wrapperClass="progress-bar-wrapper"
                      borderColor="#F4442E"
                      barColor="#51E5FF"
                    />
                  </div>
                )}
                <input
                  type="submit"
                  value="Submit"
                  disabled={mydisable() || !Myxm || disable}
                  className={`${
                    disable ? "cursor-not-allowed" : ""
                  } btn btn-success text-white w-full`}
                />
              </div>
            </form>
          </div>
        </div>

        {allSub?.length > 0 &&
          (me?.role === "admin" ||
            me?.phoneNumber === "+8801963886310" ||
            me?.phoneNumber === "+8801601621461") && (
            <div className="my-5 w-full p-4 px-3 bg-[#fffcff] rounded-xl">
              <h3 className="text-xl font-bold mb-4 text-purple-700 text-center">
                {subject} Result submition {allSub?.length}
              </h3>
              <ResultCart exam={allSub} setEditSubmit={setEditSubmit} />
            </div>
          )}

        {xmRunning?.status === "running" && (
          <h1 className="text-center max-w-[500px] element-font-4 capitalize bg-white mx-auto mt-3 p-3 rounded-xl text-2xl mb-2 text-purple-700 font-bold">
            Now the {xmRunning?.examName} is going on.
          </h1>
        )}
        {Myxm?.status === "processing" && (
          <h1 className="text-center max-w-[500px] element-font-4 capitalize bg-white mx-auto mt-3 p-3 rounded-xl text-2xl mb-2 text-purple-700 font-bold">
            {" "}
            {Myxm?.examName} is Processing
          </h1>
        )}
        {(!timeLefts?.dateOver || xmdate) && (
          <div className=" mb-4 mt-5 w-full">
            <div className="max-w-[500px] mx-auto mb-5 bg-[#fffcff] p-3 rounded-xl">
              {(!timeLefts?.dateOver || xmdate) && (
                <h1 className="text-center capitalize element-font-4 text-2xl mb-2 text-purple-700 font-bold">
                  {xmdate?.examName} Start After
                </h1>
              )}
              {!timeLefts?.dateOver && (
                <div className="flex gap-3 element-font-4 max-w-[500px] mx-auto mt-5 items-center justify-center">
                  <div className="p-2 w-full text-center border border-purple-700 rounded-lg bg-[#f0e1f6]">
                    <h1 className="text-2xl font-bold text-purple-700">
                      {timeLefts?.days}
                    </h1>
                    <span>Days</span>
                  </div>
                  <div className="p-2 w-full text-center border border-purple-700 rounded-lg bg-[#f0e1f6]">
                    <h1 className="text-2xl font-bold text-purple-700">
                      {timeLefts?.hours}
                    </h1>
                    <span>Hours</span>
                  </div>
                  <div className="p-2 w-full text-center border border-purple-700 rounded-lg bg-[#f0e1f6]">
                    <h1 className="text-2xl font-bold text-purple-700">
                      {timeLefts?.minutes}
                    </h1>
                    <span>Minutes</span>
                  </div>
                  <div className="p-2 w-full text-center border border-purple-700 rounded-lg bg-[#f0e1f6]">
                    <h1 className="text-2xl font-bold text-purple-700">
                      {timeLefts?.seconds}
                    </h1>
                    <span>Seconds</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="my-4 w-full max-w-[500px] mx-auto p-4 bg-[#fffcff] rounded-xl">
          <Link
            to="/dashboard/result-card"
            className="btn capitalize rounded-3xl py-1 border-[3px] border-purple-500 w-full text-lg font-reem bg-purple-700 text-white"
          >
            Result Download / Print
          </Link>
        </div>

        <div className="w-full bg-[#fffcff] md:flex items-center justify-between gap-5 rounded-lg mx-auto my-3 p-5">
          <label
            onClick={() => setShowResults("show result")}
            htmlFor="All-result"
            className="bg-purple-700 mb-4 md:mb-0 grid place-items-center cursor-pointer mx-auto border-[8px] border-[#dfbced] w-full h-16 rounded-full text-white text-xl font-bold"
          >
            Show Results
          </label>
          {me?.role === "admin" && (
            <Link to="/dashboard/examControl" className="w-full">
              <p className="bg-purple-700 grid place-items-center mx-auto border-[8px] border-[#dfbced] w-full h-16 rounded-full text-white text-xl font-bold">
                Exam Control Board
              </p>
            </Link>
          )}
        </div>
      </div>
      {showResults && <AllResults />}
      {editSubmit && (
        <EditeSubmition
          editSubmit={editSubmit}
          xmID={Myxm?._id}
          setEditSubmit={setEditSubmit}
          refetch={xmRefetch}
        />
      )}
    </div>
  );
};

export default AddResult;
