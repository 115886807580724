import { useQuery } from 'react-query';
import axios from '../Utils/Axios.config';

const useExam = (me) => {

    const {data, isLoading, refetch, error, } = useQuery('exam', () =>
    axios.get('/exam').then((res) => res.data)
  );

  const myXm = data?.filter(i => i?.examClass === me?.class);

    return [data, isLoading, refetch, myXm, error];
}
export default useExam;