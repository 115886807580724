import React from 'react';
import { BsWhatsapp } from 'react-icons/bs';

function WhatsAppLink({ phoneNumber, message, size }) {
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
        <span className='font-bold'><BsWhatsapp size={size} /></span>
    </a>
  );
}

export default WhatsAppLink;