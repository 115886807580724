import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import mijan from "../../assets/images/mijan.jpg"
import lalchan from "../../assets/images/lachan.jpg"

const Director = () => {

    const biodata = <p>
        সৌমিত্র জাতি গঠনে শিক্ষার কোন বিকল্প নেই। আর মানসম্মত শিক্ষা ব্যবস্থায় পারে সম-উন্নত জাতি গঠন করতে। <span className="font-bold">"নবজাগরণ একাডেমি"</span> মানসম্মত শিক্ষা প্রত্যয় নিয়েই শুরু করেছে তার অগ্রযাত্রা। মেধা মননের বিকাশে অনুকূল পরিবেশে ব্যাপক ভূমিকা রাখে। শিক্ষামূলক পরিবেশ শিক্ষার্থীকে গড়ে তোলে দক্ষ ভাবে। <br /> 
    আমাদের অঙ্গীকার নবজাগরণ একাডেমি একটি আধুনিক শিক্ষার প্রাণকেন্দ্র হিসেবে গড়ে তোলা। আমাদের এই অঙ্গীকারের সফল রূপায়নে প্রয়োজন শিক্ষার্থী সম্মানিত অভিভাবক ও সুধী মহলের সহযোগিতা। <br /> 
    আলোকিত জাতি গঠনের জন্য প্রয়োজন শিক্ষিত জনগোষ্ঠী, সেই সাথে প্রয়োজন আধুনিক শিক্ষা ব্যবস্থা এবং সমান্তরালে আধুনিক শিক্ষা প্রতিষ্ঠান। গারো পাহাড়ের পাদদেশে অবস্থিত আমাদের প্রিয় নন্নী ও পাড়াগাঁও ইউনিয়ন। শিক্ষা ক্ষেত্রে অন্যান্য ইউনিয়নে তুলনায় আমরা অনেক পিছিয়ে। শিক্ষা ক্ষেত্রে এ ব্যর্থতায় আমরা মাথা উঁচু করে কথা বলতে পারিনা, আর এই ব্যর্থতা প্রতিফলিত হয় আমাদের বিভিন্ন সামাজিক ক্ষেত্রে। এ ব্যর্থতা থেকে উত্তরণের মাধ্যমে আলোকিত নন্নী ও পাড়াগাঁও সমগ্র দেশ গঠনের চেষ্টায় জন্ম হয়েছে <span className="font-bold">"নবজাগরণ একাডেমি"</span> পরিশেষে সকল শিক্ষার্থী অভিভাবক ও শুভাকাঙ্খীদের জানাই আন্তরিক অভিনন্দন ও শুভেচ্ছা।
    </p>

    const biodata1 = <p>
        পরিবর্তিত বিশ্ব পরিস্থিতিতে পরিবর্তনের ছোঁয়া লেগেছে প্রতিটি ক্ষেত্রে। গুণগত পরিবর্তন এসেছে আমাদের শিক্ষা ব্যবস্থায়। তথ্য প্রযুক্তির উন্নতির ফলে জ্ঞানের বিশাল ভান্ডার আজ হাতের নাগালে চলে এসেছে। আধুনিক শিক্ষায় শিক্ষিত এবং পরিপূর্ণ মানুষ হিসেবে গড়ে তোলা প্রত্যয়ে এগিয়ে চলেছে <span className="font-bold">"নবজাগরণ একাডেমি"</span> নবজাগরণ একাডেমির অগ্রযাত্রায় আন্তরিক সকল সম্মানিত শিক্ষক-শিক্ষিকা, শুভাকাঙ্ক্ষী এবং অভিভাবকের শুভেচ্ছা ও কৃতজ্ঞতা জানাচ্ছি, সকলের দোয়া ও সহযোগিতা আমাদের এই প্রত্যাশা।
    </p>



    const bioData = [
        {
            name: "মিজানুর রহমান",
            bio: biodata,
            title: 'পরিচালক',
            title1: 'বি.এ অনার্স, এম.এ (বাংলা)',
            img: mijan,
        },

        {
            name: "লালচান আহমেদ",
            bio: biodata1,
            title: 'নির্বাহী পরিচালক',
            title1: 'বি.এস.এস অনার্স, এম.এস.এস (অর্থনীতি)',
            img: lalchan,
        }
        
    ]
    return (
        <div className='max-w-7xl mx-auto px-2 md:px-8 lg:px-16 ox-hidden'>
            <div className='w-full'>
                <Swiper
                    loop={true}
                    navigation={true}
                    autoplay={{
                        delay: 7500,
                        disableOnInteraction: true,
                      }}
                    modules={[Autoplay, Navigation]}
                    className="testimonial-slider navigation-bl navigation-outline"
                >
                    {
                        bioData.map((data, index) => (
                            <SwiperSlide key={index}>
                                <h1 className='text-4xl text-center my-5 mb-8 md:text-5xl sm:mb-8 font-all sm:element-font-1 font-bold'>{data?.title}</h1>  
                                <div className='sm:flex items-center gap-x-4'>
                                    <div className='w-full  py-5'>
                                        <div className='sm:w-[300px] w-[250px] bg-[#a543bd9a] mx-auto rounded-xl border-4 border-purple-600'>
                                            <img src={data?.img} className="rounded-xl bg-[#68bdee] img-routed border-4 border-purple-600 w-full" alt="director" />
                                        </div>
                                    </div>
                                    <div className="w-full font-all border-0 sm:border-l-2 p-5 border-purple-700 px-3 sm:pl-5">
                                        <div className='mt-5 sm:mt-0'>
                                            <p className='text- sm:text-[20px]'>{data?.bio}</p>
                                        </div>
                                        <div className='mt-5'>
                                            <h3 className='pb-0 font-bold text-[28px]'>{data?.name}</h3>
                                            <h5 className='pt-0 text-[16px]'>{data?.title1}</h5>
                                            <h5 className='text-[20px]'>{data?.title} - নবজাগরণ একাডেমি</h5>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    );
};

export default Director;