import React from "react";
import { useContext } from "react";
import Marquee from "react-fast-marquee";
import { NoticeContext } from "../../App";

const NoticeMarquee = () => {
  const [notices, noticeLoading, noticeRefetch] = useContext(NoticeContext);

  const NoticeRunning = notices?.filter((i) => i?.status?.includes("running"));

  let notice = "";

  NoticeRunning?.reverse()?.map((i, index) => {
    if (i?.noticeClass === "All") {
      if (index > 0) {
        notice = notice + " এবং " + i?.noticeTitle + "  ";
      } else {
        notice = i?.noticeTitle + " ";
      }
    }
  });

  return (
    <div className="max-w-5xl mx-auto px-2 md:px-8 lg:px-16s">
      <div className="md:mt-5">
        <Marquee
          className="rounded-md  text-purple-400 text-[15px]  md:text-[24px] font-bold"
          gradientWidth={80}
          gradientColor={[163, 85, 235, 0.89]}
          speed={20}
        >
          {notice && <h1 className="py-1 font-all"> {notice} </h1>}
          {!notice && <h1 className="py-1 font-all"> নবজাগরণ একাডেমি </h1>}
        </Marquee>
      </div>
    </div>
  );
};

export default NoticeMarquee;
