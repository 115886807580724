import React from 'react';
import { useState } from 'react';
import { ProgressBar } from 'react-loader-spinner';
import { toast} from 'react-toastify';
import delete1 from  '../../assets/icons/delete.png'
import axios from '../../Utils/Axios.config'

const DeleteModalConfirm = ({deletingModal, method, refetch, setDeletingModal}) => {
    const { _id } = deletingModal;
    const [loading, setLoading] = useState(false);

    const handleDelete = () => {
        setLoading(true)
        axios.delete(`/${method}/${_id}`)
        .then(response => {
            console.log(response.data);
            refetch();
            toast.success(` Deleted successfully`);
            setLoading(false)
            setDeletingModal(null)
        })
        .catch(error => {
            console.error(error);
            toast.error(` Ops No..! something is Wrong`);
            setLoading(false)
        });
    }
    
    return (
        <div>
            <input type="checkbox" id="delete-confirm-modal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box pb-8 mb-10 sm:mb-0 bg-blue-100">
                    <div className='text-center'>
                        <img src={delete1} className='w-20 mx-auto' alt="delete" />
                    </div>
                    <h2 className="text-red-700 text-center text-2xl">Are you sure you want to delete This item</h2>
                    <div className=" mt-5">
                        { loading &&
                            <div className='w-full flex items-center justify-center'>
                                <ProgressBar
                                height="80"
                                width="80"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{}}
                                wrapperClass="progress-bar-wrapper"
                                borderColor = '#F4442E'
                                barColor = '#51E5FF'
                                />
                            </div>
                        }
                        <div className="flex items-center justify-center gap-3">
                            <button onClick={handleDelete} className="btn w-[100px] btn-primary text-white btn-sm">Yes</button>
                            <label htmlFor="delete-confirm-modal" className="btn btn-sm w-[100px] ">No</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteModalConfirm;