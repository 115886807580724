import React, { useContext, useState } from "react";
import { attendanceContext, MeContext } from "../../App";
import Loading from "../../Shared/Loading";
import bg1 from "../../assets/images/banks.jpg";
import UpdateModul from "../Modul/UpdateModul";
import UserProfileImg from "../../Components/AnimateButtons/UserProfileImg";
import ProfileInformation from "../../Components/AnimateButtons/ProfileInformation";
import AttendancePiker from "../../Components/AnimateButtons/AttendancePiker";
import XmChart from "../../Components/AnimateButtons/XmChart";
import LeardBoard from "../../Components/AnimateButtons/LeardBoard";
import LeaderBoardInfo from "../Modul/LeaderBoardInfo";
import ResultSheet from "../Modul/ResultSheet";
import AttendanceTable from "../../Shared/AttendanceTable";
import edit from "../../assets/icons/user-profile.png";
import resetPass from "../../assets/icons/reset-password.png";
import logout1 from "../../assets/icons/logout1.png";
import LogOutModule from "../Modul/LogOutModule";
import AttendanceToday from "../Modul/AttendanceToday";
import UpdatePass from "../Modul/UpdatePass";
import { Link } from "react-router-dom";
import LateAttendance from "../Modul/LateAttendance";
import LeaderBoardPrint from "../Modul/LeaderBoardPrint";

const MyProfile = () => {
  const [me, meLoading, meRefetch, meError] = useContext(MeContext);
  const [
    Attendances,
    AttendanceLoading,
    atRefetch,
    myAttendance,
    present,
    absent,
  ] = useContext(attendanceContext);
  const [updateModal, setUpdateModal] = useState(null);
  const [resultSheet, setResultSheet] = useState(null);
  const [logout, setLogout] = useState(null);
  const [updatePassModal, setUpdatePassModal] = useState(null);
  const [attendanceToday, setAttendanceToday] = useState(null);
  const [leaderBoardInfo, setLeaderBoardInfo] = useState(null);
  const [leaderBoardPrint, setLeaderBoardPrint] = useState(null);
  const date = new Date();
  const [late, setLate] = useState(null);
  const [classes, setClass] = useState("Six");
  const [dates, setDate] = useState(formatDate(date));

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month =
      date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return `${year}-${month}-${day}`;
  }

  if (meLoading) {
    return <Loading />;
  }

  let pre = [];
  let abs = [];

  present?.map((i) => {
    const p = new Date(i.date);
    pre.push(p);
  });

  absent?.map((i) => {
    const A = new Date(i.date);
    abs.push(A);
  });

  // const todayAt = myAttendance?.find(i => date.toLocaleDateString() === new Date(dates).toLocaleDateString());
  const todayAt = myAttendance?.find(
    (i) =>
      new Date(i.date).toLocaleDateString() === new Date().toLocaleDateString()
  );

  const ClassAttendance = Attendances?.find(
    (i) => i?.AttendanceName === classes
  );

  let allAttendance = [];

  ClassAttendance?.Attendance?.map((i) => {
    i?.newArr?.map((a) => {
      if (
        new Date(a.date).toLocaleDateString() ===
        new Date(dates).toLocaleDateString()
      ) {
        allAttendance.push(a);
      }
    });
  });

  return (
    <div className="">
      <div className="md:flex gap-3 mt-0 pt-0 sm:mt-4 w-full">
        <div className="w-full z-0 sm:z-[1]">
          <div
            style={{ backgroundImage: `url(${bg1})` }}
            className="bg-cover -z-[0] sm:-z-[1] overflow-hidden w-full relative rounded-b-[100px] h-[280px] sm:h-[320px] bg-gradient-to-t from-cyan-500 to-blue-500"
          >
            <div className="absolute z-10 w-full h-full top-0 left-0 bg-gradient-to-t from-[#52cce7d9] to-[#5270e7d3]">
              <div className="cursor-pointer mb-9 relative w-28 h-28 sm:w-36 sm:h-36 mx-auto flex justify-center pt-6">
                {me?.imageURL && (
                  <div className="avatar">
                    <div className=" w-28 h-28 sm:w-36 sm:h-36 ring-[6px] rounded-full bg-secondary flex items-center justify-center ring-[#91f2dc] ring-offset-base-100 ring-offset-2">
                      <img src={me?.imageURL} alt="profile" />
                    </div>
                  </div>
                )}
                {!me?.imageURL && (
                  <div className="relative w-28 h-28 sm:w-36 sm:h-36">
                    <UserProfileImg
                      me={me}
                      textColor="sm:text-[70px] ring-offset-[3px] text-[60px] text-white"
                      className="w-28 h-28 sm:w-36 bg-secondary sm:h-36 ring-[6px] "
                    />
                  </div>
                )}
                <div className="absolute sm:bottom-16 bottom-12 sm:-right-4 -right-3 text-[18px] bg-white border-[4px] border-[#91f2dc] rounded-full w-8 h-8 !grid !place-content-center">
                  {todayAt?.status === "P" && (
                    <p className="text-green-700 font-bold">P</p>
                  )}
                  {(todayAt?.status === "A" || !todayAt) && (
                    <p className="text-red-700 font-bold">A</p>
                  )}
                </div>
              </div>
              <p className="text-3xl mt-3 font-bold text-white text-center">
                {me?.firstName} {me?.lastName}
              </p>
              <p className="text-xl sm:mt-1 font-bold text-[#f0e0fc] text-center">
                {me?.address}
              </p>
            </div>
            <div className="w-full absolute top-3 left-0 flex items-center justify-between px-2">
              <div className="!z-[10000] w-20 flex items-center">
                <label
                  onClick={() => setUpdateModal(me)}
                  htmlFor="add-update-modal"
                  className="w-10 h-10 cursor-pointer rounded-3xl"
                >
                  <img src={edit} className="w-full" alt="" />
                </label>
                <label
                  onClick={() => setUpdatePassModal(me)}
                  htmlFor="update-password"
                  className="w-10 ml-2 h-10 cursor-pointer rounded-3xl"
                >
                  <img src={resetPass} className="w-full" alt="" />
                </label>
              </div>
              <label
                onClick={() => setLogout("logout")}
                htmlFor="Logout-modal"
                className="w-10 h-10 cursor-pointer rounded-3xl !z-[10000]"
              >
                <img src={logout1} className="w-full" alt="" />
              </label>
            </div>
          </div>

          <div className="w-full !z-[100] relative -mt-[60px] px-2 sm:px-4">
            <ProfileInformation
              user={me}
              present={present}
              setAttendanceToday={setAttendanceToday}
              absent={absent}
              meLoading={meLoading}
            />
            {!(me?.role === "teacher" || me?.role === "admin") && (
              <div className="border-[#eddeff] bg-[#f3f9fb] sm:my-5 my-3 w-full border-b border rounded-xl p-3">
                <h2 className="text-2xl text-purple-700 font-bold text-center">
                  Attendance and Result
                </h2>
              </div>
            )}
            <div className="w-full md:flex justify-between gap-3 sm:gap-5 sm:my-5 my-3">
              {!(me?.role === "teacher" || me?.role === "admin") && (
                <div className="sm:hidden">
                  <div className="border-[#eddeff] w-full bg-[#f3f9fb] mb-5 sm:mb-0 mx-auto sm:w-[350px] border-b border rounded-2xl">
                    <AttendancePiker pre={pre} abs={abs} />
                  </div>
                </div>
              )}
              {!(me?.role === "teacher" || me?.role === "admin") && (
                <div className="border-[#eddeff] w-full bg-[#f3f9fb] border-b border p-3 rounded-2xl">
                  <XmChart user={me} setResultSheet={setResultSheet} />
                </div>
              )}
            </div>

            {me?.role === "admin" && (
              <div className="my-5 p-4 rounded-xl sm:flex justify-between gap-3 w-full bg-white items-center">
                <div className="w-full mb-4 sm:mb-0">
                  <Link
                    to="/dashboard/notice"
                    className="btn text-white bg-purple-700 border-[5px] border-[#dfbced] hover:bg-purple-500 hover:border-purple-200 rounded-full w-full"
                  >
                    Notice Control Board
                  </Link>
                </div>
                <div className="w-full">
                  <Link
                    to="/dashboard/reword"
                    className="btn text-white bg-purple-700 border-[5px] border-[#dfbced] hover:bg-purple-500 hover:border-purple-200 rounded-full w-full"
                  >
                    Reword Control Board
                  </Link>
                </div>
              </div>
            )}

            {(me?.role === "teacher" || me?.role === "admin") && (
              <div className="w-full">
                <h1 className="text-purple-700 mb-3 font-bold text-xl">
                  All Attendance
                </h1>
                <div className="flex gap-x-3 items-center justify-between w-full">
                  <div className="w-full">
                    <select
                      onChange={(e) => setClass(e.target.value)}
                      className="select sm:select-md select-sm select-bordered !border-[#c9a2f9] px-1 select-[#c9a2f9] bg-[#faf9fd] rounded-md w-full"
                    >
                      <option value="Six" select>
                        Six
                      </option>
                      <option value="Play">Play</option>
                      <option value="Nursery">Nursery</option>
                      <option value="One">One</option>
                      <option value="Two">Two</option>
                      <option value="Three">Three</option>
                      <option value="Four">Four</option>
                      <option value="Five">Five</option>
                      <option value="SevenBoys">SevenBoys</option>
                      <option value="SevenGirls">SevenGirls</option>
                      <option value="EightBoys">EightBoys</option>
                      <option value="EightGirls">EightGirls</option>
                      <option value="NineBoys">NineBoys</option>
                      <option value="NineGirls">NineGirls</option>
                      <option value="TenBoys">TenBoys</option>
                      <option value="TenGirls">TenGirls</option>
                    </select>
                  </div>
                  <div className="w-full">
                    <input
                      type="date"
                      value={dates}
                      onChange={(e) => setDate(e.target.value)}
                      className="input sm:input-md input-sm input-bordered px-1 !border-[#c9a2f9] input-[#c9a2f9]  rounded-md w-full"
                      name="date"
                      id=""
                    />
                  </div>
                </div>
                {allAttendance?.length > 0 &&
                  (me?.role === "teacher" || me?.role === "admin") && (
                    <div className="mb-5 mt-3 max-w-full mx-auto p-4 bg-white rounded-2xl w-full">
                      {allAttendance?.map((i, index) => (
                        <AttendanceTable
                          key={index}
                          setLate={setLate}
                          atds={i}
                        />
                      ))}
                    </div>
                  )}
                {!(allAttendance?.length > 0) &&
                  (me?.role === "teacher" || me?.role === "admin") && (
                    <div className="mt-4">
                      <h1 className="text-center font-bold">No Attendance</h1>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>

        <div className=" w-full sm:w-[400px] px-2 sm:px-0  mx-auto mt-3 md:mt-0 ">
          <div className="hidden sm:block">
            <div className="border-[#eddeff] w-full bg-[#f3f9fb] mb-5 sm:mb-0 mx-auto sm:w-[350px] border-b border rounded-2xl">
              <AttendancePiker pre={pre} abs={abs} />
            </div>
          </div>
          <div className="w-full my-5">
            <LeardBoard
              setLeaderBoardPrint={setLeaderBoardPrint}
              setLeaderBoardInfo={setLeaderBoardInfo}
            />
          </div>
        </div>
      </div>
      {updateModal && (
        <UpdateModul
          updateModal={updateModal}
          refetch={meRefetch}
          setUpdateModal={setUpdateModal}
        />
      )}
      {updatePassModal && (
        <UpdatePass
          updatePassModal={updatePassModal}
          refetch={meRefetch}
          setUpdatePassModal={setUpdatePassModal}
        />
      )}
      {leaderBoardInfo && (
        <LeaderBoardInfo setLeaderBoardInfo={setLeaderBoardInfo} />
      )}
      {leaderBoardPrint && (
        <LeaderBoardPrint
          leaderBoardPrint={leaderBoardPrint}
          setLeaderBoardPrint={setLeaderBoardInfo}
        />
      )}
      {resultSheet && <ResultSheet resultSheet={resultSheet} />}
      {logout && <LogOutModule logout={logout} setLogout={setLogout} />}
      {attendanceToday && (
        <AttendanceToday
          attendanceToday={attendanceToday}
          setAttendanceToday={setAttendanceToday}
        />
      )}
      {late && (
        <LateAttendance
          setLate={setLate}
          late={late}
          refetch={atRefetch}
          classes={classes}
          id={ClassAttendance?._id}
        />
      )}
    </div>
  );
};

export default MyProfile;
