import React, { useContext } from 'react';
import { ExamContext } from '../../App';
import Rutin from '../../assets/images/NA-Rotin-2.jpg'
import useTimer from '../../Hooks/useTimer';
import Navbar from '../../Shared/Navbar';
import Footer from '../../Shared/Footer';


const About = () => {
    const [xms, xmLoading, xmRefetch, myXm, xmError] = useContext(ExamContext);
    const xmdate = xms?.find(i => i?.status?.includes("pending"))
    const [timeLefts] = useTimer(xmdate?.examDate)
    return (
        <div className='relative element-font-4'>
            <Navbar />
            <div className='max-w-7xl mx-auto px-2 md:px-8 lg:px-16'>
                <div className='w-full my-3 sm:my-5'>
                    <img src={Rutin} className="w-full rounded-xl shadow-lg" alt="Rutin" />
                </div>
                <div>
                {(!timeLefts?.dateOver || xmdate) &&  
                    <div className=" mb-4 mt-5 w-full">
                        <div className='max-w-[500px] mx-auto mb-5 bg-[#fffcff] p-3 rounded-xl'>
                            { (!timeLefts?.dateOver || xmdate) && <h1 className='text-center capitalize text-2xl mb-2 text-purple-700 font-bold'>{xmdate?.examName} Start After</h1>}
                            { !timeLefts?.dateOver && 
                            <div className="flex gap-3 max-w-[500px] mx-auto mt-5 items-center justify-center">
                                <div className="p-2 w-full text-center border border-purple-700 rounded-lg bg-[#f0e1f6]">
                                    <h1 className='text-2xl font-bold text-purple-700'>{timeLefts?.days}</h1>
                                    <span>Days</span>
                                </div>
                                <div className="p-2 w-full text-center border border-purple-700 rounded-lg bg-[#f0e1f6]">
                                    <h1 className='text-2xl font-bold text-purple-700'>{timeLefts?.hours}</h1>
                                    <span>Hours</span>
                                </div>
                                <div className="p-2 w-full text-center border border-purple-700 rounded-lg bg-[#f0e1f6]">
                                    <h1 className='text-2xl font-bold text-purple-700'>{timeLefts?.minutes}</h1>
                                    <span>Minutes</span>
                                </div>
                                <div className="p-2 w-full text-center border border-purple-700 rounded-lg bg-[#f0e1f6]">
                                    <h1 className='text-2xl font-bold text-purple-700'>{timeLefts?.seconds}</h1>
                                    <span>Seconds</span>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                }
                </div> 
            </div>
            <Footer />
        </div>
    );
};

export default About;