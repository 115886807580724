import React, { useContext, useEffect, useState } from "react";
import { ExamContext, userContext } from "../../App";
import axios from "axios";
import Loading from "../../Shared/Loading";
import { toast } from "react-toastify";
import { ProgressBar } from "react-loader-spinner";
import { totalMarks, totalMaxMarks } from "../../Utils/CalculateFunction";

const BulkSms = () => {
  const [users, userLoading] = useContext(userContext);
  const [xms] = useContext(ExamContext);
  const MyXm = xms?.find((i) => i?.examName === "First Semister");
  const [send, setSend] = useState("");
  const [smsType, setSmsType] = useState("Single");
  const [smsBalance, setSmsBalance] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [message, setMessage] = useState(
    "প্রিয় অভিভাবক, আগামীকাল স্কুল বন্ধ থাকবে। নবজাগরণ একাডেমি https://www.nabojagoron-academy.com/"
  );
  const [dataUser, setData] = useState(users);
  const [Class, setClass] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(
      "https://bulksmsbd.net/api/getBalanceApi?api_key=I6dXEMHybK6zQZzr2UJd"
    )
      .then((response) => response.json())
      .then((data) => {
        setSmsBalance(data);
        console.log(data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    setData(users);
  }, [users]);

  if (userLoading) {
    return <Loading />;
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = dataUser?.filter((user) =>
    user.firstName.toLowerCase().startsWith(searchTerm.toLowerCase())
  );
  const students = users?.filter((i) => i?.role?.includes("student"));

  const handleClass = (classData) => {
    setClass(classData);
    if (classData === "All") {
      return setData(users);
    } else if (
      classData === "teacher" ||
      classData === "garden" ||
      classData === "student"
    ) {
      const data = users?.filter((i) => i?.role?.includes(classData));
      return setData(data);
    } else {
      const studentValue = students?.filter((i) =>
        i?.class?.includes(classData)
      );
      const sorted = studentValue?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      setData(sorted);
    }
  };

  const userNumbers = () => {
    const numbers = [];
    filteredUsers?.map((i) => {
      const number = i?.phoneNumber2
        ? i?.phoneNumber2?.slice(1)
        : i?.phoneNumber?.slice(1);
      numbers.push(number);
    });
    const numberString = numbers.join(", ");
    return numberString;
  };

  // many sms message ===========++++
  const manySMS = [];
  const userNm = (Phone) => {
    const user = users?.find((i) => i?.phoneNumber?.includes(Phone));
    return user;
  };
  const userResult = (Phone) => {
    const result = MyXm?.Results?.find((i) => i?.studentPh?.includes(Phone));
    return result;
  };

  filteredUsers?.map((i) => {
    const sms = {
      to: i?.phoneNumber2
        ? i?.phoneNumber2?.slice(1)
        : i?.phoneNumber?.slice(1),
      message: `Dear ${userNm(i?.phoneNumber)?.firstName} ${
        userNm(i?.phoneNumber)?.lastName
      }, 1st Semester result is published. and you have scored an impressive total of ${totalMarks(
        userResult(i?.phoneNumber)
      )} out of ${totalMaxMarks(
        userNm(i?.phoneNumber)?.class
      )} marks. Regards: nabojagoron-academy.com`,
    };
    manySMS.push(sms);
  });

  // send Single Sms Configure +++++++
  const API_URL2 = "https://bulksmsbd.net/api/smsapimany";
  const API_URL1 = "https://bulksmsbd.net/api/smsapi";
  const apiKey = "I6dXEMHybK6zQZzr2UJd";
  const SENDER_ID = "8809617611038";
  const RECIPIENT_NUMBERS = userNumbers();

  const handaleSendSms = () => {
    setLoading(true);
    setError("");

    const data1 = {
      api_key: apiKey,
      senderid: SENDER_ID,
      number: RECIPIENT_NUMBERS,
      message: message,
    };

    const data2 = {
      api_key: apiKey,
      senderid: SENDER_ID,
      messages: manySMS,
    };

    const url = smsType === "Single" ? API_URL1 : API_URL2;
    const data = smsType === "Single" ? data1 : data2;

    console.log(data);
    axios
      .post(url, data)
      .then((response) => {
        if (response.data.response_code === 202) {
          toast.success("SMS sent successfully!");
          setError("");
          setSend("");
        } else {
          toast.error(response.data.error_message);
          setError(response.data.error_message);
        }
        console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to send SMS:", error);
        toast.error("some this is worng");
        setLoading(false);
      });
  };

  return (
    <div className="p-2 sm:p-0">
      <div className="w-full">
        <div className="text-center w-full flex items-center gap-3 justify-between p-3 py-4 shadow-md sm:mb-5 mb-3 rounded-md bg-white">
          <div className="">
            <input
              type="search"
              value={searchTerm}
              onChange={handleSearch}
              className="border rounded-3xl w-[150px] sm:w-auto ring-[#b06fff]-focus ring-[#eddeff] border-[#eddeff] ring-2 outline-0 p-1 px-3"
              placeholder="Search User"
              name=""
              id=""
            />
          </div>
          <div
            className={`flex bg-[#f3f9fb] max-w-[500px] border border-[#eddeff] rounded-3xl w-full`}
          >
            <div className="w-[100px] bg-[#eddeff] flex items-center justify-center rounded-r-none rounded-3xl">
              <span className="text-xl font-Oswald font-bold text-purple-700">
                {dataUser?.length}
              </span>
            </div>
            <select
              onChange={(e) => handleClass(e.target.value)}
              className="text-sm border px-3 py-1.5 rounded-l-none rounded-3xl w-full"
            >
              <option value="All" select>
                All
              </option>
              <option value="teacher">Teacher</option>
              <option value="student">Student</option>
              <option value="garden">Garden</option>
              <option value="Play">Play</option>
              <option value="Nursery">Nursery</option>
              <option value="One">One</option>
              <option value="Two">Two</option>
              <option value="Three">Three</option>
              <option value="Four">Four</option>
              <option value="Five">Five</option>
              <option value="Six">Six</option>
              <option value="Seven">Seven</option>
              <option value="Eight">Eight</option>
              <option value="Nine">Nine</option>
              <option value="Ten">Ten</option>
            </select>
          </div>
        </div>

        <div className="w-full p-3 shadow-md mb-3 rounded-md bg-white">
          <div className="w-full flex items-center gap-3 justify-between">
            <div className="flex gap-x-2 items-center">
              <p className="text-[14px]">SMS Balance</p>
              <p className="font-Oswald text-[16px] bg-purple-100 rounded-md px-1 text-purple-700">
                {smsBalance?.balance}
              </p>
            </div>
            <div className="flex gap-x-2 text-[12px] sm:text-[14px] font-bold justify-end duration-800 items-center">
              <p
                onClick={() => setSmsType("Single")}
                className={`${
                  smsType === "Single"
                    ? "bg-purple-700 text-white"
                    : "bg-purple-100 text-purple-700"
                } duration-500 cursor-pointer rounded-md px-2 py-1`}
              >
                Single SMS
              </p>
              <p
                onClick={() => setSmsType("Many")}
                className={`${
                  smsType === "Many"
                    ? "bg-purple-700 text-white"
                    : "bg-purple-100 text-purple-700"
                } duration-500 cursor-pointer rounded-md px-2 py-1`}
              >
                Many SMS
              </p>
            </div>
          </div>
          {smsType === "Many" && (
            <div className="mt-3 text-[12px]">
              <p>{manySMS[0]?.message}</p>
            </div>
          )}
        </div>

        <div className="mt-4 w-full bg-white rounded-2xl sm:pt-3  sm:p-6 p-3">
          <div className="flex py-2 items-center justify-between">
            <h1 className="text-2xl font-bold font-Oswald text-purple-700">
              Receiver {filteredUsers?.length} {Class}
            </h1>
            <div className="">
              <input
                type="text"
                value={send}
                placeholder="Type Send"
                onChange={(e) => setSend(e.target.value)}
                className="input input-sm input-bordered mx-auto w-[100px]"
              />
            </div>
          </div>
          <div className="max-h-[330px] overflow-y-scroll">
            <div className="max-h-[300px] px-2 smsUser-grid gap-y-2 text-justify my-5 rounded-md">
              {filteredUsers?.map((i, index) => (
                <div
                  key={index}
                  className="w-6 h-6 grid place-content-center overflow-hidden rounded-full bg-[#7e16a0] ring ring-[#d583f5] ring-offset-base-100 ring-offset-1"
                >
                  {!i?.imageURL && (
                    <h2 className="text-white text-[14px] element-font-5 font-bold">
                      {i?.firstName?.slice(0, 1)}
                      {i?.lastName?.slice(0, 1)}
                    </h2>
                  )}
                  {i?.imageURL && (
                    <img
                      src={i?.imageURL}
                      className="w-full rounded-full"
                      alt="pic"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="my-4 relative">
            <textarea
              name="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="textarea w-full h-[110px] textarea-bordered"
              placeholder="Enter Your Message"
            ></textarea>
            <p className="absolute -top-1 -right-1 text-[12px] text-purple-700 bg-purple-100 px-1 rounded-md">
              {message.length}
            </p>
          </div>
          {loading && (
            <div className="w-full flex items-center justify-center">
              <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor="#F4442E"
                barColor="#51E5FF"
              />
            </div>
          )}

          <div>
            <p className="text-sm text-accent mb-2 text-center">{error}</p>
            <button
              onClick={handaleSendSms}
              className="btn w-full font-bold text-xl btn-primary text-white"
              disabled={send !== "Send"}
            >
              send Sms
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkSms;
