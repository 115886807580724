export const allClassData = [
  {
    className: "Play",
  },
  {
    className: "Nursery",
  },
  {
    className: "One",
  },
  {
    className: "Two",
  },
  {
    className: "Three",
  },
  {
    className: "Four",
  },
  {
    className: "Five",
  },
  {
    className: "Six",
  },
  {
    className: "Seven-Boys",
  },
  {
    className: "Seven-Girls",
  },
  {
    className: "Eight-Boys",
  },
  {
    className: "Eight-Girls",
  },
  {
    className: "Nine-Boys",
  },
  {
    className: "Nine-Girls",
  },
  {
    className: "Ten-Boys",
  },
  {
    className: "Ten-Girls",
  },
];

export const ClassData = [
  {
    className: "Play",
    mark: 400,
  },
  {
    className: "Nursery",
    mark: 400,
  },
  {
    className: "One",
    mark: 400,
  },
  {
    className: "Two",
    mark: 400,
  },
  {
    className: "Three",
    mark: 360,
  },
  {
    className: "Four",
    mark: 360,
  },
  {
    className: "Five",
    mark: 360,
  },
  {
    className: "Six",
    mark: 420,
  },
  {
    className: "Seven",
    mark: 420,
  },
  {
    className: "Eight",
    mark: 650,
  },
  {
    className: "Nine",
    mark: 1150,
  },
  {
    className: "Ten",
    mark: 1150,
  },
];
