import React, { useContext, useState } from 'react';
import { ExamContext, MeContext } from '../../App';
import XmChart from '../../Components/AnimateButtons/XmChart';
import useTimer from '../../Hooks/useTimer';
import ResultSheet from '../Modul/ResultSheet';

const ShowResult = () => {
    const [me, meLoading, meRefetch, meError] = useContext(MeContext);
    const [xms, xmLoading, xmRefetch, myXm, xmError] = useContext(ExamContext);
    const xmdate = xms?.find(i => i?.status?.includes("pending"))
    const [timeLefts] = useTimer(xmdate?.examDate)
    const [resultSheet, setResultSheet] = useState(null)
    const Myxm = xms?.find(i => i?.status?.includes("processing"));
    return (
        <div className='px-2 sm:px-0 mt-2'>
            <div className='border-[#eddeff] w-full bg-[#f3f9fb] border-b border p-3 rounded-2xl'>
                <XmChart
                    user={me}
                    setResultSheet={setResultSheet}
                />
            </div>
            {(Myxm?.status === "processing") && <h1 className='text-center max-w-[500px] capitalize bg-white mx-auto mt-3 p-3 rounded-xl text-2xl mb-2 text-purple-700 font-bold'> {Myxm?.examName} is Processing</h1>}
                {(!timeLefts?.dateOver || xmdate) &&  <div className=" mb-4 mt-5 w-full">
                    <div className='max-w-[500px] mx-auto mb-5 bg-[#fffcff] p-3 rounded-xl'>
                        { (!timeLefts?.dateOver || xmdate) && <h1 className='text-center capitalize text-2xl mb-2 text-purple-700 font-bold'>{xmdate?.examName} Start After</h1>}
                        { !timeLefts?.dateOver && 
                        <div className="flex gap-3 max-w-[500px] mx-auto mt-5 items-center justify-center">
                            <div className="p-2 w-full text-center border border-purple-700 rounded-lg bg-[#f0e1f6]">
                                <h1 className='text-2xl font-bold text-purple-700'>{timeLefts?.days}</h1>
                                <span>Days</span>
                            </div>
                            <div className="p-2 w-full text-center border border-purple-700 rounded-lg bg-[#f0e1f6]">
                                <h1 className='text-2xl font-bold text-purple-700'>{timeLefts?.hours}</h1>
                                <span>Hours</span>
                            </div>
                            <div className="p-2 w-full text-center border border-purple-700 rounded-lg bg-[#f0e1f6]">
                                <h1 className='text-2xl font-bold text-purple-700'>{timeLefts?.minutes}</h1>
                                <span>Minutes</span>
                            </div>
                            <div className="p-2 w-full text-center border border-purple-700 rounded-lg bg-[#f0e1f6]">
                                <h1 className='text-2xl font-bold text-purple-700'>{timeLefts?.seconds}</h1>
                                <span>Seconds</span>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                }
                {
                    resultSheet && <ResultSheet 
                    resultSheet={resultSheet}
                    />
                }
        </div>
    );
};

export default ShowResult;