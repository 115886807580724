import React, { useContext, useEffect, useState } from 'react';
import teacherImg from "../../assets/icons/teacher.png"
import { userContext } from '../../App';

const AllTeachersCard = ({teacherInfo}) => {
    const [users] = useContext(userContext);
    const teachers = users?.filter(i => i?.role?.includes("teacher"));
    
    return (
        <div>
            <input type="checkbox" id="teacher-info" className="modal-toggle" />
            <div className="modal h-screen modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-100 h-auto w-full sm:!w-11/12 !max-w-3xl px-3 pt-2 pb-4">
                    <label htmlFor="teacher-info" className="btn z-50 btn-sm btn-circle absolute right-2 top-2">✕</label>  
                    
                    <p className='my-5 text-center font-bold text-[20px] sm:text-xl font-all'>আমাদের একাডেমির শিক্ষক/শিক্ষিকাদের পরিচিতি</p>
                    <div className='teacher-grid'>
                        {
                            teachers?.map((i, index) => (
                                <div key={index} className='overflow-hidden border-[#e9ecef] bg-[#f8fafc] relative rounded-md'>
                                    {!i?.imageURL && <img src={teacherImg} className='w-full hover:scale-[1.1] duration-500 h-full' alt="" />}
                                    {i?.imageURL && <img src={i?.imageURL} className='w-full hover:scale-[1.1] duration-500 h-full' alt="" />}
                                    <div className='w-full px-2 h-full flex items-end text-white shadow-inner absolute bottom-0 left-0'>
                                        <div className='text-center w-full mb-1'>
                                            <h3 className='text-white text-[12px] element-font-4 font-bold sm:text-[14px]'>{i?.firstName} {i?.lastName}</h3>
                                            <h6 className='text-white font-normal text-[8px] sm:text-[10px]'>{i?.title}</h6>
                                            <h6 className='text-white font-normal text-[10px] sm:text-[11px]'>{i?.designation}</h6>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllTeachersCard;