import { format } from 'date-fns';
import React, { useContext } from 'react';
import { userContext } from '../App';

const AttendanceTable = ({atds, setLate}) => {
    const [users, userLoading, userRefetch, userError] = useContext(userContext);

    const claculateTime = (t1, t2) => {
        const time1 = new Date(t1).getTime();
        const time2 = new Date(t2).getTime();
        const distance = time2 - time1;
        const minutes = Math.floor(distance / (1000 * 60));
        return minutes
    }

    const userNm = (Phone) => {
        const user = users?.find(i => i?.phoneNumber?.includes(Phone));
        return user
    }

    return (
        <div className='flex justify-betweens items-center gap-1 py-1 border-b border-[#ddd]'>
            <div className='flex items-center w-full'>
                <h3 className={`text-[14px] font-bold`}>{atds?.roleNo}</h3>
                <h3 className='text-[14px] font-bold ml-5'>{userNm(atds?.phoneNumber)?.firstName} {userNm(atds?.phoneNumber)?.lastName}</h3>
            </div>
            <div className='flex gap-x-2 items-center'>
                { ((atds?.status === 'A') && (new Date(atds.date).toLocaleDateString() === new Date().toLocaleDateString())) &&
                    <label onClick={() => setLate(atds)} htmlFor='late-attendance' className='btn border-0 text-purple-700 hover:text-white bg-purple-100 hover:bg-purple-700 btn-xs'>Late</label>
                }
                { atds?.late &&
                    <div className='flex text-purple-700 items-center gap-x-1'> 
                     <p>{claculateTime(atds?.date, atds?.late)}</p><span>m</span>
                    </div>
                }
                {!atds?.late && <h3 className={`${(atds?.status === 'P')? "text-primary" : "text-red-600" } text-center text-[16px] font-bold`}>{atds?.status}</h3>}
                {atds?.late && <h3 className={` text-purple-700 text-center text-[16px] font-bold`}>L</h3>}
            </div>
        </div>
    );
};




export default AttendanceTable;