import React, { useEffect, useState } from "react";
import { SiGmail } from "react-icons/si";
import { FaPhone } from "react-icons/fa";
import { FiFacebook, FiLinkedin, FiTwitter } from "react-icons/fi";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import bg1 from "../../assets/images/banks.jpg";
import { MdLocationPin } from "react-icons/md";
import WhatsAppLink from "../../Utils/WhatsAppLink";

const ContactUs = () => {
  const [map, setMap] = useState([]);

  useEffect(() => {
    fetch("GoogleMap.json")
      .then((res) => res.json())
      .then((data) => setMap(data));
  }, []);
  return (
    <div className="my-10  ox-hidden">
      <div className="text-center my-12">
        <h1 className="text-4xl md:text-6xl font-all font-bold">
          <span className="text-purple-700">যোগাযোগ</span> করুন
        </h1>
        <p className="font-bold text-sm sm:text-[18px] font-all mt-2">
          স্কুল সম্পর্কে বিস্তারিত জানতে যোগাযোগ করুন
        </p>
      </div>

      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
        <div
          style={{ backgroundImage: `url(${bg1})` }}
          className="bg-cover h-[280px] relative rounded-xl overflow-hidden"
          data-aos="zoom-in-right"
          data-aos-delay="100"
          data-aos-duration="800"
        >
          <div className="absolute top-0 text-white left-0 w-full h-full border bg-gradient-to-t from-[#52cce7d9] to-[#5270e7d3] border-[#c4f4fdd2]  shadow-box p-2 md:p-4">
            <h1 className="text-xl font-all font-bold">
              নিচের তথ্যগুলো দিয়ে যোগাযোগ করুন
            </h1>
            <div className="">
              <p className="text-sm font-all">
                নবজাগরণ একাডেমি সর্বদাই আপনাদেরকে সাপোর্ট দেওয়ার জন্য প্রস্তুত
                থাকেন
              </p>
              <div className="">
                <div className="cursor-pointer my-2 flex items-center">
                  <FaPhone />{" "}
                  <a
                    className="font-bold ml-2 rounded-md px-1 bg-[#cefffe84] text-purple-700 block"
                    href="tel:+8801737774409"
                  >
                    +8801737774409
                  </a>
                </div>
              </div>
              <div className="">
                <div className="cursor-pointer my-2 flex items-center">
                  <FaPhone />{" "}
                  <a
                    className="font-bold ml-2 rounded-md px-1 bg-[#cefffe97] text-purple-700 block"
                    href="tel:+880937464639"
                  >
                    +880937464639
                  </a>
                </div>
              </div>
              <div className="">
                <div className="cursor-pointer my-2 flex items-center">
                  <SiGmail />{" "}
                  <a
                    className="font-bold ml-2 rounded-md px-1 bg-[#cefffe85] text-purple-700"
                    href="mailto:nabojagoronacademy@gmail.com"
                  >
                    nabojagoronacademy@gmail.com
                  </a>
                </div>
              </div>
              <div className="">
                <div className="cursor-pointer text-white my-2 font-all flex items-center">
                  <MdLocationPin size={20} />{" "}
                  <a
                    className="font-bold ml-2 text-white"
                    href="mailto:rohulamin@gmail.com"
                  >
                    নন্নী বাজার, নালিতাবাড়ী, শেরপুর।
                  </a>
                </div>
              </div>
            </div>
            <div className="">
              <div className="flex items-center gap-x-2 mt-2">
                <div className="p-2 bg-[#74c9fa] border border-[#8ed4fd] text-white rounded-md">
                  <a className="block" href={`tel:+8801937774409`}>
                    <span className="font-bold">
                      <AiOutlinePhone size={20} />
                    </span>
                  </a>
                </div>
                <div className="p-2 bg-[#74c9fa] border border-[#8ed4fd] text-white rounded-md">
                  <a
                    className="block"
                    href={`mailto:nabojagoronacademy@gmail.com`}
                  >
                    <span className="font-bold">
                      <AiOutlineMail size={20} />
                    </span>
                  </a>
                </div>
                <div className="p-2 bg-[#6fa8fd] border border-[#8ed4fd] text-white rounded-md">
                  <WhatsAppLink
                    phoneNumber="+8801937774409"
                    size={20}
                    message="Hello, how are you?"
                  />
                </div>
                <div className="p-2 bg-[#6ac0fa] border border-[#8ed4fd] text-white rounded-md">
                  <a
                    className="block"
                    href="https://www.facebook.com/profile.php?id=100013083253930"
                  >
                    <span className="font-bold">
                      <FiFacebook size={20} />
                    </span>
                  </a>
                </div>
                <div className="p-2 bg-[#6fadfd] border border-[#8ed4fd] text-white rounded-md">
                  <a
                    className="block"
                    href="https://www.linkedin.com/in/rohulaminwd/"
                  >
                    <span className="font-bold">
                      <FiLinkedin size={20} />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="border rounded-xl"
          data-aos="zoom-in-left"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          {map.map((i, index) => (
            <div key={index} className="w-full h-full">
              <iframe
                className="w-full h-[280px] sm:h-full rounded-xl"
                allowFullScreen=""
                loading="lazy"
                title="Googl map"
                referrerPolicy="no-referrer-when-downgrade"
                src={i.map}
              ></iframe>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
