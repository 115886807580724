import { format } from 'date-fns';
import React, { useContext, useState } from 'react';
import { MeContext, NoticeContext } from '../../App';
import megaphone from "../../assets/icons/megaphone.png"
import noticeBoard from "../../assets/icons/noticeboard.png"

const Announcement = () => {
    const [me, meLoading, meRefetch, meError] = useContext(MeContext);
    const [notices, noticeLoading, noticeRefetch ] = useContext(NoticeContext);
    const noticeRunning = notices?.filter(i => i?.status?.includes('running'));

    const notice = [];

    noticeRunning?.reverse()?.map((i, index) => {
        if(me?.class === i?.noticeClass){
            notice.push(i);
        }
        else if(me?.role === i?.noticeClass){
            notice.push(i);
        }
        else if(i?.noticeClass === "All"){
            notice.push(i);
        }
    })

    console.log(notice, "kisu nai ki")



    return (
        <div className='px-2 sm:px-0 mt-2'>
            <div className='grid grid-clos-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'>
                {
                    notice?.map((i, index) => <>
                        <div 
                        key={index}
                        className="p-3 md:mt-0 w-full relative overflow-hidden cursor-pointer  bg-white  duration-300 shadow-md rounded-xl"
                        >
                            <div className='py-1.5 pb-0.5 gap-2'>
                                <div className='w-full flex gap-2'>
                                    <div className='w-10'>
                                    <img src={noticeBoard} className='w-10' alt="pending " />
                                    </div>
                                    <div className='w-full'>
                                        <h3 className='text-[16px] capitalize element-font-5 font-bold'>{i?.noticeTitle}</h3>
                                        <p className='text-[12px] element-font-5'>{i?.description}</p>
                                    </div>
                                </div>
                                {/* <div className='flex items-center mt-2 justify-between'>
                                    <p className='text-[12px]'>{format(new Date(i?.createdAt), 'PP')}</p>
                                    <h5 className='text-right capitalize text-[#2c536e] font-bold'>{i?.status}...</h5>
                                </div>
                                <div className={`${(i?.status === "pending")? "bg-[#dedede] text-primary" : ""}
                                ${(i?.status === "complete")? "bg-[#8df3f9] text-secondary" : ""}
                                absolute -top-1.5 grid place-content-center -right-1.5 w-[68px] h-7 rounded-full bg-secondary text-white`}>
                                    <p className='text-sm text-purple-700 mt-1'>{i?.noticeClass}</p>
                                </div>    */}
                            </div>
                        </div>
                    </>)
                }
            </div>
            { (notice?.length === 0) &&
                <div>
                    <h1 className='text-3xl font-bold text-purple-700 text-center mt-1'>Announcement Page</h1>
                    <div className='w-[180px] mt-5 mx-auto'>
                        <img src={megaphone} className="w-full" alt="" />
                    </div>
                </div>
            }
        </div>
    );
};

export default Announcement;