import React, { useState } from "react";
import logo from "../../assets/images/log03.png";
import pen from "../../assets/images/pen.png";
import {
  MiddleSub,
  jscSub,
  primarySub,
  secondarySub,
  sscSub,
} from "../../data/subjectData";
import { normalFeedback } from "../../data/feedback";
import { useEffect } from "react";
import {
  calculateGreat,
  calculateMyPosition,
  handleGreat,
  maxMark,
  totalMarks,
} from "../../Utils/CalculateFunction";

const XmReport = ({ me, xm, MyXm, GenderXm, present, myAttendance }) => {
  const [xmSub, setXmSub] = useState([...sscSub]);
  const myResult = MyXm?.Results?.find((i) => i?.studentPh === me?.phoneNumber);

  // Subject select
  useEffect(() => {
    if (
      me?.class === "Play" ||
      me?.class === "Nursery" ||
      me?.class === "One" ||
      me?.class === "Two"
    ) {
      setXmSub(primarySub);
    } else if (
      me?.class === "Three" ||
      me?.class === "Four" ||
      me?.class === "Five"
    ) {
      setXmSub(secondarySub);
    } else if (me?.class === "Six" || me?.class === "Seven") {
      setXmSub(MiddleSub);
    } else if (me?.class === "Eight") {
      setXmSub(jscSub);
    } else if (me?.class === "Ten" || me?.class === "Nine") {
      setXmSub([...sscSub]);
    }
  }, [me]);

  // Find the subject marks

  const marks = (sub) => {
    const sumMark = myResult?.subResult?.find((i) => i?.subject === sub);
    return sumMark;
  };

  // find the result subject agr and higher math
  if (me?.class === "Ten" || me?.class === "Nine") {
    const Arg = myResult?.subResult?.find((i) => i?.subject === "Agricultural");
    const Hrm = myResult?.subResult?.find((i) => i?.subject === "Higher Math");
    if (Arg?.subject === "Agricultural") {
      const myI = xmSub?.find((i) => i?.name === "Higher Math");
      const myIndex = xmSub.indexOf(myI);
      if (myIndex > 0) {
        xmSub?.splice(myIndex, 1);
      }
    } else if (Hrm?.subject === "Higher Math") {
      const myI = xmSub?.find((i) => i?.name === "Agricultural");
      const myIndex = xmSub.indexOf(myI);
      if (myIndex > 0) {
        xmSub?.splice(myIndex, 1);
      }
    }
  }

  const year = new Date().getFullYear()?.toString().slice(2);
  const studentId = me?.phoneNumber?.slice(10) + year + me?.roleNo;

  const STR = (i) => {
    const m = i?.mcq ? i?.mcq : 0;
    const w = i?.writing ? i?.writing : 0;
    const p = i?.pecticNun ? i?.pecticNun : 0;
    const mark = parseFloat(m) + parseFloat(w) + parseFloat(p);
    return mark;
  };

  const result = myResult?.subResult?.map((i) => {
    const SubMark = {
      mark: STR(i),
      subMa: i,
    };
    return SubMark;
  });

  function calculateGPA(marks) {
    const gradePoints = marks?.map((x) => {
      const crnSub = xmSub?.find((i) => i?.name === x?.subMa?.subject);
      if (crnSub?.name === "Higher Math" || crnSub?.name === "Agricultural") {
        const optionalSub =
          x?.mark >= 80
            ? 3
            : x?.mark >= 70
            ? 2
            : x?.mark >= 60
            ? 1.5
            : x?.mark >= 50
            ? 1
            : 0;
        return optionalSub;
      } else if (x?.mark >= Math.ceil((crnSub?.maxNum / 100) * 80)) {
        return 5;
      } else if (x?.mark >= Math.ceil((crnSub?.maxNum / 100) * 70)) {
        return 4;
      } else if (x?.mark >= Math.ceil((crnSub?.maxNum / 100) * 60)) {
        return 3.5;
      } else if (x?.mark >= Math.ceil((crnSub?.maxNum / 100) * 50)) {
        return 3;
      } else if (x?.mark >= Math.ceil((crnSub?.maxNum / 100) * 40)) {
        return 2;
      } else if (x?.mark >= Math.ceil((crnSub?.maxNum / 100) * 33)) {
        return 1;
      } else {
        return 0;
      }
    });

    const great = (gpa) => {
      if (gpa >= 5) {
        return "A+";
      } else if (gpa >= 4) {
        return "A";
      } else if (gpa >= 3.5) {
        return "A-";
      } else if (gpa >= 3) {
        return "B";
      } else if (gpa >= 2) {
        return "C";
      } else if (gpa >= 1) {
        return "D";
      } else {
        return "F";
      }
    };

    const totalGradePoints = gradePoints?.reduce((acc, curr) => acc + curr, 0);
    const subLength = xmSub?.length > 11 ? 11 : xmSub?.length;
    const gpa = totalGradePoints / subLength;
    return [gpa.toFixed(2), great(gpa)];
  }

  const grtGPA = calculateGPA(result);

  const { myGreats, status, rejected } = calculateGreat(myResult, xmSub);
  const { myIndex, Totalresults } = calculateMyPosition(
    GenderXm,
    me?.phoneNumber
  );

  return (
    <div>
      <div className="">
        <div className="">
          <div>
            <div className="font-reem w-full relative">
              <div className="flex gap-x-3 items-center">
                <div className="">
                  <img src={logo} className="w-[110px] mx-auto" alt="logo" />
                </div>
                <div>
                  <h1 className="text-4xl text-purple-700 font-bold">
                    Nabojagoron Academy
                  </h1>
                  <h3 className="text-[24px] text-purple-600 mt-2">
                    Nanni Bazar, Nalitabari, Sherpur.
                  </h3>
                </div>
              </div>
              <div className="w-full absolute -top-2 left-0 h-full flex gap-x-4 items-center justify-end">
                <div className="w-20 h-24 mt-2 mr-1 bg-purple-700 ring ring-[#c99df5]">
                  {me?.imageURL && (
                    <img
                      src={me?.imageURL}
                      className="w-full h-full mx-auto"
                      alt="logo"
                    />
                  )}
                  {!me?.imageURL && (
                    <h2 className="text-4xl pt-4 font-bold text-white uppercase">
                      {me?.firstName?.slice(0, 1)}
                      {me?.lastName?.slice(0, 1)}
                    </h2>
                  )}
                </div>
              </div>
            </div>

            <div className="w-full mb-4 mt-2 flex items-center">
              <div className="h-[2px] w-[50%] bg-[#da9ffb]"></div>
              <div className="font-reem w-full text-center text-purple-700 bg-[#f1e5fb] !border-[#da9ffb] font-bold px-2 py-0.5 uppercase text-[24px] rounded-[20px] border-[2px]">
                Academic Reports - {new Date().getFullYear()}
              </div>
              <div className="h-[2px] w-[50%] bg-[#da9ffb]"></div>
            </div>

            <div className="w-full">
              <table className="w-full font-bold">
                <thead>
                  <tr className="text-center font-reem text-xl border !border-[#da9ffb] text-purple-700">
                    <th className="!border-[#da9ffb] border bg-[#f4f1f8]">
                      E-Name
                    </th>
                    <th className="!border-[#da9ffb] border">{xm}</th>
                    <th className="!border-[#da9ffb] border bg-[#f4f1f8]">
                      Class
                    </th>
                    <th className="!border-[#da9ffb] border">{me?.class}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="rounded-b-xl font-reem border text-lg !border-[#da9ffb] text-purple-700">
                    <td className="border text-center !border-[#da9ffb] bg-[#f4f1f8]">
                      S-Name
                    </td>
                    <td className="border text-center !border-[#da9ffb]">
                      {me?.firstName} {me?.lastName}
                    </td>
                    <td className="border text-center !border-[#da9ffb] bg-[#f4f1f8]">
                      Roll NO
                    </td>
                    <td className="border text-center !border-[#da9ffb]">
                      {me?.roleNo}
                    </td>
                  </tr>
                  <tr className="rounded-b-xl font-reem border text-lg !border-[#da9ffb] text-purple-700">
                    <td className="border text-center !border-[#da9ffb] bg-[#f4f1f8]">
                      Address
                    </td>
                    <td className="border text-center !border-[#da9ffb]">
                      {me?.address}
                    </td>
                    <td className="border text-center !border-[#da9ffb] bg-[#f4f1f8]">
                      S.ID
                    </td>
                    <td className="border text-center !border-[#da9ffb]">
                      {studentId}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="w-full mt-4 result-table">
              <table className="w-full">
                <thead className="rounded-t-xl">
                  <tr className="uppercase font-reem text-xl bg-purple-700 rounded-t-xl text-white">
                    <th>Subject</th>
                    <th>Writing</th>
                    <th>MCQ</th>
                    <th>Pectic</th>
                    <th>Total</th>
                    <th>Great</th>
                  </tr>
                </thead>
                <tbody>
                  {xmSub?.map((i, index) => (
                    <tr
                      key={index}
                      className={`${
                        index % 2 !== 0 ? "bg-[#f4f1f8] text-[#602c91]" : ""
                      } font-reem text-lg`}
                    >
                      <td className="border text-center !border-[#da9ffb]">
                        {i?.name === "Islam" ? "Religion" : i?.name}
                      </td>
                      <td className="border text-center !border-[#da9ffb]">
                        {marks(i?.name)?.writing
                          ? marks(i?.name)?.writing
                          : "="}
                      </td>
                      <td className="border text-center !border-[#da9ffb]">
                        {marks(i?.name)?.mcq ? marks(i?.name)?.mcq : "="}
                      </td>
                      <td className="border text-center !border-[#da9ffb]">
                        {marks(i?.name)?.pecticNun
                          ? marks(i?.name)?.pecticNun
                          : "="}
                      </td>
                      <td className="border text-center !border-[#da9ffb]">
                        {i?.maxNum > 100 ? 100 : i?.maxNum} /{" "}
                        {STR(marks(i?.name))}
                      </td>
                      <td className="border text-center !border-[#da9ffb]">
                        {handleGreat(
                          STR(marks(i?.name)),
                          xmSub,
                          i?.name,
                          marks(i?.name)
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr className="bg-[#f1e5fb] rounded-b-xl font-reem border text-lg !border-[#da9ffb] text-purple-700 font-bold">
                    <td className="border text-center !border-[#da9ffb]">
                      Rejected: {rejected}
                    </td>
                    <td className="border text-center !border-[#da9ffb]">=</td>
                    <td className="border text-center !border-[#da9ffb]">=</td>
                    <td className="border text-center !border-[#da9ffb]">=</td>
                    <td className="border text-center !border-[#da9ffb]">
                      {maxMark(me?.class)} / {totalMarks(myResult)}
                    </td>
                    <td className="border text-center !border-[#da9ffb]">
                      {status === "FAIL" ? "F" : grtGPA[1]}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="w-full my-5">
                <thead>
                  <tr className="text-xl text-center font-reem bg-purple-700 text-white">
                    <th className="p-1 px-2">MARKS</th>
                    <th className="p-1 px-2">GPA</th>
                    <th className="p-1 px-2">GREAT</th>
                    <th className="p-1 px-2">STATUS</th>
                    <th className="p-1 px-2">S-NO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-[#f1e5fb] text-center border font-reem text-lg font-bold !border-[#da9ffb] text-purple-700">
                    <td className="border px-2 !border-[#da9ffb]">
                      {maxMark(me?.class)} / {totalMarks(myResult)}
                    </td>
                    <td className="border px-2 !border-[#da9ffb]">
                      {grtGPA[0]}
                    </td>
                    <td className="border px-2 !border-[#da9ffb]">
                      {status === "FAIL" ? "F" : grtGPA[1]}
                    </td>
                    <td className="border px-2 !border-[#da9ffb]">{status}</td>
                    <td className="border px-2 !border-[#da9ffb]">
                      {myIndex + 1}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <div className="w-full mt-3 mb-1 flex items-center">
                <div className="font-reem w-[240px] text-purple-700 bg-[#f1e5fb] !border-[#da9ffb] font-bold px-3 py-0.5 text-[20px] rounded-[20px] border-[2px]">
                  Exam Feedback
                </div>
                <div className="h-[2px] w-full bg-[#da9ffb]"></div>
              </div>
              <div>
                <p className="font-reem leading-1 text-[18px] text-purple-700">
                  {normalFeedback}
                </p>
              </div>
            </div>

            <div className="w-full text-purple-800 flex mt-4 justify-between">
              <div className="text-center font-reem text-sm w-[180px]">
                <h3 className="border-b pb-1 font-bold border-[#8b8b8b]">
                  <img
                    src="https://fontmeme.com/permalink/230520/ae1df4b56eda0344305a0437b6fe4a89.png"
                    alt="signature-fonts"
                    className="w-[150px] text-center -mb-1 mx-auto"
                  />
                </h3>
                <h3 className="font-bold pt-1">( Exam Controller )</h3>
              </div>
              <div className="w-18 -mt-2 h-14">
                <img src={pen} alt="pne" className="w-18 h-14" />
              </div>
              <div className="text-center font-reem text-sm w-[130px]">
                <h3 className="border-b pb-1 text-center font-bold border-[#8b8b8b]">
                  <img
                    src="https://fontmeme.com/permalink/230520/e1e16894902aeb97b29bf4b97d6f5ae8.png"
                    alt="signature-fonts"
                    className="w-[100px] -mb-2 text-center mx-auto"
                  />
                </h3>
                <h3 className="font-bold pt-1">( Head Teacher )</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default XmReport;
