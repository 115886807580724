import React from 'react';
import { useQuery } from 'react-query';
import { useState } from 'react';
import "./dashboard.css"
import { AnimatePresence, motion } from "framer-motion"
import { AiOutlineDelete } from 'react-icons/ai';
import axios from '../../Utils/Axios.config';
import Loading from '../../Shared/Loading';
import { BiEdit } from 'react-icons/bi';
import DeleteModalConfirm from '../Modul/DeleteModalConfirm';
import teacherImg from "../../assets/icons/teacher.png"
import CreateReword from '../Modul/CreateReword';

const RewordBoard = () => {

    const [openCreateReword, setOpenCreateReword] = useState(null);
    const [deletingModal, setDeletingModal] = useState(null)
    const method = "reword"

    const {data, isLoading, refetch, error, } = useQuery('reword', () =>
    axios.get('/reword').then((res) => res.data));


    if(isLoading){
        return <Loading />
    }


  

 

    return (
        <div className='w-full sm:p-0 p-2'>
            <div className='text-center w-full flex items-center justify-between p-3 py-4 shadow-md sm:mb-5 mb-3 rounded-md bg-white'>
                <div className=''>
                    <label onClick={() => setOpenCreateReword([null, "create"])} htmlFor='create-reword' className='btn btn-primary rounded-3xl btn-sm text-white'>Create Notice</label>
                </div>
                <h1 className='font-bold text-xl'><span className='text-accent '>{data?.length}</span></h1>
            </div>
 
                <motion.div layout className='md:mt-5 reword-student-grid mt-3'>
                <AnimatePresence>
                    {
                    data?.map((i, index) => <>
                        <motion.div
                        layout 
                        key={i?._id}
                        animate={{ opacity: 1, scale: 1 }}
                        initial={{ opacity: 0, scale: 0 }}
                        exit={{ opacity: 0, scale: 0 }}
                        className=' overflow-hidden border-[#e9ecef] bg-[#f8fafc] relative rounded-xl' >
                            <div>
                                {!i?.imgUrl && <img src={teacherImg} className='w-full hover:scale-[1.1] duration-500 h-full' alt="" />}
                                {i?.imgUrl && <img src={i?.imgUrl} className='w-full hover:scale-[1.1] duration-500 !h-full' alt="" />}
                                <div className='w-full px-2 h-full flex items-end text-white shadow-GPA-5 absolute bottom-0 left-0'>
                                    <div className='text-center w-full '>
                                        <h3 className='text-white element-font-5 text-[12px] sm:text-[14px]'>{i?.name}</h3>
                                        <h6 className='text-white font-normal element-font-5 text-[11px] sm:text-[13px]'>{i?.gpa}</h6>
                                    </div>
                                    <div className='flex absolute top-2 w-full px-2 left-0 justify-between items-center'>
                                        <label  onClick={() => setOpenCreateReword([i, "update"])} htmlFor='create-reword' className="text-primary cursor-pointer"><BiEdit size={20} /></label>
                                        <label onClick={() => setDeletingModal(i)} htmlFor="delete-confirm-modal" className="text-accent ml-2 cursor-pointer"><AiOutlineDelete size={20} /></label>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </>)
                    }
                </AnimatePresence>    
                </motion.div> 
                {
                openCreateReword && <CreateReword
                openCreateReword={openCreateReword}
                refetch={refetch} 
                setOpenCreateReword={setOpenCreateReword}
                />
                }
                {
                deletingModal && <DeleteModalConfirm 
                deletingModal={deletingModal}  
                setDeletingModal={setDeletingModal}
                refetch={refetch}
                method={method}
                />
            }
        </div>
    );
};

export default RewordBoard;