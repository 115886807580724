
import React from 'react';
import PrintDownload from '../../Utils/PrintDownload';

const ResultCard = () => {
    return (
        <div className=''> 
            <div>
                <PrintDownload />
            </div>
        </div>
    );
};

export default ResultCard;