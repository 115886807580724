import React, { useState } from "react";
import axios from "../../Utils/Axios.config";
import { toast } from "react-toastify";
import attendance from "../../assets/icons/attendance2.png";
import { ProgressBar } from "react-loader-spinner";
import { useContext } from "react";
import { userContext } from "../../App";

const ConfirmAttendance = ({
  setConfirmAttendance,
  id,
  atRefetch,
  confirmAttendance,
  ClassAttendance,
  date,
}) => {
  const [users, userLoading, userRefetch, userError] = useContext(userContext);
  const [disable, setDisable] = useState(false);

  const abs = confirmAttendance?.newArr?.filter((i) => i.status?.includes("A"));
  const pre = confirmAttendance?.newArr?.filter((i) => i.status?.includes("P"));

  // send Absent Sms
  const API_URL = "https://bulksmsbd.net/api/smsapimany";
  const apiKey = "I6dXEMHybK6zQZzr2UJd";
  const SENDER_ID = "8809617611038";

  const message = [];
  const userNm = (Phone) => {
    const user = users?.find((i) => i?.phoneNumber?.includes(Phone));
    return user;
  };

  abs?.map((i) => {
    const sms = {
      to: i?.phoneNumber2
        ? i?.phoneNumber2?.slice(1)
        : i?.phoneNumber?.slice(1),
      message: `${userNm(i?.phoneNumber)?.firstName} ${
        userNm(i?.phoneNumber)?.lastName
      } আজকে স্কুলে অনুপস্থিত, নবজাগরণ একাডেমি।`,
    };
    message.push(sms);
  });

  const handleAttendance = () => {
    setDisable(true);

    const data = {
      api_key: apiKey,
      senderid: SENDER_ID,
      messages: message,
    };

    // console.log(data, "good so")

    axios
      .patch(`/attendance/${id}`, confirmAttendance)
      .then((response) => {
        if (abs?.length > 0) {
          axios
            .post(API_URL, data)
            .then((response) => {
              if (response.data.response_code === 202) {
                toast.success("Attendance successful");
                atRefetch();
                setConfirmAttendance(null);
              } else {
                toast.warn("Attendance successful but Absent Sms not send");
                atRefetch();
                setConfirmAttendance(null);
              }
              console.log(response.data);
            })
            .catch((error) => {
              toast.warn("Attendance successful but Absent Sms not send");
              atRefetch();
              setConfirmAttendance(null);
            });
        } else {
          toast.success("Attendance successful");
          atRefetch();
          setConfirmAttendance(null);
        }
      })
      .catch((error) => {
        toast.error("Ops No..!! Something is wrong");
        setDisable(false);
        console.log(error, "no");
      });
  };
  return (
    <div>
      <input type="checkbox" id="confirm-attendance" className="modal-toggle" />
      <div className="modal h-screen modal-bottom sm:modal-middle">
        <div className="modal-box pb-8 mb-10 bg-blue-100 h-auto px-2 py-4 sm:py-8 sm:px-4">
          <div className="text-xl text-center">
            <img src={attendance} className="w-32 mx-auto" alt="complete" />
            {new Date(ClassAttendance?.updatedAt).toLocaleDateString() ===
              date.toLocaleDateString() && (
              <h2 className="text-accent">Out of Attendance Time</h2>
            )}
            {new Date(ClassAttendance?.updatedAt).toLocaleDateString() !==
              date.toLocaleDateString() && (
              <h2 className="">
                Are Your sure..? you want to Create attendance class
                <span className="text-purple-700 font-bold">
                  {" "}
                  {confirmAttendance?.class}
                </span>
              </h2>
            )}
            <div className="text-xl flex items-center justify-center gap-x-3 mt-3 font-bold">
              <p className="text-green-700 px-2 py-0.5 rounded-md bg-[#c8fde9]">
                Present: {pre?.length}{" "}
              </p>
              <p className="text-accent px-2 py-0.5 rounded-md bg-[#faccd7]">
                Absent: {abs?.length}
              </p>
            </div>
          </div>
          <div className="p-4 mt-5 shadow-md rounded-md bg-white">
            {disable && (
              <div className="w-full flex items-center justify-center">
                <ProgressBar
                  height="80"
                  width="80"
                  ariaLabel="progress-bar-loading"
                  wrapperStyle={{}}
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#F4442E"
                  barColor="#51E5FF"
                />
              </div>
            )}
            <div className="flex items-center justify-center gap-x-3">
              <button
                onClick={handleAttendance}
                disabled={disable || confirmAttendance?.newArr?.length <= 0}
                className="btn w-[100px] btn-primary text-white btn-sm"
              >
                Submit
              </button>
              <label
                htmlFor="confirm-attendance"
                className="btn btn-sm w-[100px]"
              >
                Cancel
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAttendance;

// disabled={ ((new Date(ClassAttendance?.updatedAt).toLocaleDateString() === date.toLocaleDateString()) || disable || (confirmAttendance?.newArr?.length <= 0) )}
