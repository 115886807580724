import React, { useContext } from "react";
import LBoardPrintD from "../../Components/AnimateButtons/LBoardPrintD";
import { useRef } from "react";
import ReactToPrint from "react-to-print";

const LBPrintBtn = ({ setLeaderBoardPrint, leaderBoardPrint }) => {
  const componentRef = useRef();

  return (
    <div>
      <input type="checkbox" id="LeaderBoard-Print" className="modal-toggle" />
      <div className="modal h-screen modal-middle">
        <div className="modal-box w-[95%] max-w-4xl h-auto p-2">
          <div className="w-full mb-4 overflow-x-auto">
            <div
              ref={componentRef}
              className="p-4 min-w-[780px] rounded-md h-full w-full bg-white"
            >
              <div className="p-3 water-mark border rounded-md border-[#f2e7fc]">
                <LBoardPrintD LBData={leaderBoardPrint} />
              </div>
            </div>
          </div>

          <div className="flex justify-center m-4 p-4 rounded-xl bg-[#f7f0ff] gap-x-5 items-center">
            <label htmlFor="LeaderBoard-Print" className="btn btn-sm">
              Cancel
            </label>
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-sm border-purple-700 bg-purple-700 text-white">
                  Download / Print
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const LeaderBoardPrint = ({ leaderBoardPrint }) => {
  return (
    <div>
      <LBPrintBtn leaderBoardPrint={leaderBoardPrint} />
    </div>
  );
};

export default LeaderBoardPrint;
