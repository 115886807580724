import React, { useState } from "react";
import { toast } from "react-toastify";
import create1 from "../../assets/icons/create (1).png";
import axios from "../../Utils/Axios.config";
import { ProgressBar } from "react-loader-spinner";
import { GoodFeedback, badFeedback, normalFeedback } from "../../data/feedback";

const EditeSubmition = ({ setEditSubmit, xmID, refetch, editSubmit }) => {
  const [result, user] = editSubmit;
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(result?.feedback);
  const [writing, setWriting] = useState(
    result?.writing ? result?.writing : undefined
  );
  const [pecticNun, setPecticNun] = useState(
    result.pecticNun ? result.pecticNun : undefined
  );
  const [mcq, setMcq] = useState(result.mcq ? result.mcq : undefined);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const subResult = {
      studentPh: result?.studentPh,
      subject: result?.subject,
      writing: writing,
      mcq: mcq,
      pecticNun: pecticNun,
      feedback: feedback,
    };

    axios
      .patch(`/exam/editResult/${xmID}`, subResult)
      .then((response) => {
        console.log(response.data, "hi");
        refetch();
        setLoading(false);
        toast.success("Successfully update the submit");
        setEditSubmit(null);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        toast.error("Opps No some this is Wrong..!!");
      });
  };

  return (
    <div>
      <input type="checkbox" id="Edit-submit" className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="bg-blue-100 pb-8 mb-10 h-auto modal-box p-3 py-5 sm:p-4">
          <div className="w-full text-center">
            <img src={create1} className="w-20 mx-auto" alt="create" />
            <h2 className="text-xl capitalize font-reem text-center font-bold text-primary">
              Update {result?.subject} Submit Result
            </h2>
          </div>
          <form onSubmit={handleSubmit} className="w-full font-reem  mt-3">
            <div className="bg-white rounded-xl p-3 sm:p-4">
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center w-full">
                  <div className="">
                    {user?.imageURL && (
                      <div className="w-9 h-9 ring ring-[#91f2dc] ring-offset-base-100 ring-offset-2 bg-primary rounded-full">
                        <img
                          src={user?.imageURL}
                          className="w-9 h-9 rounded-full"
                          alt="profile"
                        />
                      </div>
                    )}
                    {!user?.imageURL && (
                      <div className="w-full">
                        <div className="w-10 h-10 bg-primary rounded-full flex items-center justify-center ring ring-[#91f2dc] ring-offset-base-100 ring-offset-2">
                          <h2 className="text-xl uppercase font-bold text-white">
                            {user?.firstName?.slice(0, 1)}
                            {user?.lastName?.slice(0, 1)}
                          </h2>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="ml-2 element-font-4">
                    <h1 className="text-cyan-900 mb-0 font-bold">
                      {user?.firstName} {user?.lastName}
                    </h1>
                    <h1 className="text-sm text-green-900 font-bold">
                      {user?.class}
                    </h1>
                  </div>
                </div>
                <div className="">
                  <h1 className="text-secondary element-font-4 text-xl mb-0 font-bold">
                    {user?.roleNo}
                  </h1>
                </div>
              </div>
              <div className="mt-3">
                <div className="flex text-center gap-2 sm:gap-3 justify-between items-center">
                  {result?.writing && (
                    <div className="w-full text-left">
                      <label className="text-sm text-secondary text-left mb-2">
                        Writhing
                      </label>
                      <input
                        type="number"
                        placeholder="Writing part"
                        onChange={(e) => setWriting(e.target.value)}
                        value={writing}
                        className="input input-sm input-bordered input-success w-full"
                      />
                    </div>
                  )}
                  {result?.mcq && (
                    <div className="w-full text-left">
                      <label className="text-sm text-secondary text-left mb-2">
                        MCQ
                      </label>
                      <input
                        type="number"
                        placeholder="MCQ part"
                        onChange={(e) => setMcq(e.target.value)}
                        value={mcq}
                        className="input input-sm input-bordered input-success w-full"
                      />
                    </div>
                  )}
                  {result?.pecticNun && (
                    <div className="w-full text-left">
                      <label className="text-sm text-secondary text-left mb-2">
                        Pectical
                      </label>
                      <input
                        type="number"
                        placeholder="Pectical Number"
                        onChange={(e) => setPecticNun(e.target.value)}
                        value={pecticNun}
                        className="input input-sm input-bordered input-success w-full"
                      />
                    </div>
                  )}
                </div>
                <div className="relative mt-6 my-1.5">
                  <textarea
                    className="textarea leading-normal min-h-[130px] p-3 w-full textarea-success"
                    placeholder="Exam Feedback"
                    onChange={(e) => setFeedback(e.target.value)}
                    value={feedback}
                  ></textarea>
                  <div className="absolute flex gap-x-1 -top-3 right-2">
                    <span
                      onClick={() => setFeedback(normalFeedback)}
                      className="btn btn-xs btn-secondary"
                    >
                      Normal
                    </span>
                    <span
                      onClick={() => setFeedback(GoodFeedback)}
                      className="btn btn-xs btn-success text-white"
                    >
                      good
                    </span>
                    <span
                      onClick={() => setFeedback(badFeedback)}
                      className="btn btn-xs btn-error"
                    >
                      bad
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className=" mt-5 p-3 py-5 rounded-xl bg-white">
              {loading && (
                <div className="w-full flex items-center justify-center">
                  <ProgressBar
                    height="80"
                    width="80"
                    ariaLabel="progress-bar-loading"
                    wrapperStyle={{}}
                    wrapperClass="progress-bar-wrapper"
                    borderColor="#F4442E"
                    barColor="#51E5FF"
                  />
                </div>
              )}
              <div className="flex items-center justify-center gap-3">
                <input
                  type="submit"
                  value="Submit"
                  className="btn w-[100px] btn-primary text-white btn-sm"
                />
                <label htmlFor="Edit-submit" className="btn btn-sm w-[100px] ">
                  cancel
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditeSubmition;
