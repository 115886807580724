import React, { useContext, useEffect, useState } from 'react';
import teacherImg from "../../assets/icons/teacher.png"
import axios from '../../Utils/Axios.config';
import { useQuery } from 'react-query';

const AllRewordStudents = () => {
    const {data, isLoading, refetch, error, } = useQuery('reword', () =>
    axios.get('/reword').then((res) => res.data));
    
    return (
        <div>
            <input type="checkbox" id="Student-reword" className="modal-toggle" />
            <div className="modal h-screen modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-100 h-auto w-full sm:!w-11/12 !max-w-3xl px-3 pt-2 pb-4">
                    <label htmlFor="Student-reword" className="btn z-50 btn-sm btn-circle absolute right-2 top-2">✕</label>  
                    
                    <p className='my-5 text-center font-bold text-[20px] sm:text-xl font-all'>বিগত বছরে এসএসসি পরীক্ষায় গোল্ডেন A+ ও জিপিএ G.P.A 5.00 প্রাপ্ত শিক্ষার্থী</p>
                    <div className='reword-student-grid gap-3'>
                        {
                             data?.map((i, index) => (
                                <div key={index} className='overflow-hidden pb-3 h-[200px] sm:h-[240px] border-[#e9ecef] bg-[#f8fafc] relative rounded-md'>
                                   {!i?.imgUrl && <img src={teacherImg} className='w-full hover:scale-[1.1] duration-500 h-full' alt="" />}
                                    {i?.imgUrl && <img src={i?.imgUrl} className='w-full hover:scale-[1.1] duration-500 h-full' alt="" />}
                                    <div className=''>
                                        
                                    </div>
                                    <div className='w-full px-2 h-full flex items-end text-white shadow-GPA-5 absolute bottom-0 left-0'>
                                        <div className='text-center w-full mb-1'>
                                            <h3 className='text-white font-all text-[10px] sm:text-[12px]'>{i?.name}</h3>
                                            <h6 className='text-white font-normal text-[8px] sm:text-[10px]'>{i?.gpa}</h6>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllRewordStudents;