import React, { useState } from "react";
import ClassCard from "./ClassCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import ClassDetails from "../Modul/ClassDetails";
import class1 from "../../assets/images/class.jpg";
import class2 from "../../assets/images/class (1).jpg";
import class3 from "../../assets/images/class (2).jpg";
import class4 from "../../assets/images/class (3).jpg";
import class5 from "../../assets/images/class (4).jpg";
import class6 from "../../assets/images/class (6).jpg";
import class7 from "../../assets/images/class (7).jpg";
import class8 from "../../assets/images/class (8).jpg";
import { useContext } from "react";
import { userContext } from "../../App";

const Classes = () => {
  const [classDetails, setClassDetails] = useState("hello");

  const [users, userLoading, userRefetch, userError, , blocked] =
    useContext(userContext);
  const students = users?.filter((i) => i?.role?.includes("student"));

  const handaleClass = (classData) => {
    const studentValue = students?.filter((i) => i?.class?.includes(classData));
    return studentValue;
  };

  const ClassData = [
    {
      img: class1,
      class: "প্লে",
      students: handaleClass("Play"),
      tittle:
        "প্রাথমিক শিক্ষার জন্য আদর্শ ক্লাসরুম ও শিক্ষক, শিশুদের জন্য উপযুক্ত ক্লাসরুম পরিবেশ এবং সাধনা",
      describe: [
        {
          title: "প্রথম শিক্ষার্থীদের জন্য নামজাদের প্রদর্শনী আয়োজন করা হয়।",
        },
        {
          title: "অলস লেখার প্রথম চিহ্ন শেখানো হয়।",
        },
        {
          title:
            "পূর্ব অধ্যয়নের সাথে সম্পূর্ণ সম্পর্কিত প্রশ্নের সাথে পূর্বাভাস করা হয়।",
        },
        {
          title: "গণিত পাঠের সাথে আঙ্কের পরিচিতি করানো হয়।",
        },
        {
          title: "প্রথম শিক্ষার্থীদের জন্য চিত্র ও রং খেলার সুযোগ দেওয়া হয়।",
        },
      ],
    },
    {
      img: class7,
      class: "নার্সারি",
      students: handaleClass("Nursery"),
      tittle:
        "প্রাথমিক শিক্ষার জন্য আদর্শ ক্লাসরুম ও শিক্ষক, শিশুদের জন্য উপযুক্ত ক্লাসরুম পরিবেশ এবং সাধনা",
      describe: [
        {
          title: "প্রথম শিক্ষার্থীদের জন্য নামজাদের প্রদর্শনী আয়োজন করা হয়।",
        },
        {
          title: "অলস লেখার প্রথম চিহ্ন শেখানো হয়।",
        },
        {
          title:
            "পূর্ব অধ্যয়নের সাথে সম্পূর্ণ সম্পর্কিত প্রশ্নের সাথে পূর্বাভাস করা হয়।",
        },
        {
          title: "গণিত পাঠের সাথে আঙ্কের পরিচিতি করানো হয়।",
        },
        {
          title: "প্রথম শিক্ষার্থীদের জন্য চিত্র ও রং খেলার সুযোগ দেওয়া হয়।",
        },
      ],
    },
    {
      img: class8,
      class: "প্রথম",
      students: handaleClass("One"),
      tittle:
        "প্রাথমিক শিক্ষার জন্য আদর্শ ক্লাসরুম ও শিক্ষক, শিশুদের জন্য উপযুক্ত ক্লাসরুম পরিবেশ এবং সাধনা",
      describe: [
        {
          title: "প্রথম শিক্ষার্থীদের জন্য নামজাদের প্রদর্শনী আয়োজন করা হয়।",
        },
        {
          title: "অলস লেখার প্রথম চিহ্ন শেখানো হয়।",
        },
        {
          title:
            "পূর্ব অধ্যয়নের সাথে সম্পূর্ণ সম্পর্কিত প্রশ্নের সাথে পূর্বাভাস করা হয়।",
        },
        {
          title: "গণিত পাঠের সাথে আঙ্কের পরিচিতি করানো হয়।",
        },
        {
          title: "প্রথম শিক্ষার্থীদের জন্য চিত্র ও রং খেলার সুযোগ দেওয়া হয়।",
        },
      ],
    },
    {
      img: class2,
      class: "দ্বিতীয়",
      students: handaleClass("Two"),
      tittle:
        "দ্বিতীয় শ্রেণি শিক্ষার জন্য আদর্শ ক্লাসরুম ও শিক্ষক, প্রয়োজনীয় শিক্ষামূলক উপকরণ এবং পাঠ্যপুস্তক",
      describe: [
        {
          title:
            "দ্বিতীয় শ্রেণীর শিক্ষার্থীদের জন্য বাংলা ও ইংরেজি শব্দ প্রভাব এবং উচ্চারণ শেখানো হয়।",
        },
        {
          title: "অংকের প্রথম চিহ্ন ও গণিতের মূলতথ্য প্রদান করা হয়।",
        },
        {
          title: "অধিক চমৎকার ছবি খেলার সুযোগ দেওয়া হয়।",
        },
        {
          title: "প্রথম শিক্ষার্থীদের জন্য চিত্র ও রং খেলার সুযোগ দেওয়া হয়।",
        },
      ],
    },
    {
      img: class3,
      class: "তিতীয়",
      students: handaleClass("Three"),
      tittle:
        "দ্বিতীয় শ্রেণি শিক্ষার জন্য আদর্শ ক্লাসরুম ও শিক্ষক, প্রয়োজনীয় শিক্ষামূলক উপকরণ এবং পাঠ্যপুস্তক",
      describe: [
        {
          title:
            "বিভিন্ন কাজে বাংলা ও ইংরেজি ভাষার উচ্চারণ এবং বানান শেখানো হয়।",
        },
        {
          title:
            "পূর্বের শিক্ষা পাঠগুলি নিরীক্ষা করা হয় এবং নতুন বিষয় শিখানো হয়।",
        },
        {
          title: "গণিতে বিভিন্ন গণিতের ধরন সম্পর্কে জ্ঞান প্রদান করা হয়।",
        },
        {
          title:
            "প্রতিটি শিক্ষার্থীর জন্য অধিক রঙিন ছবি এবং খেলা সুযোগ দেওয়া হয়।",
        },
      ],
    },
    {
      img: class4,
      class: "চতুর্থ",
      students: handaleClass("Four"),
      tittle:
        "চতুর্থ শ্রেণি শিক্ষার জন্য আদর্শ ক্লাসরুম ও শিক্ষক, প্রয়োজনীয় শিক্ষামূলক উপকরণ এবং পাঠ্যপুস্তক",
      describe: [
        {
          title:
            "বাংলা এবং ইংরেজিতে উপসর্গ এবং প্রত্যয় সম্পর্কে জ্ঞান প্রদান করা হয়।",
        },
        {
          title:
            "পরিমিতির পরিচিতি এবং প্রক্রিয়া বিশেষতঃ গণিতের মৌলিক সংখ্যার উপর বিস্তারিত তথ্য দেওয়া হয়।",
        },
        {
          title:
            "প্রতিটি শিক্ষার্থীর জন্য বেশি উন্নয়নশীল গণিত খেলা সুযোগ দেওয়া হয়।",
        },
        {
          title:
            "নতুন বিষয় সম্পর্কে জানানো হয় এবং উন্নয়নের উপর ভরসা দেওয়া হয়।",
        },
      ],
    },
    {
      img: class5,
      class: "পঞ্চম",
      students: handaleClass("Five"),
      tittle:
        "পঞ্চম শ্রেণি শিক্ষার জন্য আদর্শ ক্লাসরুম ও শিক্ষক, প্রয়োজনীয় শিক্ষামূলক উপকরণ এবং পাঠ্যপুস্তক",
      describe: [
        {
          title:
            "বাংলা এবং ইংরেজিতে উপসর্গ, প্রত্যয়, শব্দভাণ্ডার এবং উচ্চারণ বিষয়ক পাঠ দেওয়া হয়।",
        },
        {
          title:
            "গণিতের বিভিন্ন সূত্র এবং প্রক্রিয়া শেখানো হয় যা পরীক্ষায় কাজে লাগে।",
        },
        {
          title:
            "প্রতিটি শিক্ষার্থীর জন্য বেশি কম্পিউটার শিক্ষা দেওয়া হয় যাতে তারা বিভিন্ন কাজের জন্য তাদের কম্পিউটার ব্যবহার করতে পারে।",
        },
        {
          title:
            "বিভিন্ন জ্ঞানকে প্রকাশে আনার জন্য প্রতিটি শিক্ষার্থীর জন্য কার্যকর বই এবং লেখাপড়া কাজ ব্যবহার করা হয়।",
        },
      ],
    },
    {
      img: class6,
      class: "ষষ্ঠ",
      students: handaleClass("Six"),
      tittle:
        "ষষ্ঠ শ্রেণি শিক্ষার জন্য আদর্শ ক্লাসরুম ও শিক্ষক, প্রয়োজনীয় শিক্ষামূলক উপকরণ এবং পাঠ্যপুস্তক",
      describe: [
        {
          title:
            "বাংলা এবং ইংরেজি ভাষার সার্থকতা এবং স্পষ্টতা বৃদ্ধি করতে পাঠ দেওয়া হয়।",
        },
        {
          title:
            "গণিতের প্রাকৃতিক সংখ্যা, সমবাহু ত্রিভুজ, বর্গমূল এবং নথিত কোন সমস্যা সম্পর্কে শিখানো হয়।",
        },
        {
          title:
            "প্রতিটি শিক্ষার্থীর জন্য বিভিন্ন উন্নয়নশীল কম্পিউটার গেম এবং কার্যকর সফটওয়্যার ব্যবহার করে তাদের কম্পিউটার দক্ষতা পরিষ্কার করা হয়।",
        },
        {
          title:
            "বাংলাদেশের ইতিহাস এবং সংস্কৃতির সম্পূর্ণ বিবরণ এবং এর সংশ্লিষ্ট ঘটনার পরিসংখ্যান এবং সম্পর্কিত তথ্য শেখানো হয়।",
        },
      ],
    },
    {
      img: class1,
      class: "সপ্তম",
      students: handaleClass("Seven"),
      tittle:
        "সপ্তম শ্রেণি শিক্ষার জন্য আদর্শ ক্লাসরুম ও শিক্ষক, প্রয়োজনীয় শিক্ষামূলক উপকরণ এবং পাঠ্যপুস্তক",
      describe: [
        {
          title:
            "বাংলা এবং ইংরেজি ভাষার উন্নয়নশীল লেখা এবং কথায় প্রকাশ করা এবং ভাষার সংক্ষেপ বৃদ্ধি করার জন্য উপস্থাপনা দেওয়া হয়।",
        },
        {
          title:
            "জীববিজ্ঞান, পদার্থ এবং রসায়নের প্রাথমিক ধারণা সম্পর্কে উপস্থাপনা এবং প্রক্রিয়ার শিক্ষা দেওয়া হয়।",
        },
        {
          title:
            "আরও উন্নয়নশীল কম্পিউটার কাজ এবং এর পরিষ্কার ব্যবহার জানানো হয়।",
        },
        {
          title:
            "বিভিন্ন সামাজিক বিষয় সম্পর্কে প্রস্তুতি দেওয়া হয়, যেমন সমাজে ভূমিকা, সংস্কৃতি এবং ইতিহাস।",
        },
      ],
    },
    {
      img: class2,
      class: "অষ্টম",
      students: handaleClass("Eight"),
      tittle:
        "অষ্টম শ্রেণি শিক্ষার জন্য আদর্শ ক্লাসরুম ও শিক্ষক, প্রয়োজনীয় শিক্ষামূলক উপকরণ এবং পাঠ্যপুস্তক",
      describe: [
        {
          title:
            "বাংলা এবং ইংরেজি ভাষার উন্নয়নশীল লেখা এবং কথায় প্রকাশ করা এবং ভাষার সংক্ষেপ বৃদ্ধি করার জন্য উপস্থাপনা দেওয়া হয়।",
        },
        {
          title:
            "জীববিজ্ঞান, পদার্থ এবং রসায়নের প্রাথমিক ধারণা সম্পর্কে উপস্থাপনা এবং প্রক্রিয়ার শিক্ষা দেওয়া হয়।",
        },
        {
          title:
            "আরও উন্নয়নশীল কম্পিউটার কাজ এবং এর পরিষ্কার ব্যবহার জানানো হয়।",
        },
        {
          title:
            "বিভিন্ন সামাজিক বিষয় সম্পর্কে প্রস্তুতি দেওয়া হয়, যেমন সমাজে ভূমিকা, সংস্কৃতি এবং ইতিহাস।",
        },
      ],
    },
    {
      img: class4,
      class: "নবম",
      students: handaleClass("Nine"),
      tittle:
        "নবম শ্রেণি শিক্ষার জন্য আদর্শ ক্লাসরুম ও শিক্ষক, প্রয়োজনীয় শিক্ষামূলক উপকরণ এবং পাঠ্যপুস্তক",
      describe: [
        {
          title:
            "বাংলা এবং ইংরেজি ভাষার উন্নয়নশীল লেখা এবং কথায় প্রকাশ করা এবং ভাষার সংক্ষেপ বৃদ্ধি করার জন্য উপস্থাপনা দেওয়া হয়।",
        },
        {
          title:
            "জীববিজ্ঞান, পদার্থ এবং রসায়নের প্রাথমিক ধারণা সম্পর্কে উপস্থাপনা এবং প্রক্রিয়ার শিক্ষা দেওয়া হয়।",
        },
        {
          title:
            "আরও উন্নয়নশীল কম্পিউটার কাজ এবং এর পরিষ্কার ব্যবহার জানানো হয়।",
        },
        {
          title:
            "বিভিন্ন সামাজিক বিষয় সম্পর্কে প্রস্তুতি দেওয়া হয়, যেমন সমাজে ভূমিকা, সংস্কৃতি এবং ইতিহাস।",
        },
      ],
    },
    {
      img: class8,
      class: "দশম",
      students: handaleClass("Ten"),
      tittle:
        "দশম শ্রেণি শিক্ষার জন্য আদর্শ ক্লাসরুম ও শিক্ষক, প্রয়োজনীয় শিক্ষামূলক উপকরণ এবং পাঠ্যপুস্তক",
      describe: [
        {
          title:
            "বাংলা এবং ইংরেজি ভাষার উন্নয়নশীল লেখা এবং কথায় প্রকাশ করা এবং ভাষার সংক্ষেপ বৃদ্ধি করার জন্য উপস্থাপনা দেওয়া হয়।",
        },
        {
          title:
            "জীববিজ্ঞান, পদার্থ এবং রসায়নের প্রাথমিক ধারণা সম্পর্কে উপস্থাপনা এবং প্রক্রিয়ার শিক্ষা দেওয়া হয়।",
        },
        {
          title:
            "আরও উন্নয়নশীল কম্পিউটার কাজ এবং এর পরিষ্কার ব্যবহার জানানো হয়।",
        },
        {
          title:
            "বিভিন্ন সামাজিক বিষয় সম্পর্কে প্রস্তুতি দেওয়া হয়, যেমন সমাজে ভূমিকা, সংস্কৃতি এবং ইতিহাস।",
        },
      ],
    },
  ];

  return (
    <div className="my-5">
      <div className="text-center font-all my-12">
        <h1 className="text-2xl sm:text-4 mt-5 mb-1 md:text-5xl sm:mb-8 font-bold">
          একনজরে স্কুলের <span className="text-purple-700">শ্রেণি</span> কক্ষ
          গুলো
        </h1>
        <p className="font-bold text-sm sm:text-[18px]">
          প্লে থেকে দশম শ্রেণী পর্যন্ত যা যা থাকছে।
        </p>
      </div>
      <div>
        <Swiper
          loop={true}
          navigation={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          modules={[Autoplay, Navigation]}
          className="testimonial-slider navigation-bl navigation-outline"
          breakpoints={{
            300: {
              slidesPerView: 1,
            },
            500: {
              slidesPerView: 2,
            },
            868: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
        >
          {ClassData?.map((item, index) => (
            <SwiperSlide key={index} className="">
              <ClassCard
                key={index}
                classes={item}
                setClassDetails={setClassDetails}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {
        <ClassDetails
          setClassDetails={setClassDetails}
          classDetails={classDetails}
        />
      }
    </div>
  );
};

export default Classes;
