import React, { useState } from 'react';
import axios from "../../Utils/Axios.config"
import { toast} from 'react-toastify';
import complete from '../../assets/images/complete1.png';
import { ProgressBar } from 'react-loader-spinner';

const AddModulConfirm = ({setAddModal, method, refetch, addModal}) => {

    const [user, data] = addModal;
    const [loading, setLoading] = useState(false);

    const API_URL = 'https://bulksmsbd.net/api/smsapi';
    const apiKey = 'I6dXEMHybK6zQZzr2UJd';
    const SENDER_ID = '8809617611038';
    const RECIPIENT_NUMBERS = user?.phoneNumber?.slice(1, );
    const message = `Dear ${user?.firstName} ${user?.lastName}, Congratulations! Your account has been created successfully Nobojagoron Academy. Login Number: ${user?.phoneNumber?.slice(1, )} Password: ${user?.password} Login Url: https://nabojagoron-academy.com`

    console.log(message, "message")


    const handleAdd = async () => {
        setLoading(true)

        const data1 = {
            api_key: apiKey,
            senderid: SENDER_ID,
            number: RECIPIENT_NUMBERS,
            message: message,
        };

        axios.patch(`/${method}/${user?._id}`, data)
        .then((response) => {
          
            axios.post(API_URL, data1)
            .then(response => {
                refetch();
                toast.success("successfully added the user")
                setLoading(false)
                setAddModal(null)
            })
            .catch(error => {
                refetch();
                toast.success("successfully added the user")
                setLoading(false)
                setAddModal(null)
            });

        })
        .catch((error) => {
            toast.error("Ops No..!! Something is wrong")
            setLoading(false)
        });
    }

    return (
        <div>
            <input type="checkbox" id="add-confirm-modal" className="modal-toggle" />
            <div className="modal h-screen modal-bottom sm:modal-middle">
                <div className="modal-box pb-8 mb-10 bg-blue-100 h-auto px-2 py-4 sm:py-8 sm:px-4">
                    <div className='text-xl text-center font-bold text-accent'>
                       <img src={complete} className='w-32 mx-auto' alt="complete" />
                       <h2 className='text-2xl font-bold text-primary'>Are Your sure..? you want to add <span className='text-secondary'>{user?.firstName} {user?.lastName}</span> this Academy </h2>
                    </div>
                    <div className='p-4  mt-5 shadow-md rounded-md bg-white'>
                        { loading &&
                            <div className='w-full flex items-center justify-center'>
                                <ProgressBar
                                height="80"
                                width="80"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{}}
                                wrapperClass="progress-bar-wrapper"
                                borderColor = '#F4442E'
                                barColor = '#51E5FF'
                                />
                            </div>
                        }
                        <div className='flex items-center justify-center gap-3'>
                            <button onClick={handleAdd} className="btn w-[100px] btn-primary text-white btn-sm">Yes</button>
                            <label htmlFor="add-confirm-modal" className="btn btn-sm w-[100px]">No</label>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    );
};

export default AddModulConfirm;