export const primarySub = [
  {
    name: "Bangla",
    maxNum: 100,
    wMax: 100,
    GScience: true,
  },
  {
    name: "English",
    maxNum: 100,
    wMax: 100,
    computer: true,
  },
  {
    name: "Math",
    maxNum: 100,
    wMax: 100,
    Drawing: true,
  },
  {
    name: "Arabic",
    maxNum: 100,
    wMax: 100,
  },
];

export const secondarySub = [
  {
    name: "Bangla",
    wMax: 60,
    maxNum: 60,
  },
  {
    name: "English",
    wMax: 60,
    maxNum: 60,
  },
  {
    name: "Math",
    wMax: 60,
    maxNum: 60,
  },
  {
    name: "Islam",
    wMax: 60,
    maxNum: 60,
  },
  {
    name: "Science",
    wMax: 60,
    maxNum: 60,
  },
  {
    name: "BD.W",
    wMax: 60,
    maxNum: 60,
  },
];

export const MiddleSub = [
  {
    name: "Bangla",
    wMax: 60,
    maxNum: 60,
  },
  {
    name: "English",
    wMax: 60,
    maxNum: 60,
  },
  {
    name: "Math",
    wMax: 60,
    maxNum: 60,
  },
  {
    name: "Islam",
    wMax: 60,
    maxNum: 60,
  },
  {
    name: "G.Science",
    wMax: 60,
    maxNum: 60,
  },
  {
    name: "History & BD.W",
    wMax: 60,
    maxNum: 60,
  },
  // {
  //   name: "History.S.S",
  //   wMax: 60,
  //   maxNum: 60,
  // },
  {
    name: "ICT",
    wMax: 60,
    maxNum: 60,
  },
];

export const jscSub = [
  {
    name: "Bangla",
    wMax: 70,
    mcq: 30,
    maxNum: 100,
  },
  {
    name: "English",
    wMax: 100,
    maxNum: 100,
  },
  {
    name: "Math",
    wMax: 70,
    mcq: 30,
    maxNum: 100,
  },
  {
    name: "Islam",
    wMax: 70,
    mcq: 30,
    maxNum: 100,
  },
  {
    name: "G.Science",
    wMax: 70,
    mcq: 30,
    maxNum: 100,
  },
  {
    name: "BD.W",
    wMax: 70,
    mcq: 30,
    maxNum: 100,
  },
  {
    name: "ICT",
    wMax: 25,
    mcq: 25,
    maxNum: 50,
  },
];

export const sscSub = [
  {
    name: "Bangla 1st",
    mcq: 30,
    wMax: 70,
    maxNum: 100,
  },
  {
    name: "Bangla 2nd",
    mcq: 30,
    wMax: 70,
    maxNum: 100,
  },
  {
    name: "English 1st",
    wMax: 100,
    maxNum: 100,
  },
  {
    name: "English 2nd",
    wMax: 100,
    maxNum: 100,
  },
  {
    name: "Math",
    mcq: 30,
    wMax: 70,
    maxNum: 100,
  },
  {
    name: "Islam",
    mcq: 30,
    wMax: 70,
    maxNum: 100,
  },
  {
    name: "ICT",
    mcq: 25,
    maxNum: 50,
    practical: 25,
  },
  {
    name: "Physics ",
    mcq: 25,
    wMax: 50,
    maxNum: 100,
    practical: 25,
  },
  {
    name: "Chemistry",
    mcq: 25,
    maxNum: 100,
    practical: 25,
    wMax: 50,
  },
  {
    name: "Biology",
    mcq: 25,
    maxNum: 100,
    practical: 25,
    wMax: 50,
  },
  {
    name: "BD.W",
    mcq: 30,
    maxNum: 100,
    wMax: 70,
  },
  {
    name: "Higher Math",
    mcq: 25,
    practical: 25,
    wMax: 50,
    maxNum: 100,
  },
  {
    name: "Agricultural",
    mcq: 25,
    practical: 25,
    wMax: 50,
    maxNum: 100,
  },
];
