export const handleClassData = (me, setData, students) => {
  if (me?.class === "Eight" || me?.class === "Nine" || me?.class === "Ten") {
    const studentValue = students?.filter((i) => i?.class === me?.class);

    const st = studentValue?.filter((i) => {
      if (me?.gender === "male") {
        return i?.gender === "male";
      } else {
        return i?.gender === "female";
      }
    });
    const sorted = st?.sort(function (a, b) {
      return a.roleNo - b.roleNo;
    });
    setData(sorted);
  } else if (me?.class === "Seven") {
    const studentValue = students?.filter((i) => i?.class?.includes("Seven"));
    const sorted = studentValue?.sort(function (a, b) {
      return a.roleNo - b.roleNo;
    });
    setData(sorted);
  } else {
    const studentValue = students?.filter((i) => i?.class?.includes(me?.class));
    const sorted = studentValue?.sort(function (a, b) {
      return a.roleNo - b.roleNo;
    });
    setData(sorted);
  }
};
