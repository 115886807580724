import React, { useContext, useState } from 'react';
import axios from "../../Utils/Axios.config.js"
import Marquee from 'react-fast-marquee';
import teacherImg from "../../assets/icons/teacher.png"
import AllRewordStudents from '../Modul/AllRewordStudents';
import { useQuery } from 'react-query';
import SpenerLoding from '../../Shared/SpenerLoding';

const RewordStudent = () => {
    const [teacherInfo, setTeacherInfo] = useState('teacher');

    const {data, isLoading, refetch, error, } = useQuery('reword', () =>
    axios.get('/reword').then((res) => res.data));

    const teacherLength = Math.floor(data?.length / 2);
    
    return (
        <div className='max-w-7xl mx-auto px-2 md:px-8 lg:px-16 ox-hidden'>
            <div className='text-center font-all mb-12 font-all mt-5'>
                <h1 className='text-2xl sm:text-4 mt-5 mb-1 md:text-5xl sm:mb-8 font-bold'>একনজরে  <span className='text-purple-500 font-Oswald'>G.P.A 5.00 </span>  শিক্ষার্থী</h1>
                <p className='font-bold text-sm sm:text-[18px]'>বিগত বছরে এসএসসি পরীক্ষায় গোল্ডেন A+ ও জিপিএ G.P.A 5.00 প্রাপ্ত শিক্ষার্থী</p>
            </div>
            <div className="relative pb-10">
                <Marquee 
                className='rounded-md mb-2 sm:mb-4 text-purple-400 text-[15px] md:text-[24px]'
                gradientWidth={20}
                pauseOnHover={true}
                gradientColor={[255,255,255,0.53]}
                speed={9}
                >
                   {
                    data?.slice(0, teacherLength)?.map((i, index) => (
                        <div className=''>
                            <div className='grid'>
                                <div className='w-[100px] sm:w-[150px] pb-3 h-[118px] sm:h-[170px] mx-1 sm:mx-2 overflow-hidden border-[#e9ecef] bg-[#f8fafc] relative rounded-xl' key={index}>
                                    {!i?.imgUrl && <img src={teacherImg} className='w-full hover:scale-[1.1] duration-500 h-full' alt="" />}
                                    {i?.imgUrl && <img src={i?.imgUrl} className='w-full hover:scale-[1.1] duration-500 h-full' alt="" />}
                                    <div className=''>
                                        
                                    </div>
                                    <div className='w-full px-2 h-full flex items-end text-white shadow-GPA-5 absolute bottom-0 left-0'>
                                        <div className='text-center w-full mb-1'>
                                            <h3 className='text-white font-all text-[10px] sm:text-[12px]'>{i?.name}</h3>
                                            <h6 className='text-white font-normal text-[8px] sm:text-[10px]'>{i?.gpa}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                   }
                </Marquee>
                <Marquee 
                className='rounded-md text-purple-400 text-[15px] md:text-[24px]'
                gradientWidth={20}
                pauseOnHover={true}
                gradientColor={[255,255,255,0.53]}
                speed={8}
                >
                   {
                    data?.slice(teacherLength, )?.map((i, index) => (
                        <div className=''>
                            <div className='grid'>
                                <div className='w-[100px] pb-3 sm:w-[150px] h-[118px] sm:h-[170px] mx-1 sm:mx-2 overflow-hidden border-[#e9ecef] bg-[#f8fafc] relative rounded-xl' key={index}>
                                    {!i?.imgUrl && <img src={teacherImg} className='w-full hover:scale-[1.1] duration-500 h-full' alt="" />}
                                    {i?.imgUrl && <img src={i?.imgUrl} className='w-full hover:scale-[1.1] duration-500 h-full' alt="" />}

                                    <div className='w-full px-2 h-full flex items-end text-white shadow-GPA-5 absolute bottom-0 left-0'>
                                        <div className='text-center w-full mb-1'>
                                            <h3 className='text-white font-all text-[10px] sm:text-[12px]'>{i?.name}</h3>
                                            <h6 className='text-white font-normal text-[8px] sm:text-[10px]'>{i?.gpa}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                   }
                </Marquee>
                <div className="w-full flex justify-center bg-[#ffffff65] blur-xl items-end py-3 z-10 px-2 h-[50px]  text-white absolute bottom-0 left-0">
                   
                </div>
            </div>

            {isLoading && <SpenerLoding />}

            <div className='z-50 mt-3 flex justify-center'>
                <label htmlFor='Student-reword' onClick={() => setTeacherInfo(data)} className='btn btn-circle text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] btn-wide sm:w-[300px] mx-auto font-all'>সবগুলো জিপিএ 5 শিক্ষার্থী দেখুন</label>
            </div>
            
            { teacherInfo &&
                <AllRewordStudents
                teacherInfo={teacherInfo}
                setTeacherInfo={setTeacherInfo}
                />
            }    
        </div>
    );
};

export default RewordStudent;