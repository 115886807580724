import React from "react";
import { userContext } from "../../App";
import { useContext } from "react";
import { MdOutlineSchool } from "react-icons/md";
import logo from "../../assets/images/log03.png";
import pen from "../../assets/images/pen.png";
import xmR from "../../assets/images/xm (2).jpg";
const LBoardPrintD = ({ LBData }) => {
  const [users] = useContext(userContext);
  const [LB, xm] = LBData;

  const userNm = (Phone) => {
    const user = users?.find((i) => i?.phoneNumber?.includes(Phone));
    return user;
  };

  return (
    <div className="font-reem">
      <div>
        <div className="flex items-center justify-between">
          <div className="flex gap-x-3 items-center">
            <div className="">
              <img src={logo} className="w-[110px] mx-auto" alt="logo" />
            </div>
            <div>
              <h1 className="text-4xl text-purple-700 font-bold">
                Nabojagoron Academy
              </h1>
              <h3 className="text-[24px] text-purple-600 mt-2">
                Nanni Bazar, Nalitabari, Sherpur.
              </h3>
            </div>
          </div>
          <div className="">
            <img src={xmR} className="w-[120px] mx-auto" alt="logo" />
          </div>
        </div>
        <div className="w-full mb-4 flex items-center">
          <div className="h-[2px] w-[50%] bg-[#da9ffb]"></div>
          <div className="font-reem w-full text-center text-purple-700 bg-gradient-to-r from-[#d9bafd5c] to-[#b4e9fb50] !border-[#da9ffb] font-bold px-2 py-0.5 uppercase text-[24px] rounded-[20px] border-[2px]">
            Leader Board - {new Date().getFullYear()}
          </div>
          <div className="h-[2px] w-[50%] bg-[#da9ffb]"></div>
        </div>
        <table className="w-full my-3">
          <thead>
            <tr className="text-center font-reem text-xl border !border-[#da9ffb] text-purple-700">
              <th className="!border-[#da9ffb] border bg-[#f4f1f8]">E-Name</th>
              <th className="!border-[#da9ffb] border">{xm}</th>
              <th className="!border-[#da9ffb] border bg-[#f4f1f8]">Class</th>
              <th className="!border-[#da9ffb] border">
                {userNm(LB[0]?.studentPh)?.class}
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div>
        {LB?.slice(0, 10).map((i, index) => (
          <div
            key={index}
            className="p-2 py-3 mb-3 px-3 flex bg-gradient-to-r from-[#d9bafd1e] to-[#b4e9fb1f] items-center justify-between border rounded-xl border-[#f0d2fc]"
          >
            <div className="flex items-center gap-x-2">
              <h1 className="font-bold text-purple-700 text-xl">{index + 1}</h1>
              <div className="w-9 h-9 grid place-content-center rounded-full bg-[#7e16a0] ring ring-[#d583f5] ring-offset-base-100 ring-offset-1">
                {!userNm(i?.studentPh)?.imageURL && (
                  <h2 className="text-white font-bold">
                    {userNm(i?.studentPh)?.firstName?.slice(0, 1)}
                    {userNm(i?.studentPh)?.lastName?.slice(0, 1)}
                  </h2>
                )}
                {userNm(i?.studentPh)?.imageURL && (
                  <img
                    src={userNm(i?.studentPh)?.imageURL}
                    className="w-9 h-9 overflow-hidden rounded-full"
                    alt="pic"
                  />
                )}
              </div>
              <h1 className="flex text-purple-700 font-bold text-[20px] items-center">
                {userNm(i?.studentPh)?.firstName}{" "}
                {userNm(i?.studentPh)?.lastName}{" "}
                <span className="font-bold ml-2 text-purple-700">
                  <MdOutlineSchool size={24} />
                </span>
              </h1>
            </div>
            <div className="flex items-center gap-x-2">
              <h1 className="font-bold text-lg text-cyan-700">{i?.results}</h1>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full text-purple-800 flex mt-4 justify-between">
        <div className="text-center font-reem text-sm w-[180px]">
          <h3 className="border-b pb-1 font-bold border-[#8b8b8b]">
            <img
              src="https://fontmeme.com/permalink/230520/ae1df4b56eda0344305a0437b6fe4a89.png"
              alt="signature-fonts"
              className="w-[150px] text-center -mb-1 mx-auto"
            />
          </h3>
          <h3 className="font-bold pt-1">( Exam Controller )</h3>
        </div>
        <div className="w-18 -mt-2 h-14">
          <img src={pen} alt="pne" className="w-18 h-14" />
        </div>
        <div className="text-center font-reem text-sm w-[130px]">
          <h3 className="border-b pb-1 text-center font-bold border-[#8b8b8b]">
            <img
              src="https://fontmeme.com/permalink/230520/e1e16894902aeb97b29bf4b97d6f5ae8.png"
              alt="signature-fonts"
              className="w-[100px] -mb-2 text-center mx-auto"
            />
          </h3>
          <h3 className="font-bold pt-1">( Head Teacher )</h3>
        </div>
      </div>
    </div>
  );
};

export default LBoardPrintD;
