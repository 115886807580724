
import { useQuery } from 'react-query';
import axios from '../Utils/Axios.config';

const useUsers = () => {

    const {data, isLoading, refetch, error, } = useQuery('user', () =>
    axios.get('/user').then((res) => res.data)
  );

  const users = data?.data.filter(i => i?.status === "active");
  const ApplyUsers = data?.data.filter(i => i?.status === "inactive");
  const blocked = data?.data.filter(i => i?.status === "blocked");

    return [users, isLoading, refetch, error, ApplyUsers, blocked];
}
export default useUsers;