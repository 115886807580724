import React from "react";
import Particles from "react-tsparticles";
import particalCon from "./particalCon";
const ParticalBg = () => {
  return (
    <div>
      <Particles params={particalCon} />
    </div>
  );
};

export default ParticalBg;
