import React, { useContext, useState } from 'react';
import { userContext } from '../../App';


const AttendanceToday = ({attendanceToday}) => {
    const [users, userLoading, userRefetch, userError] = useContext(userContext);
    const [attendance, attDate, setAttDate] = attendanceToday

    const userNm = (Phone) => {
        const user = users?.find(i => i?.phoneNumber?.includes(Phone));
        return user
    }

    return (
        <div>
            <input type="checkbox" id="attendance-today" className="modal-toggle" />
            <div className="modal modal-middle">
                <div className="modal-box px-3 mb-10 sm:mb-0 bg-blue-100">
                    <label htmlFor="attendance-today" className="btn z-50 btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <div className='flex items-center justify-between'>
                    <h1 className='text-secondary mb-3 mt-1 font-bold text-[20px]'>Today Attendance</h1>
                    <div className='w-full max-w-[150px]'>
                        <input type="date" value={attDate} onChange={(e) => setAttDate(e.target.value)} className="input sm:input-md input-sm input-bordered px-1 !border-[#c9a2f9] input-[#c9a2f9]  rounded-md w-full" name="date" id="" />
                    </div>
                    </div>
                
                    <div className='max-h-[600px] overflow-y-scroll'>
                        {
                        attendance?.map((i, index) => (
                            <div key={index} className='p-2 flex items-center justify-between border-b border-[#f0d2fc]'>
                                <div className='flex items-center gap-x-2'>
                                    <h1 className='font-bold'>{i?.roleNo}</h1>
                                    <div className='w-6 h-6 grid place-content-center overflow-hidden rounded-full bg-[#7e16a0] ring ring-[#d583f5] ring-offset-base-100 ring-offset-1'>
                                        {!userNm(i?.phoneNumber)?.imageURL && <h2 className='text-white text-xs font-bold'>{userNm(i?.phoneNumber)?.firstName?.slice(0, 1)}{userNm(i?.phoneNumber)?.lastName?.slice(0, 1)}</h2>}
                                        {userNm(i?.phoneNumber)?.imageURL && <img src={userNm(i?.phoneNumber)?.imageURL} className='w-full h-full rounded-full' alt='pic' />}
                                    </div>
                                    <h2 className=''>{userNm(i?.phoneNumber)?.firstName} {userNm(i?.phoneNumber)?.lastName}</h2>
                                </div>
                                <div className='flex items-center gap-x-2'>
                                    <span>{userNm(i?.phoneNumber)?.class}</span>
                                    {!i?.late && <h1 className={`${(i?.status === 'P')? "text-primary" : "text-accent"} font-bold text-lg`}>{i?.status}</h1>}
                                    {i?.late && <h1 className={` text-purple-700 font-bold text-lg`}>L</h1>}
                                </div>
                            </div>
                        ))}
                    
                    </div>

                </div>
            </div>
        </div>
    );
};

export default AttendanceToday;