import React, {useContext, useState} from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import gratuation from "../../assets/icons/graduated.png"
import teacher from "../../assets/icons/teacher.png"
import student from "../../assets/icons/students.png"
import userImg from "../../assets/icons/user.png"
import { userContext } from '../../App';

const BannerTop = () => {
    const [users, userLoading, userRefetch, userError] = useContext(userContext);
    const teachers = users?.filter(i => i?.role?.includes("teacher"));
    const students = users?.filter(i => i?.role?.includes("student"));
    const [focus, setFocus] = useState(false);
    return (
        <div className='flex items-center px-2 sm:px-4 justify-center md:mb-0 py-10 md:pt-8'>
            <div className="w-full max-w-5xl md:-mt-28 bg-gradient-to-r from-[#e3f5ff] to-[#ddf7ff] shadow-md p-2 py-3 pb-5 md:p-5 md:px-4 -bottom-20 rounded-xl">
                <div className='grid gap-3 sm:gap-4 grid-cols-2 md:grid-cols-4'>
                    <div className='p-1 sm:p-2 w-full flex items-center gap-x-2 border bg-[#f7edfd] border-[#f8d3fc] rounded-xl'>
                        <div className='w-16 sm:w-20'>
                            <img src={gratuation} className='w-full' alt="" />
                        </div>
                        <div>
                            <h3 className='text-secondary font-bold sm:text-3xl'>
                                <CountUp start={focus ? 0 : null} end={320} duration={4} redraw={true}>
                                    <VisibilitySensor onChange={(isVisible) => {
                                            if (isVisible) { setFocus(true); }
                                        }}>
                                    </VisibilitySensor>
                                </CountUp> 
                                +
                            </h3>
                            <p className='text-sm element-font-2'>A+ শিক্ষার্থী</p>
                        </div>
                    </div>
                    <div className='p-1 sm:p-2 w-full flex items-center gap-x-2 border bg-[#d2f8fb] border-[#b1f3fc] rounded-xl'>
                        <div className='w-16 sm:w-20'>
                            <img src={teacher} className='w-full' alt="" />
                        </div>
                        <div>
                        <h3 className='text-secondary font-bold sm:text-3xl'>
                                <CountUp start={focus ? 0 : null} end={teachers?.length} duration={3} redraw={true}>
                                    <VisibilitySensor onChange={(isVisible) => {
                                            if (isVisible) { setFocus(true); }
                                        }}>
                                    </VisibilitySensor>
                                </CountUp> 
                                +
                            </h3>
                            <p className='text-sm element-font-2'>শিক্ষক</p>
                        </div>
                    </div>
                    <div className='p-1 sm:p-2 w-full flex items-center gap-x-2 border bg-[#e4effd] border-[#bae4f7] rounded-xl'>
                        <div className='w-16 sm:w-20'>
                            <img src={student} className='w-full' alt="" />
                        </div>
                        <div>
                            <h3 className='text-secondary font-bold sm:text-3xl'>
                            <h3 className='text-secondary font-bold sm:text-3xl'>
                                <CountUp start={focus ? 0 : null} end={students?.length} duration={6} redraw={true}>
                                    <VisibilitySensor onChange={(isVisible) => {
                                            if (isVisible) { setFocus(true); }
                                        }}>
                                    </VisibilitySensor>
                                </CountUp> 
                                +
                            </h3>
                            </h3>
                            <p className='text-sm element-font-2'>শিক্ষার্থী</p>
                        </div>
                    </div>
                    <div className='p-1 sm:p-2 w-full flex items-center gap-x-2 border bg-[#d2fbe0] border-[#b1fcca] rounded-xl'>
                        <div className='w-16 sm:w-20'>
                            <img src={userImg} className='w-full' alt="" />
                        </div>
                        <div>
                            <h3 className='text-secondary font-bold sm:text-3xl'>
                            <h3 className='text-secondary font-bold sm:text-3xl'>
                                <CountUp start={focus ? 0 : null} end={580} duration={5} redraw={true}>
                                    <VisibilitySensor onChange={(isVisible) => {
                                            if (isVisible) { setFocus(true); }
                                        }}>
                                    </VisibilitySensor>
                                </CountUp> 
                                +
                            </h3>
                            </h3>
                            <p className='text-sm element-font-2'>অভিভাবক</p>
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    {/* <h1 className='sm:text-4xl text-xl font-bold element-font-1 text-primary'>নবজাগরণ একাডেমি</h1> */}
                    <p className="mt-5 font-all sm:text-[22px] ">আমাদের স্কুলের পরিচিতি দিয়ে শিক্ষার্থীদের মানসিক এবং সামাজিক উন্নয়ন করতে সর্বদা উদ্যমী হয়ে থাকি। স্কুলে ভর্তি  হওয়ার জন্য  নিচের লিঙ্ক থেকে ফর্মটি পূরণ করুন এবং স্কুলের পরিচিতি এবং সুযোগ-সুবিধা সম্পর্কে বিস্তারিত জানতে সর্বদা আমাদের সাথে যোগাযোগ করুন।</p>
                    <div className='flex justify-end pt-5'>
                        <Link className='' to="/register">
                            <span className='bg-[#267023] w-auto py-1.5 px-5 border-[4px] border-[#86eabd] rounded-3xl text-white font-all text-xl'>রেজিস্ট্রেশন করুন</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerTop;