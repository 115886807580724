import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { HiPlus } from "react-icons/hi";
import { ExamContext, MeContext } from "../../App";
import Loading from "../../Shared/Loading";
import { Link } from "react-router-dom";
import {
  MiddleSub,
  jscSub,
  primarySub,
  secondarySub,
  sscSub,
} from "../../data/subjectData";
import { BiEdit } from "react-icons/bi";

const XmChart = ({ user, setEditSubmit, xm, setXm }) => {
  const [me] = useContext(MeContext);
  const [xms, xmLoading, xmRefetch, myXm, xmError] = useContext(ExamContext);
  const comPxm = xms?.filter((i) => i?.status?.includes("complete"));
  const xmSelect = comPxm?.find((i) => i?.examName === xm);
  const [xmSub, setXmSub] = useState([...sscSub]);
  const myResult = xmSelect?.Results?.find((i) =>
    i.studentPh?.includes(user?.phoneNumber)
  );

  if (xmLoading) {
    <Loading />;
  }

  useEffect(() => {
    if (
      user?.class === "Play" ||
      user?.class === "Nursery" ||
      user?.class === "One" ||
      user?.class === "Two"
    ) {
      setXmSub(primarySub);
    } else if (
      user?.class === "Three" ||
      user?.class === "Four" ||
      user?.class === "Five"
    ) {
      setXmSub(secondarySub);
    } else if (user?.class === "Six" || user?.class === "Seven") {
      setXmSub(MiddleSub);
    } else if (user?.class === "Eight") {
      setXmSub(jscSub);
    } else if (user?.class === "Ten" || user?.class === "Nine") {
      setXmSub([...sscSub]);
    }
  }, [user]);

  const totalMarks = myResult?.subResult?.reduce(
    (accumulator, currentValue) => {
      const m = currentValue?.mcq ? currentValue?.mcq : 0;
      const w = currentValue?.writing ? currentValue?.writing : 0;
      const e = currentValue?.pecticNun ? currentValue?.pecticNun : 0;

      return accumulator + parseFloat(m) + parseFloat(w) + parseFloat(e);
    },
    0
  );
  const calculateMark = (x) => {
    const i = parseFloat(x?.mcq);
    const w = parseFloat(x?.writing);
    const p = parseFloat(x?.pecticNun);

    if (x?.pecticNun && x?.writing) {
      return i + w + p;
    } else if (x.mcq && x?.writing) {
      return i + w;
    } else if (x.mcq && x?.pecticNun) {
      return i + p;
    } else {
      return w;
    }
  };

  const handleWMP = (i, sub) => {
    const w = Math.floor((sub?.wMax / 100) * 33);
    const m = Math.floor((sub?.mcq / 100) * 33);
    if (i?.writing < w || i?.mcq < m) {
      return "F";
    } else {
      return "P";
    }
  };

  const handleGreat = (p, i) => {
    const sub = i?.subject;
    const crnSub = xmSub?.find((i) => i?.name === sub);
    const status = handleWMP(i, crnSub);
    if (crnSub?.mcq && status === "F") {
      return "F";
    } else if (p < (crnSub?.maxNum / 100) * 33) {
      return "F";
    } else if (
      p >= (crnSub?.maxNum / 100) * 33 &&
      p < (crnSub?.maxNum / 100) * 40
    ) {
      return "D";
    } else if (
      p >= (crnSub?.maxNum / 100) * 40 &&
      p < (crnSub?.maxNum / 100) * 50
    ) {
      return "C";
    } else if (
      p >= (crnSub?.maxNum / 100) * 50 &&
      p < (crnSub?.maxNum / 100) * 60
    ) {
      return "B";
    } else if (
      p >= (crnSub?.maxNum / 100) * 60 &&
      p < (crnSub?.maxNum / 100) * 70
    ) {
      return "A-";
    } else if (
      p >= (crnSub?.maxNum / 100) * 70 &&
      p < (crnSub?.maxNum / 100) * 80
    ) {
      return "A";
    } else if (
      p >= (crnSub?.maxNum / 100) * 80 &&
      p <= (crnSub?.maxNum / 100) * 100
    ) {
      return "A+";
    }
  };
  return (
    <div className="w-full">
      {myResult && (
        <div className="flex mb-3 items-center justify-between">
          <h3 className="text-purple-700 font-bold text-2xl">{xm}</h3>
          <div className="w-[130px]">
            <select
              onChange={(e) => setXm(e.target.value)}
              className="select sm:select-md select-sm select-bordered !border-[#c9a2f9] px-1 select-[#c9a2f9] bg-[#ebe1f8] rounded-md w-full"
            >
              {comPxm?.map((item, index) => (
                <option key={index} value={item?.examName.toString()} select>
                  {item?.examName.toString()}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {myResult &&
        myResult?.subResult?.map((results) => (
          <div
            tabIndex="0"
            className="collapse collapse-arrow shadow-sm bg-[#f6eefb] mb-2 w-full rounded-md py-1 px-2"
          >
            <div className="text-xl font-medium">
              <div className="flex items-center w-full justify-between">
                <p className="text-[12px] w-fullz sm:text-[16px]">
                  {results.subject}
                </p>
                <div className="text-[12px] w-[120px] justify-end sm:text-[16] flex items-center">
                  <p className="font-bold">{results.writing}</p>
                  {results.mcq && results.writing && (
                    <span className="mx-1 text-purple-800">
                      <HiPlus />{" "}
                    </span>
                  )}
                  {results.mcq && <p className="font-bold">{results.mcq}</p>}
                  {results.pecticNun && (
                    <span className="mx-1 text-purple-800">
                      <HiPlus />{" "}
                    </span>
                  )}
                  {results.pecticNun && (
                    <p className="font-bold">{results.pecticNun}</p>
                  )}
                  {results.mcq && (
                    <span className="mx-1 text-purple-800">=</span>
                  )}
                  {results.mcq && (
                    <p className="font-bold"> {calculateMark(results)}</p>
                  )}
                  <p className="font-bold ml-2 text-purple-700">
                    {handleGreat(calculateMark(results), results)}
                  </p>
                </div>
              </div>
            </div>
            <div className="collapse-content !mx-0 !px-0 w-full grid gap-2">
              <div className="border relative p-2 pb-1 mt-2 !mx-0 rounded-lg border-[#ebc5ff] bg-[#f6e6ff]">
                <div className="mt-1">
                  <p className="text-sm text-purple-700">{results.feedback}</p>
                  <div className="flex mt-1 text-[#b968e4] items-center justify-end">
                    <h1 className="text-xs">{results.sir}</h1>
                    {/* <h1 className='text-xs'>{format(new Date(results.date), 'PP')}</h1> */}
                  </div>
                </div>
                {me?.role === "admin" && (
                  <div className="absolute -top-[11px] right-2">
                    <label
                      htmlFor="Edit-submit"
                      onClick={() => setEditSubmit([results, user])}
                      className="border capitalize bg-[#eeddfc] flex items-center rounded-xl leading-loose border-[#ebc5ff] text-purple-700 px-3 font-bold cursor-pointer !py-0 text-[10px]"
                    >
                      Edit - <BiEdit />
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      {myResult && (
        <div className="flex mt-5 mb-2 items-center justify-between">
          <div>
            {/* <label
              htmlFor="result-sheet"
              onClick={() =>
                setResultSheet({ myResult, totalMarks, handleGreat, user })
              }
              className="border text-purple-700 px-3 py-[6px] cursor-pointer rounded-xl bg-[#f5e4fd] border-[#e9c9fb]"
            >
              Mark sheet
            </label> */}
          </div>
          <div className="w-100">
            <h3 className="text-purple-600 flex items-center gap-x-2">
              Marks:{" "}
              <span className="text-xl px-1 rounded-md bg-[#f5e4fd] font-bold">
                {totalMarks}
              </span>
            </h3>
          </div>
        </div>
      )}
      <div className="mt-5">
        <Link
          to="/dashboard/result-card"
          className="btn rounded-3xl border-[3px] border-purple-500 w-full btn-md text-xl font-reem bg-purple-700 text-white"
        >
          Download / Print
        </Link>
      </div>
      {!myResult && (
        <div className="my-5">
          <h3 className="text-purple-700 font-bold text-2xl text-center">
            Result is Not AvailAble...
          </h3>
        </div>
      )}
    </div>
  );
};

export default XmChart;
