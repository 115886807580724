import React, { useContext, useState } from 'react';
import './dashboard.css'
import { motion } from "framer-motion"
import {FaChevronLeft} from 'react-icons/fa'
import {BsChatLeftDots} from 'react-icons/bs'
import {HiArrowSmLeft, HiOutlineUserGroup, HiUserGroup, HiUsers} from 'react-icons/hi'
import { MdOutlineSchool, MdOutlineLeaderboard, MdSchool, MdLeaderboard} from 'react-icons/md'
import { AiOutlineHome, AiOutlineDashboard, AiFillHome, AiFillDashboard, AiOutlineNotification, AiFillNotification } from 'react-icons/ai'
import { NavLink, Link, Outlet, useLocation } from 'react-router-dom';
import { MeContext } from '../../App';

const Dashboard = () => {
    const [me, meLoading, meRefetch, meError] = useContext(MeContext);
    const [open, setOpen] = useState(true);
    const location = useLocation();
    const { pathname } = location;

    const goBack = () => {
        window.history.back();
      };

    return (
        <div className="flex">
            <motion.div 
            animate={{ width: !open ? '80px' : '210px',
                transition:{
                    duration: 0.5,
                    type: 'spring',
                    damping: 10, 
                }
            }}
            className="!hidden sm:!block h-screen bg-blue-100 z-20 sm:relative"
            >
                <div className="bg-base-100 hidden element-font-4 sm:block border-r rounded-lg p-2 h-full">
                    <div onClick={() => setOpen(!open)} className={`${!open && "rotate-180"} absolute cursor-pointer p-1 border-white border-2 rounded-full text-primary font-bold top-8 -right-2.5 bg-white`}> <FaChevronLeft /> </div>
                    <div className="flex items-center pt-4">
                        {/* <img src={logo} className={`cursor-pointer w-14 duration-500 ${!open && 'rotate-[360deg]'}`} alt="" /> */}
                        {open && <h1 className={`text-primary ml-2 origin-left text-2xl cursor-pointer uppercase whitespace-nowrap duration-300 font-medium`}><Link to='/'>Nabojagoron</Link></h1>}
                        {!open && <h1 className={`text-primary ml-2 origin-left text-2xl cursor-pointer whitespace-nowrap duration-300 font-medium`}><Link to='/'>N.A</Link></h1>}
                    </div>
                    <div className="mt-4">
                        <ul className=" menu overflow-y-auto">
                            <li className='font-bold my-1'>
                                <NavLink to='/dashboard/'
                                    className={({ isActive }) =>
                                    isActive ? 'border-r-[4px] border-primary rounded-l-md text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                                 }
                                >
                                    { (pathname === "/dashboard/")? <AiFillHome size={'20px'} /> :  <AiOutlineHome size={'20px'} /> }
                                    <h1 className={`origin-left whitespace-nowrap duration-300 font-medium ${!open && 'scale-0 hidden'}`}>Dashboard</h1>
                                </NavLink>
                            </li>
                            {((me?.role === "teacher") || (me?.role === "admin")) &&
                            <li className='font-bold my-1'>
                                <NavLink to='/dashboard/addResult'
                                    className={({ isActive }) =>
                                    isActive ? 'border-r-[4px] border-primary rounded-l-md text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                                 }
                                > 
                                    { (pathname === "dashboard/addResult")? <MdSchool size={'20px'} /> :  <MdOutlineSchool size={'20px'} /> }
                                    <h1 className={`origin-left whitespace-nowrap duration-300 font-medium ${!open && 'scale-0 hidden'}`}>Result</h1>
                                </NavLink>
                            </li>}

                            {(me?.role === "student") &&
                             <li className='font-bold my-1'>
                                <NavLink to='/dashboard/result'
                                    className={({ isActive }) =>
                                    isActive ? 'border-r-[4px] border-primary rounded-l-md text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                                 }
                                >
                                    { (pathname === "/dashboard/result")? <MdSchool size={'20px'} /> :  <MdOutlineSchool size={'20px'} /> } 
                                    <h1 className={`origin-left whitespace-nowrap duration-300 font-medium ${!open && 'scale-0 hidden'}`}>Result</h1>
                                </NavLink>
                            </li>
                            }

                            {(me?.role === "student") &&
                             <li className='font-bold my-1'>
                                <NavLink to='/dashboard/leaderBoard'
                                    className={({ isActive }) =>
                                    isActive ? 'border-r-[4px] border-primary rounded-l-md text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                                 }
                                >
                                    
                                    { (pathname === "/dashboard/leaderBoard")? <MdLeaderboard size={'20px'} /> :  <MdOutlineLeaderboard size={'20px'} /> } 
                                    <h1 className={`origin-left whitespace-nowrap duration-300 font-medium ${!open && 'scale-0 hidden'}`}>leaderBoard</h1>
                                </NavLink>
                            </li>
                            }

                            {((me?.role === "student") || (me?.role === "teacher")) &&
                             <li className='font-bold my-1'>
                                <NavLink to='/dashboard/announcement'
                                    className={({ isActive }) =>
                                    isActive ? 'border-r-[4px] border-primary rounded-l-md text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                                 }
                                >
                                    
                                    { (pathname === "/dashboard/announcement")? <AiFillNotification  size={'20px'} /> :  <AiOutlineNotification size={'20px'} /> } 
                                    <h1 className={`origin-left whitespace-nowrap duration-300 font-medium ${!open && 'scale-0 hidden'}`}>Announcement</h1>
                                </NavLink>
                            </li>
                            }

                            {(me?.role === "admin") &&
                            <li className='font-bold my-1'>
                                <NavLink to='/dashboard/users'
                                className={({ isActive }) =>
                                    isActive ? 'border-r-[4px] border-primary rounded-l-md text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                                }
                                >
                             
                                    { (pathname === "/dashboard/users")? <HiUserGroup size={'20px'} /> :  <HiOutlineUserGroup size={'20px'} /> } 
                                    <h1 className={`origin-left whitespace-nowrap duration-300 font-medium ${!open && 'scale-0 hidden'}`}>Users</h1>
                                </NavLink>
                            </li>}

                            {((me?.role === "teacher") || (me?.role === "admin")) &&
                            <li className='font-bold my-1'>
                                <NavLink to='/dashboard/attendance'
                                    className={({ isActive }) =>
                                    isActive ? 'border-r-[4px] border-primary rounded-l-md text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                                 }
                                >
                                    { (pathname === "/dashboard/attendance")? <AiFillDashboard size={'20px'} /> : <AiOutlineDashboard size={'20px'} /> }
                                    <h1 className={`origin-left whitespace-nowrap duration-300 font-medium ${!open && 'scale-0 hidden'}`}>Attendance</h1>
                                </NavLink>
                            </li>
                            }
                        </ul>
                    </div>
                </div> 
            </motion.div>
            <div className="bg-[#d7ebe4] element-font-4 flex-1 pb-[60px] sm:pb-0 sm:px-4 relative h-screen overflow-y-auto">
                <div className="p-2 sm:p-3 sm:rounded-lg mb-2 sm:mb-3 shadow-md bg-white">
                    <div className="flex items-center w-full justify-between">
                        <span className='cursor-pointer' onClick={goBack}>
                            <HiArrowSmLeft size={24} />
                        </span>
                        <Link to="/" className='text-primary text-sm sm:text-lg md:text-2xl element-font-4 font-bold'>Nabojagoron Academy</Link>         
                        <div>
                            <BsChatLeftDots size={24} />
                        </div>         
                    </div>
                </div>
                <Outlet />
                <div className="fixed z-[10000] px-3 p-1 py-1.5 bottom-0 left-0 border-t border-[#bed6cd] sm:hidden w-full bg-white">
                    <ul className='flex justify-between items-center'>
                        <li className=''>
                            <NavLink to='/dashboard/'
                                className={({ isActive }) =>
                                isActive ? 'border-t border-primary pt-2 text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                            }
                            >
                                <div className='flex justify-center'>
                                    { (pathname === "/dashboard/")? <AiFillHome size={'20px'} /> :  <AiOutlineHome size={'20px'} /> } 
                                </div>
                                <p className={`text-[12px] font-bold`}>Home</p>
                            </NavLink>
                        </li>

                        {((me?.role === "teacher") || (me?.role === "admin")) &&
                        <li className=''>
                            <NavLink to='/dashboard/addResult'
                                className={({ isActive }) =>
                                isActive ? 'border-t border-primary pt-2 text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                            }
                            >
                                <div className='flex justify-center'>
                                    { (pathname === "/dashboard/addResult")? <MdSchool size={'20px'} /> :  <MdOutlineSchool size={'20px'} /> } 
                                </div>
                                <p className={`text-[12px] font-bold`}>Result</p>
                            </NavLink>
                        </li>
                        }

                        {(me?.role === "student") &&
                        <li className=''>
                            <NavLink to='/dashboard/result'
                                className={({ isActive }) =>
                                isActive ? 'border-t border-primary pt-2 text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                            }
                            >
                                <div className='flex justify-center'>
                                    { (pathname === "/dashboard/result")? <MdSchool size={'20px'} /> :  <MdOutlineSchool size={'20px'} /> } 
                                </div>
                                <p className={`text-[12px] font-bold`}>Result</p>
                            </NavLink>
                        </li>
                        }

                        {(me?.role === "student") &&
                        <li className=''>
                            <NavLink to='/dashboard/leaderBoard'
                                className={({ isActive }) =>
                                isActive ? 'border-t border-primary pt-2 text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                            }
                            >
                                <div className='flex justify-center'>
                                    { (pathname === "/dashboard/leaderBoard")? <MdLeaderboard size={'20px'} /> :  <MdOutlineLeaderboard size={'20px'} /> } 
                                </div>
                                <p className={`text-[12px] font-bold`}>leaderBoard</p>
                            </NavLink>
                        </li>
                        }
                        {((me?.role === "student") || (me?.role === "teacher")) &&
                        <li className=''>
                            <NavLink to='/dashboard/announcement'
                                className={({ isActive }) =>
                                isActive ? 'border-t border-primary pt-2 text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                            }
                            >
                                <div className='flex justify-center'>
                                { (pathname === "/dashboard/announcement")? <AiFillNotification  size={'20px'} /> :  <AiOutlineNotification size={'20px'} /> } 
                                </div>
                                <p className={`text-[12px] font-bold`}>Announcement</p>
                            </NavLink>
                        </li>
                        }

                        {(me?.role === "admin") &&
                        <li className=''>
                            <NavLink to='/dashboard/users'
                                className={({ isActive }) =>
                                isActive ? 'border-t border-primary pt-2 text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                            }
                            >
                                <div className='flex justify-center'>
                                    { (pathname === "/dashboard/users")? <HiUserGroup size={'20px'} /> :  <HiOutlineUserGroup size={'20px'} /> }
                                </div>
                                <p className={`text-[12px] font-bold`}>Users</p>
                            </NavLink>
                        </li>
                        }


                        {((me?.role === "teacher") || (me?.role === "admin")) &&
                        <li className=''>
                            <NavLink to='/dashboard/attendance'
                                className={({ isActive }) =>
                                isActive ? 'border-t border-primary pt-2 text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                            }
                            >
                                <div className='flex justify-center'>
                                    { (pathname === "/dashboard/attendance")? <AiFillDashboard size={'20px'} /> : <AiOutlineDashboard size={'20px'} /> } 
                                </div>
                                <p className={`text-[12px] font-bold`}>Attendance</p>
                            </NavLink>
                        </li>
                        }

                        
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;