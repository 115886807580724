import React, { useState } from 'react';



const Rewords = () => {
    
    // https://i.ibb.co/mhcLrRD/1-10.jpg
    // https://i.ibb.co/tmXnt4h/1-20.jpg
    // https://i.ibb.co/54xXxQ8/1-23.jpg
    // https://i.ibb.co/LJvfpQz/1-25.jpg
    // https://i.ibb.co/j9NK2fc/1-30.jpg
    // https://i.ibb.co/Zd2WLFY/44.jpg
    // https://i.ibb.co/Rb282k6/DSC-0044.jpg
    // https://i.ibb.co/B34JMPy/img20230328-11271558-Copy-2.jpg
    // https://i.ibb.co/bbQ25SB/img20230328-11271558-Copy-3.jpg
    // https://i.ibb.co/VVJQXDZ/img20230328-11271558-Copy-4.jpg
    // https://i.ibb.co/xFnjZY7/img20230328-11271558-Copy-5.jpg
    // https://i.ibb.co/gVs295X/img20230328-11271558-Copy.jpg
    // https://i.ibb.co/N7np7ng/img20230328-11271558.jpg
    // https://i.ibb.co/3Fqmg93/NJA-2023-5.jpg
    // https://i.ibb.co/QDZS8ht/NJA-2023-8.jpg
    // https://i.ibb.co/sthxz9B/NJA-2023-13.jpg

    // https://i.ibb.co/sgbhxYt/338647862-531197345751869-7202459730544443040-n.jpg
    // https://i.ibb.co/WcvyB6V/338678371-609322981058170-5720122461254446967-n.jpg
    // https://i.ibb.co/84q35gh/338698566-1028427428040451-1468113049078485643-n.jpg



    return (
        <div className='my-10'>
            <div className='text-center font-all my-12'>
                <h1 className='text-2xl sm:text-4 mt-5 mb-1 md:text-5xl sm:mb-8 font-bold'>একনজরে স্কুলের <span className='text-purple-700'>গ্যালারি</span> দেখুন</h1>
                <p className='font-bold text-sm sm:text-[18px]'>স্কুলের অনুষ্ঠান এবং বিভিন্ন সেলিব্রেশন পিকচার গুলো</p>
            </div>
            <div className='sm:flex w-full gap-3 sm:gap-4 justify-between'>
                <div className="relative mb-4 sm:mb-0 transition-all duration-300 rounded-lg img-gallery overflow-hidden w-full sm:h-[500px] h-full" data-aos="zoom-in-right" data-aos-delay="300" data-aos-duration="600">
                    <img src="https://i.ibb.co/sthxz9B/NJA-2023-13.jpg" className='rounded-lg w-full duration-300 h-full' alt="" />
                    <div className="absolute flex rounded-lg transition-all items-end justify-center w-full h-full top-0 left-0 gallery">
                        <p className='text-white element-font-5 text-[12px] sm:text-[24px]'>সকল টিচারদের সাথে স্কুলের পিকচার</p>
                    </div>
                </div>
                <div className="w-full mb-3 grid grid-cols-2 gap-3 sm:gap-4 sm:mb-0">
                    <div className="relative transition-all duration-300 img-gallery overflow-hidden w-full h-full" data-aos="zoom-in-right" data-aos-delay="100" data-aos-duration="200">
                        <img src='https://i.ibb.co/VVJQXDZ/img20230328-11271558-Copy-4.jpg' className='rounded-lg w-full duration-300 h-full' alt="" />
                        <div className="absolute flex rounded-lg transition-all items-end justify-center w-full h-full top-0 left-0 gallery">
                            <p className='text-white element-font-5 text-[10px] sm:text-[14px]'>বক্তৃতা দিচ্ছেন প্রধান শিক্ষক জনাব মিজানুর রহমান</p>
                        </div>
                    </div>
                    <div className="relative transition-all duration-300 img-gallery overflow-hidden w-full h-full" data-aos="zoom-in-left" data-aos-delay="200" data-aos-duration="400">
                        <img src="https://i.ibb.co/WcvyB6V/338678371-609322981058170-5720122461254446967-n.jpg" className='rounded-lg w-full duration-300 h-full' alt="" />
                        <div className="absolute flex rounded-lg transition-all items-end justify-center w-full h-full top-0 left-0 gallery">
                            <p className='text-white element-font-5 text-[10px] sm:text-[14px]'>এস এস সি বিদায় অনুষ্ঠান</p>
                        </div>
                    </div>
                    <div className="relative transition-all duration-300 img-gallery overflow-hidden w-full h-full" data-aos="zoom-in-right" data-aos-delay="300" data-aos-duration="600">
                        <img src="https://i.ibb.co/Zd2WLFY/44.jpg" className='rounded-lg w-full duration-300 h-full' alt="" />
                        <div className="absolute flex rounded-lg transition-all items-end justify-center w-full h-full top-0 left-0 gallery">
                            <p className='text-white element-font-5 text-[10px] sm:text-[14px]'>২৬ শে মার্চ, মহান স্বাধীনতা দিবস উদযাপন</p>
                        </div>
                    </div>
                    <div className="relative transition-all duration-300 img-gallery overflow-hidden w-full h-full" data-aos="zoom-in-left" data-aos-delay="400" data-aos-duration="600">
                        <img src="https://i.ibb.co/B34JMPy/img20230328-11271558-Copy-2.jpg" className='rounded-lg w-full duration-300 h-full' alt="" />
                        <div className="absolute flex rounded-lg transition-all items-end justify-center w-full h-full top-0 left-0 gallery">
                            <p className='text-white element-font-5 text-[10px] sm:text-[14px]'>মাসিক মিটিং এর মাধ্যমে অভিভাবকদের অবহিতকরণ</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className='sm:flex mt-3 sm:mt-4 w-full gap-3 sm:gap-4 justify-between'>
                
                <div className="w-full mb-3 grid grid-cols-2 gap-3 sm:gap-4 sm:mb-0">
                    <div className="relative transition-all duration-300 img-gallery overflow-hidden w-full h-full" data-aos="zoom-in-right" data-aos-delay="100" data-aos-duration="200">
                        <img src="https://i.ibb.co/Rb282k6/DSC-0044.jpg" className='rounded-lg duration-300 w-full h-full' alt="" />
                        <div className="absolute flex rounded-lg transition-all items-end justify-center w-full h-full top-0 left-0 gallery">
                            <p className='text-white element-font-5 text-[10px] sm:text-[14px]'>স্বাধীনতা দিবসে র‍্যালি</p>
                        </div>
                    </div>
                    <div className="relative transition-all duration-300 img-gallery overflow-hidden w-full h-full" data-aos="zoom-in-left" data-aos-delay="200" data-aos-duration="400">
                        <img src="https://i.ibb.co/tmXnt4h/1-20.jpg" className='rounded-lg duration-300 w-full h-full' alt="" />
                        <div className="absolute flex rounded-lg transition-all items-end justify-center w-full h-full top-0 left-0 gallery">
                            <p className='text-white element-font-5 text-[10px] sm:text-[14px]'>ছাত্রদের নিয়ে সাইকেল ভ্রমন</p>
                        </div>
                    </div>
                    <div className="relative transition-all duration-300 img-gallery overflow-hidden w-full h-full" data-aos="zoom-in-right" data-aos-delay="300" data-aos-duration="600">
                        <img src="https://i.ibb.co/sgbhxYt/338647862-531197345751869-7202459730544443040-n.jpg" className='rounded-lg duration-300 w-full h-full' alt="" />
                        <div className="absolute flex rounded-lg transition-all items-end justify-center w-full h-full top-0 left-0 gallery">
                            <p className='text-white element-font-5 text-[10px] sm:text-[14px]'>বিদায় অনুষ্ঠানে বিদায়ী শিক্ষার্থী</p>
                        </div>
                    </div>
                    <div className="relative transition-all duration-300 img-gallery overflow-hidden w-full h-full" data-aos="zoom-in-left" data-aos-delay="400" data-aos-duration="600">
                        <img src="https://i.ibb.co/mhcLrRD/1-10.jpg" className='rounded-lg duration-300 w-full h-full' alt="" />
                        <div className="absolute flex rounded-lg transition-all items-end justify-center w-full h-full top-0 left-0 gallery">
                            <p className='text-white element-font-5 text-[10px] sm:text-[14px]'>শিক্ষা সফরে রাজশাহী বিশ্ববিদ্যালয়</p>
                        </div>
                    </div>
                </div>
                <div className="relative transition-all duration-300 img-gallery overflow-hidden w-full h-full sm:h-[500px]" data-aos="zoom-in-right" data-aos-delay="300" data-aos-duration="600">
                    <img src="https://i.ibb.co/84q35gh/338698566-1028427428040451-1468113049078485643-n.jpg" className='rounded-lg w-full duration-300 h-full' alt="" />
                    <div className="absolute flex rounded-lg transition-all items-end justify-center w-full h-full top-0 left-0 gallery">
                        <p className='text-white element-font-5 text-[12px] sm:text-[24px]'>বিদায় অনুষ্ঠানে শিক্ষক ও অতিথি বৃন্দ</p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Rewords;