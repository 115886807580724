import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import create1 from '../../assets/icons/create (1).png'
import create2 from '../../assets/icons/create (2).png'
import axios from '../../Utils/Axios.config'
import { ProgressBar } from 'react-loader-spinner';
import profile from '../../assets/images/Student-avatar.jpg'

const CreateReword = ({setOpenCreateReword, refetch, openCreateReword}) => {

    const xm = openCreateReword[0]
    const imageRef = useRef();
    const [image, setImage] = useState();
    const [img, setImg] = useState()
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState(xm?.name);
    const [gpa, setGpa] = useState(xm?.gpa? xm?.gpa : "GPA-5.00");
    const [year, setYear] = useState();

    const onImageChange = (event) => {
        if(event.target.files && event.target.files[0]){
            let img = event.target.files[0];
            setImg(img)
            setImage({
                image: URL.createObjectURL(img)
            })
        }
    }

    const imageStorageKey = '290c7a0f169eabc5cf1f1fe286564c38';

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const fromData = new FormData();
        fromData.append('image', img);
        
        const url = `https://api.imgbb.com/1/upload?key=${imageStorageKey}`
        fetch(url, {
            method: 'POST',
            body: fromData
        })

        .then(res => res.json())
        .then(result => {
            if(result){
                const img = result.data?.url;
                const RewordData = {
                    name: name,
                    gpa: gpa,
                    year: year,
                    imgUrl: img,
                }
        
                console.log(RewordData)
                if(openCreateReword[1] === "create"){
        
                    axios.post('/reword/create', RewordData)
                    .then(response => {
                        console.log(response.data);
                        refetch()
                        setLoading(false)
                        toast.success("Successfully create the Notice")
                        setOpenCreateReword(null)
                    })
                    .catch(error => {
                        console.error(error, 'dkdj');
                        setLoading(false)
                        toast.error("Opps No some this is Wrong..!!")
                    });
        
                }else{
                    axios.patch(`/reword/${xm?._id}`, RewordData)
                    .then(response => {
                        console.log(response.data);
                        refetch()
                        setLoading(false)
                        toast.success("Successfully update the Notice")
                        setOpenCreateReword(null)
                    })
                    .catch(error => {
                        console.error(error, 'kalu');
                        setLoading(false)
                        toast.error("Opps No some this is Wrong..!!")
                    });  
                
                    }
        
            }
         })


    }
    
    return (
        <div>
            <input type="checkbox" id="create-reword" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="bg-blue-100 pb-8 mb-10 h-auto modal-box p-3 py-5 sm:p-4">
                    {(openCreateReword[1] === "create") && 
                    <div className='w-full text-center'>
                        <img src={create2} className='w-20 mx-auto' alt="create" />
                        <h2 className='text-xl uppercase text-center font-bold text-primary'>Create Reword</h2>
                    </div>
                    }
                    {(openCreateReword[1] === "update") && 
                    <div className='w-full text-center'>
                        <img src={create1} className='w-20 mx-auto' alt="create" />
                        <h2 className='text-xl uppercase text-center font-bold text-primary'>Update Reword</h2>
                    </div>
                    }
                    <form onSubmit={handleSubmit} className='w-full mt-3'>
                        <div className='rounded-xl p-3 mt-16 bg-white w-full'>

                            <div className='w-full flex justify-center'>
                                <div onClick={() => imageRef.current.click()} className="avatar -mt-16 cursor-pointer">
                                    <div className="w-28 rounded-full ring bg-blue-200 ring-white shadow-lg ring-offset-base-100 ring-offset-2">
                                        { (!image && !xm?.imgUrl) && <img src={profile} alt='profile' />}
                                        { (xm?.imgUrl && !image  ) && <img src={xm?.imgUrl} alt='profile' />}
                                        { image && <img src={image.image} alt='profile' />}
                                    </div>
                                    <div style={{display: 'none'}} className="hidden">
                                        <input type="file" name="images" onChange={onImageChange} ref={imageRef} id="" />
                                    </div>
                                </div>
                            </div>

                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text text-cyan-900 font-bold">Reword Title</span>
                                </label>
                                <input 
                                    required
                                    type="text" 
                                    placeholder="Student-Name" 
                                    value={name} 
                                    onChange={(e) => setName(e.target.value)}
                                    className="input input-bordered bg-transparent input-sm sm:input-md input-primary w-full" 
                                
                                />
                                
                            </div>
                            
                            <div className='w-full flex justify-between gap-x-3'>
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text text-cyan-900 font-bold">Reword Description</span>
                                    </label>
                                    <select
                                        onChange={(e) => setGpa(e.target.value)}
                                        className="input input-bordered input-sm sm:input-md input-primary w-full"  
                                    >
                                        <option value="GPA-5.00" select>GPA-5.00</option>
                                        <option value="গোল্ডেন GPA-5.00">গোল্ডেন GPA-5.00</option>
                                    </select>
                                </div>
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text text-cyan-900 font-bold">Reword Description</span>
                                    </label>
                                    <select
                                        onChange={(e) => setYear(e.target.value)}
                                        className="input input-bordered input-sm sm:input-md input-primary w-full"  
                                    >
                                        <option value="2023" select>2023</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                        <option value="2026">2026</option>
                                        <option value="2027">2027</option>
                                        <option value="2028">2028</option>
                                        <option value="2029">2029</option>
                                        <option value="2030">2030</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className=" mt-5 p-3 py-5 rounded-xl bg-white">
                            { loading &&
                                <div className='w-full flex items-center justify-center'>
                                    <ProgressBar
                                    height="80"
                                    width="80"
                                    ariaLabel="progress-bar-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="progress-bar-wrapper"
                                    borderColor = '#F4442E'
                                    barColor = '#51E5FF'
                                    />
                                </div>
                            }
                            <div className='flex items-center justify-center gap-3'>
                                <input type="submit" value="Submit" className="btn w-[100px] btn-primary text-white btn-sm" />
                                <label htmlFor="create-reword" className="btn btn-sm w-[100px] ">cancel</label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateReword;