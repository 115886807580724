import React, { useContext } from 'react';
import logo from "../../assets/images/logo2.png"

const ResultSheet = ({resultSheet, present, absent}) => {
    const {myResult, totalMarks, handleGreat, user} = resultSheet;

    const STR = (i) => {
        const m = i?.mcq? i?.mcq : 0;
        const w = i?.writing? i?.writing : 0;
        const p = i?.pecticNun? i?.pecticNun : 0;
        return parseFloat(m) + parseFloat(w) + parseFloat(p);
    }

    const gpa = myResult?.subResult?.map(i => {
        return STR(i)
    })

    function calculateGPA(marks) {
        
        const gradePoints = marks?.map(mark => {
          if (mark >= 80) {
            return 5;
          } else if (mark >= 70) {
            return 4;
          } else if (mark >= 60) {
            return 3.5;
          } else if (mark >= 50) {
            return 3;
          } else if (mark >= 40) {
            return 2;
          } else if (mark >= 33) {
            return 1;
          } else {
            return 0;
          }
        });
        
       const great = (gpa) => {
            if (gpa >= 5) {
                return "A+";
              } else if (gpa >= 4) {
                return "A";
              } else if (gpa >= 3.5) {
                return "A-";
              } else if (gpa >= 3) {
                return "B";
              } else if (gpa >= 2) {
                return "C";
              } else if (gpa >= 1) {
                return "D";
              } else {
                return "F";
              }
            };
      
        const totalGradePoints = gradePoints.reduce((acc, curr) => acc + curr, 0);
        const gpa = totalGradePoints / marks.length;
        
        return [gpa.toFixed(2), great(gpa)];
      }

      const grtGPA = calculateGPA(gpa);


    return (
        <div>
            <input type="checkbox" id="result-sheet" className="modal-toggle" />
            <div className="modal h-screen  modal-bottom sm:modal-middle">
                <div className="modal-box pt-3 px-3 mb-8 sm:mb-0 bg-[#fcffff] border border-[#dffbfc] h-auto w-full md:!w-[700px] !max-w-5xl">
                    <label htmlFor="result-sheet" className="z-50 w-8 h-8 grid place-content-center rounded-full text-white bg-[#662f93] absolute right-2 top-2">✕</label>
                    <div>
                        <div className='text-center w-full relative'>
                            <h1 className='sm:text-4xl text-2xl font-bold'>Nabojagoran Academy</h1>
                            <h3 className='text-lg sm:mt-2'>Nonni, nalitabari, sherpur</h3>
                            <div className='sm:text-md text-sm font-bold mt-1'>
                                <h3>Academic Reports</h3>
                                <h3>Academic Session - {(new Date().getFullYear())}</h3>
                                <div className='flex items-center gap-x-2 justify-center'>
                                <h3>Class: {user?.class}</h3>
                                </div>
                            </div>
                            <div className='w-full absolute top-2 left-0 h-full flex items-center justify-between'>
                                <div className='ml-3'>
                                    <img src={logo} className='w-[60px] sm:w-[100px] mx-auto' alt="logo" />
                                </div>
                                <div className="">
                                <div className='w-10 h-12 sm:w-16 sm:h-20 mt-2 sm:mr-3 bg-purple-700 ring ring-[#dadbdb]'>
                                    {user?.imageURL && <img src={user?.imageURL} className='w-full h-full mx-auto' alt="logo" /> }
                                    {!user?.imageURL && <h2 className='sm:text-4xl pt-4 text-2xl font-bold text-white uppercase'>{user?.firstName?.slice(0, 1)}{user?.lastName?.slice(0, 1)}</h2>}
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full text-sm sm:text-md font-bold mt-1 text-secondary sm:mt-2 flex justify-between items-center'>
                            <div className=' flex items-center'>
                                <div className="w-[80px]">
                                    <h3>Name</h3>
                                    <h3>Address</h3>
                                </div>
                                <div className="">
                                    <h3>: {user?.firstName} {user?.lastName}</h3>
                                    <h3>: {user?.address}</h3>
                                </div>
                            </div>
                                <h3>Role No: {user?.roleNo}</h3>
                        </div>


                        <div className="w-full mt-3 result-table">
                            <table className="w-full">

                                <thead className='rounded-t-xl'>
                                    <tr className='text-sm bg-purple-700 rounded-t-xl text-white'>
                                        <th>Subject</th>
                                        <th>writing</th>
                                        <th>MCQ</th>
                                        <th>Pectic</th>
                                        <th>Total</th>
                                        <th>Great</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                      myResult?.subResult?.map((i, index) => (
                                        <tr key={index} className={`${((index % 2) !== 0)? "bg-[#d9feff] text-[#2c8e91]" : ""} sm:text-sm text-xs`}>
                                            <td className='border text-center border-[#f6eaf9]'>{i?.subject}</td>
                                            <td className='border text-center border-[#f6eaf9]'>{i?.writing}</td>
                                            <td className='border text-center border-[#f6eaf9]'>{i?.mcq? i?.mcq : 0 }</td>
                                            <td className='border text-center border-[#f6eaf9]'>{i?.pecticNun? i?.pecticNun : 0 }</td>
                                            <td className='border text-center border-[#f6eaf9]'>{STR(i)}</td>
                                            <td className='border text-center border-[#f6eaf9]'>{handleGreat(STR(i))}</td>
                                        </tr>
                                      ))  
                                    }
                                    <tr className='bg-[#f1e5fb] rounded-b-xl border text-xs !border-[#da9ffb] text-purple-700 font-bold'>
                                        <td className='border text-center !border-[#da9ffb]'>Attd</td>
                                        <td className='border text-center !border-[#da9ffb]'>{present?.length} / {absent?.length}</td>
                                        <td className='border text-center !border-[#da9ffb]'>Total</td>
                                        <td className='border text-center !border-[#da9ffb]'>{totalMarks}</td>
                                        <td className='border text-center !border-[#da9ffb]'>{grtGPA[0]}</td>
                                        <td className='border text-center !border-[#da9ffb]'>{grtGPA[1]}</td>
                                    </tr>
                                
                                </tbody>
                            </table>

                            <table className="w-full mt-4">

                                <thead>
                                    <tr className='text-sm text-left bg-purple-700 text-white'>
                                        <th className='p-1 px-2'>MARKS</th>
                                        <th className='p-1 px-2'>GPA</th>
                                        <th className='p-1 px-2'>GREAT</th>
                                        <th className='p-1 px-2'>STATUS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='bg-[#f1e5fb] border text-sm font-bold !border-[#da9ffb] text-purple-700'>
                                        <td className='border px-2 !border-[#da9ffb]'>{totalMarks}</td>
                                        <td className='border px-2 !border-[#da9ffb]'>{grtGPA[0]}</td>
                                        <td className='border px-2 !border-[#da9ffb]'>{grtGPA[1]}</td>
                                        <td className='border px-2 !border-[#da9ffb]'>PASS</td>
                                    </tr>
                                
                                </tbody>
                            </table>
                        </div>

                        <div className="w-full flex mt-3 justify-end">
                            <div className='text-center text-xs w-[130px]'>
                                <h3 className='border-b
                                 font-bold border-[#8b8b8b]'>C. E. O</h3>
                                <h3 className=''>Mijanur Rahman</h3>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResultSheet;