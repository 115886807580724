import React, { useContext, useState } from 'react';
import { userContext } from '../../App';
import AllTeachersCard from '../Modul/AllTeachersCard';
import Marquee from 'react-fast-marquee';
import teacherImg from "../../assets/icons/teacher.png"
import SpenerLoding from '../../Shared/SpenerLoding';

const Teachers = () => {
    const [users, userLoading, userRefetch, userError] = useContext(userContext);
    const teachers = users?.filter(i => i?.role?.includes("teacher"));
    const [teacherInfo, setTeacherInfo] = useState('teacher');

    const teacherLength = Math.floor(teachers?.length / 2) ;
    
    return (
        <div className='max-w-7xl mx-auto px-2 md:px-8 lg:px-16 ox-hidden'>
            <div className='text-center mb-12 font-all mt-5'>
                <h1 className='text-2xl sm:text-4 mt-5 mb-1 md:text-5xl sm:mb-8 font-bold'>একনজরে আমাদের <span className='text-purple-500'>শিক্ষক </span> মন্ডলী</h1>
                <p className='font-bold text-sm sm:text-[18px]'>সর্বদায় দক্ষ শিক্ষক দ্বারা নিয়ন্ত্রিত</p>
            </div>
            <div className="relative pb-10">
                <Marquee 
                className='rounded-md mb-2 sm:mb-4 text-purple-400 text-[15px] md:text-[24px]'
                gradientWidth={20}
                pauseOnHover={true}
                gradientColor={[255,255,255,0.53]}
                speed={10}
                >
                   {
                    teachers?.slice(0, teacherLength).map((i, index) => (
                        <div className=''>
                            <div className='grid'>
                                <div className='w-[160px] sm:w-[230px] h-[190px] sm:h-[280px] mx-1 sm:mx-2 overflow-hidden border-[#e9ecef] bg-[#f8fafc] relative rounded-xl' key={index}>
                                    {!i?.imageURL && <img src={teacherImg} className='w-full hover:scale-[1.1] duration-500 h-full' alt="" />}
                                    {i?.imageURL && <img src={i?.imageURL} className='w-full hover:scale-[1.1] duration-500 h-full' alt="" />}
                                    <div className='w-full px-2 h-full flex items-end text-white shadow-inner absolute bottom-0 left-0'>
                                        <div className='text-center w-full mb-1'>
                                            <h3 className='text-white element-font-1 text-[12px] font-bold sm:text-[14px]'>{i?.firstName} {i?.lastName}</h3>
                                            <h6 className='text-white font-normal text-[8px] sm:text-[10px]'>{i?.title}</h6>
                                            <h6 className='text-white font-normal text-[10px] sm:text-[11px]'>{i?.designation}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                   }
                </Marquee>
                <Marquee 
                className='rounded-md text-purple-400 text-[15px] md:text-[24px]'
                gradientWidth={20}
                pauseOnHover={true}
                gradientColor={[255,255,255,0.53]}
                speed={9}
                >
                   {
                    teachers?.slice(teacherLength, )?.map((i, index) => (
                        <div className=''>
                            <div className='grid'>
                                <div className='w-[160px] sm:w-[230px] h-[190px] sm:h-[280px] mx-1 sm:mx-2 overflow-hidden border-[#e9ecef] bg-[#f8fafc] relative rounded-xl' key={index}>
                                    {!i?.imageURL && <img src={teacherImg} className='w-full hover:scale-[1.1] duration-500 h-full' alt="" />}
                                    {i?.imageURL && <img src={i?.imageURL} className='w-full hover:scale-[1.1] duration-500 h-full' alt="" />}
                                    <div className='w-full px-2 h-full flex items-end text-white shadow-inner absolute bottom-0 left-0'>
                                        <div className='text-center w-full mb-1'>
                                            <h3 className='text-white element-font-1 text-[12px] font-bold sm:text-[14px]'>{i?.firstName} {i?.lastName}</h3>
                                            <h6 className='text-white font-normal text-[8px] sm:text-[10px]'>{i?.title}</h6>
                                            <h6 className='text-white font-normal text-[10px] sm:text-[11px]'>{i?.designation}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                   }
                </Marquee>
            </div>
            <div className='z-50 mt-3 flex justify-center'>
                <label htmlFor='teacher-info' onClick={() => setTeacherInfo(teachers[0])} className='btn btn-circle text-xl btn-wide mx-auto font-all'>সবগুলো শিক্ষক দেখুন</label>
            </div>

            {userLoading && <SpenerLoding />}
            
            { teacherInfo &&
                <AllTeachersCard
                teacherInfo={teacherInfo}
                setTeacherInfo={setTeacherInfo}
                />
            }    
        </div>
    );
};

export default Teachers;