import React, { useState } from "react";
import { allClassData } from "../../data/classData";

const SelectStudent = ({
  me,
  setClasse,
  students,
  setData,
  compXm,
  setXm,
  handleRole,
  data,
  xm,
  classe,
  role,
}) => {
  // ============ class ===========
  const handleClass = (classData) => {
    if (
      classData === "Seven-Boys" ||
      classData === "Seven-Girls" ||
      classData === "Eight-Boys" ||
      classData === "Eight-Girls" ||
      classData === "Nine-Boys" ||
      classData === "Nine-Girls" ||
      classData === "Ten-Boys" ||
      classData === "Ten-Girls"
    ) {
      const studentValue = students?.filter(
        (i) => i?.class === classData?.split("-")[0]
      );

      const st = studentValue?.filter((i) => {
        if (classData?.split("-")[1] === "Boys") {
          return i?.gender === "male";
        } else {
          return i?.gender === "female";
        }
      });
      const sorted = st?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      setData(sorted);
      setClasse(classData?.split("-")[0]);
    } else if (classData === "Seven-Boys" || classData === "Seven-Girls") {
      const studentValue = students?.filter((i) => i?.class?.includes("Seven"));
      const sorted = studentValue?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      setData(sorted);
      setClasse("Seven");
      console.log(sorted, "kisu pailam na");
    } else {
      const studentValue = students?.filter((i) =>
        i?.class?.includes(classData)
      );
      const sorted = studentValue?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      setData(sorted);
      setClasse(classData);
    }
  };

  return (
    <div>
      <div className={`mb-3 items-center w-full font-reem justify-between`}>
        <div className="flex gap-x-1 sm:gap-x-3 item-center justify-between">
          {
            <div className="w-full">
              <select
                onChange={(e) => setXm(e.target.value)}
                value={xm} // Update this line
                className="select sm:select-md select-sm select-bordered !border-[#c9a2f9] px-1 select-[#c9a2f9] bg-[#faf9fd] rounded-md w-full"
              >
                {compXm?.map((item, index) => (
                  <option key={index} value={item?.examName.toString()}>
                    {item?.examName.toString()}
                  </option>
                ))}
              </select>
            </div>
          }
          {!(me?.role === "student") && (
            <div className="w-[150px] sm:w-[200px]">
              <select
                onChange={(e) => handleClass(e.target.value)}
                // value={classe} // Update this line
                className="select sm:select-md select-sm select-bordered !px-1 sm:!px-2 !border-[#c9a2f9] rounded-md w-full"
              >
                {allClassData?.map((item, index) => (
                  <option key={index} value={item?.className.toString()}>
                    {item?.className.toString()}
                  </option>
                ))}
              </select>
            </div>
          )}
          {!(me?.role === "student") && (
            <div className="w-[120px] sm:w-[150px]">
              <select
                onChange={(e) => handleRole(e.target.value)}
                // value={role} // Update this line
                className="select sm:select-md select-sm select-bordered !px-1 sm:!px-2 !border-[#c9a2f9] rounded-md w-full"
              >
                {data?.map((item, index) => (
                  <option key={index} value={item?.roleNo.toString()}>
                    {item?.roleNo.toString()}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectStudent;
