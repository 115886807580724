import React from 'react';
import { Watch } from 'react-loader-spinner';
import { Link } from 'react-router-dom';

const WatchAnimateBtn = () => {
    const usertoken = localStorage.getItem('accessToken')
    return (
        <div>
            { usertoken ?
            (<Link to="dashboard/">
                <div className='border-[5px] sm:border-[8px] cursor-pointer w-[250px] sm:w-[300px] flex gap-2 sm:gap-4 items-center sm:mt-8 mt-5 sm:p-2 p-1 bg-purple-700 border-[#ca99f6] text-white rounded-[80px]'>
                    <div className='w-[36px] h-[36px] p-0 sm:w-[50px] sm:h-[50px] grid place-content-center border-[2px] sm:border-[3px] border-[#772eb6] bg-[#ca99f6] rounded-full'>
                        <div className='text-center p-0'>
                            <Watch
                            visible={true}
                            height="50"
                            width="50"
                            ariaLabel="dna-loading"
                            wrapperStyle={{}}
                            wrapperClass="dna-wrapper"
                            ballColors={['#ff0000', '#00ff00', '#0000ff']}
                            backgroundColor="#F4442E"
                            />
                        </div>
                    </div>
                    <h2 className='text-[26px] sm:text-2xl md:text-3xl lg:text-4xl font-all pl-2 font-bold'>শুরু করুন</h2>
                </div>
            </Link>) :
            (<Link to="register">
                <div className='border-[5px] sm:border-[5px] cursor-pointer w-[240px] sm:w-[300px] flex gap-2 items-center sm:mt-8 mt-5 sm:p-2 p-1 bg-purple-700 border-[#ca99f6] text-white rounded-[80px]'>
                    <div className='w-[36px] h-[36px] p-0 sm:w-[50px] sm:h-[50px] grid place-content-center border-[2px] sm:border-[3px] bg-[#ca99f6] border-[#ca99f6] rounded-full'>
                        <div className='text-center p-0'>
                            <Watch
                            visible={true}
                            height="50"
                            width="50"
                            ariaLabel="dna-loading"
                            wrapperStyle={{}}
                            wrapperClass="dna-wrapper"
                            ballColors={['#ff0000', '#00ff00', '#0000ff']}
                            backgroundColor="#F4442E"
                            />
                        </div>
                    </div>
                    <h2 className='text-[20px] font-all sm:text-2xl md:text-3xl ml-1 font-bold'>রেজিস্ট্রেশন করুন</h2>
                </div>
            </Link>)}
        </div>
    );
};

export default WatchAnimateBtn;