import React, { useState, useEffect } from 'react';

const useCountdown = (targetDate) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate.getTime() - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  return timeLeft;
};

// Example usage:
const targetDate = new Date('March 1, 2023 00:00:00');
const Countdown = () => {
  const timeLeft = useCountdown(targetDate);

  return (
    <div className='flex items-center justify-between mx-w-[200px]'>
      <p>Days: {timeLeft.days}</p>
      <p>Hours: {timeLeft.hours}</p>
      <p>Minutes: {timeLeft.minutes}</p>
      <p>Seconds: {timeLeft.seconds}</p>
    </div>
  );
};

export default useCountdown;
