import React, { useContext, useState } from "react";
import { ExamContext, attendanceContext } from "../../App";
import AttendancePiker from "../../Components/AnimateButtons/AttendancePiker";
import ProfileInformation from "../../Components/AnimateButtons/ProfileInformation";
import UserProfileImg from "../../Components/AnimateButtons/UserProfileImg";
import XmChart from "../../Components/AnimateButtons/XmChart";
import bg1 from "../../assets/images/banks.jpg";
import ResultSheet from "./ResultSheet";
import { FaPhone } from "react-icons/fa";
import edit from "../../assets/icons/user-profile.png";
import UpdateModul from "./UpdateModul";
import QuickSms from "./QuickSms";
import EditeSubmition from "./EditeSubmition";

const UserDetails = ({ setOpenStudent, refetch, openStudent }) => {
  const [xms, xmLoading, xmRefetch] = useContext(ExamContext);
  const comPxm = xms?.filter((i) => i?.status?.includes("complete"));
  const [xm, setXm] = useState(comPxm !== undefined ? comPxm[0]?.examName : "");
  const xmSelect = comPxm?.find((i) => i?.examName === xm);
  const [resultSheet, setResultSheet] = useState(null);
  const [editSubmit, setEditSubmit] = useState(null);
  const [quickSms, setQuickSms] = useState(null);
  const [updateModal, setUpdateModal] = useState(null);
  const [attendance, AttendanceLoading, atRefetch] =
    useContext(attendanceContext);
  const {
    firstName,
    lastName,
    imageURL,
    address,
    phoneNumber,
    gender,
    age,
    roleNo,
    role,
  } = openStudent;

  // const atten = attendance?.find(i => i?.AttendanceName === openStudent?.class);

  const atten = attendance?.find((i) => {
    if (
      openStudent?.gender === "male" &&
      (openStudent?.class === "Seven" ||
        openStudent?.class === "Eight" ||
        openStudent?.class === "Nine" ||
        openStudent?.class === "Ten")
    ) {
      return i?.AttendanceName === `${openStudent?.class}Boys`; // using template literal to concatenate the class name with "Boys"
    } else if (
      openStudent?.gender === "female" &&
      (openStudent?.class === "Seven" ||
        openStudent?.class === "Eight" ||
        openStudent?.class === "Nine" ||
        openStudent?.class === "Ten")
    ) {
      return i?.AttendanceName === `${openStudent?.class}Girls`; // using template literal to concatenate the class name with "Girls"
    } else {
      return i?.AttendanceName === openStudent?.class;
    }
  });

  let myAtten = [];
  let pre = [];
  let abs = [];

  atten?.Attendance?.map((i) => {
    i.newArr.map((dates) => {
      if (dates.phoneNumber === openStudent?.phoneNumber) {
        myAtten.push(dates);
      }
    });
  });

  const present = myAtten?.filter((x) => x?.status?.includes("P"));
  const absent = myAtten?.filter((x) => x?.status?.includes("A"));
  const todayAt = myAtten?.find(
    (i) =>
      new Date(i.date).toLocaleDateString() === new Date().toLocaleDateString()
  );

  present?.map((i) => {
    const p = new Date(i.date);
    pre.push(p);
  });

  absent?.map((i) => {
    const A = new Date(i.date);
    abs.push(A);
  });

  return (
    <div>
      <input type="checkbox" id="user-details" className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="bg-[#f7f2f7] relative !p-0 mb-10 sm:pb-0  h-screen modal-box w-full sm:!w-11/12 !max-w-5xl">
          <label
            htmlFor="user-details"
            className="btn z-50 btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <div
            style={{ backgroundImage: `url(${bg1})` }}
            className="bg-cover -z-10 w-full relative overflow-hidden rounded-b-[100px] h-[280px] sm:h-[320px] bg-gradient-to-t from-cyan-500 to-blue-500"
          >
            <div className="absolute z-10 w-full h-full top-0 left-0 bg-gradient-to-t from-[#52cce7d9] to-[#5270e7d3]">
              <div className="w-full absolute top-3 left-0 flex items-center justify-between px-2">
                <div className="!z-[10000] w-20 flex items-center">
                  {!(role === "admin") && (
                    <label
                      onClick={() => setUpdateModal(openStudent)}
                      htmlFor="add-update-modal"
                      className="w-10 h-10 cursor-pointer rounded-3xl"
                    >
                      <img src={edit} className="w-full" alt="" />
                    </label>
                  )}
                  <a
                    className="block ml-2"
                    href={`tel:${
                      openStudent?.phoneNumber2
                        ? openStudent?.phoneNumber2
                        : openStudent?.phoneNumber
                    }`}
                  >
                    <button className="btn btn-circle btn-ghost bg-green-200  text-green-700 btn-sm">
                      <span className="">
                        <FaPhone />
                      </span>
                    </button>
                  </a>
                </div>
              </div>
              <div className="cursor-pointer mb-9 relative w-28 h-28 sm:w-36 sm:h-36 mx-auto flex justify-center pt-6">
                {imageURL && (
                  <div className="avatar">
                    <div className=" w-28 h-28 sm:w-36 sm:h-36 ring-[6px] rounded-full bg-secondary flex items-center justify-center ring-[#91f2dc] ring-offset-base-100 ring-offset-2">
                      <img src={imageURL} alt="profile" />
                    </div>
                  </div>
                )}
                {!imageURL && (
                  <div className="relative w-28 h-28 sm:w-36 sm:h-36">
                    <UserProfileImg
                      me={openStudent}
                      textColor="sm:text-[70px] ring-offset-[3px] text-[60px] text-white"
                      className="w-28 h-28 sm:w-36 bg-secondary sm:h-36 ring-[6px] "
                    />
                  </div>
                )}
                <div className="absolute sm:bottom-16 bottom-12 sm:-right-4 -right-3 text-[18px] bg-white border-[4px] border-[#91f2dc] rounded-full w-8 h-8 !grid !place-content-center">
                  {todayAt?.status === "P" && (
                    <p className="text-green-700 font-bold">P</p>
                  )}
                  {(todayAt?.status === "A" || !todayAt) && (
                    <p className="text-red-700 font-bold">A</p>
                  )}
                </div>
              </div>
              <p className="text-3xl mt-3 font-bold text-white text-center">
                {firstName} {lastName}
              </p>
              <p className="text-xl sm:mt-1 font-bold text-[#f0e0fc] text-center">
                {address}
              </p>
            </div>
          </div>

          <div className="w-full -mt-[60px] px-2 sm:px-4">
            <ProfileInformation
              user={openStudent}
              present={present}
              absent={absent}
              setQuickSms={setQuickSms}
            />
            {!(role === "teacher" || role === "admin") && (
              <div className="border-[#eddeff] w-full bg-[#f3f9fb] border-b border rounded-xl mt-5 p-3">
                <h2 className="text-2xl text-purple-700 font-bold text-center">
                  Attendance and Result
                </h2>
              </div>
            )}
            {!(role === "teacher" || role === "admin") && (
              <div className="w-full md:flex justify-between gap-3 sm:gap-5 my-5">
                <div className="">
                  <div className="border-[#eddeff] w-full bg-[#f3f9fb] mb-5 sm:mb-0 mx-auto sm:w-[350px] border-b border rounded-2xl">
                    <AttendancePiker pre={pre} abs={abs} />
                  </div>
                </div>

                <div className="border-[#eddeff] w-full bg-[#f3f9fb] border-b border p-3 rounded-2xl">
                  <XmChart
                    user={openStudent}
                    setEditSubmit={setEditSubmit}
                    setResultSheet={setResultSheet}
                    xm={xm}
                    setXm={setXm}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {resultSheet && (
          <ResultSheet
            resultSheet={resultSheet}
            present={present}
            absent={absent}
          />
        )}
        {updateModal && (
          <UpdateModul
            updateModal={updateModal}
            refetch={refetch}
            setUpdateModal={setUpdateModal}
          />
        )}
        {editSubmit && (
          <EditeSubmition
            editSubmit={editSubmit}
            xmID={xmSelect?._id}
            setEditSubmit={setEditSubmit}
            refetch={xmRefetch}
          />
        )}
        {quickSms && <QuickSms quickSms={quickSms} setQuickSms={setQuickSms} />}
      </div>
    </div>
  );
};

export default UserDetails;
