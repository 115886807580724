import React from 'react';
import { DayPicker } from 'react-day-picker';

const AttendancePiker = ({pre, abs}) => {
       // ======== Day pikers ========
    const bookedStyle = { border: '2px solid red', color: '#ab3737', backgroundColor: '#f2c1c1', fontSize: '120%',}
    const mySelected = { border: '2px solid green', fontSize: '120%', backgroundColor: '#cefce6', color: 'green', }
    const myToday = { color: 'purple', fontSize: '130%'}

    return (
        <div className='w-[350px] mx-auto sm:p-4 p-3'>
            <DayPicker 
                styles={{
                    day: {
                        color: "#914ae8",
                        backgroundColor: "#f6eafa",
                        borderRadius: "10px",
                        fontSize: "14px",
                        padding: "-3px",
                        margin: "1px",
                        border: "1px solid #f0d2fc",
                    },
                    cell: { padding: "2px" },
                    caption: {
                        borderBottom: "1px solid #d7b7fd",
                        paddingBottom: "5px",
                        marginBottom: "5px",
                        marginLeft: "-20px",
                        color: "#914ae8"
                    },
                    caption_between: { paddingBottom: "5px!important" },
                    head: { color: "#914ae8" },
                    dropdown_month: { backgroundColor: "#0e78f9" },
                    table: {
                        padding: "0px",
                        margin: "0px",
                        marginLeft: "-20px",
                    },
                    nav_button_next: {
                        backgroundColor: "#efdbf7",
                        borderRadius: "10px",
                        fontSize: "12px",
                        margin: "1px",
                        color: "#914ae8",
                        border: "1px solid #d7b7fd",
                    },
                    nav_button_previous: {
                        backgroundColor: "#efdbf7",
                        borderRadius: "10px",
                        fontSize: "12px",
                        marginRight: "10px",
                        color: "#914ae8",
                        border: "1px solid #d7b7fd",
                    },
                    }}

                mode="single"
                selected={pre}
                defaultMonth={new Date} 
                fromYear={(2022)}
                modifiers={{ booked: abs }}
                modifiersStyles={{ 
                    booked: bookedStyle, 
                    selected: mySelected,
                    today: myToday
                }}
            />
        </div>
    );
};

export default AttendancePiker;