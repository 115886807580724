
import React, { useContext } from 'react';
import { Link, Navigate, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { AiFillHome, AiOutlineHome, AiOutlineSetting } from 'react-icons/ai'
import { FaBlogger } from 'react-icons/fa'
import {BiMessageSquareDetail} from 'react-icons/bi'
import logo from '../assets/images/logo.png'
import { useState } from 'react';
import { HiOutlineLogout } from 'react-icons/hi';
import { useEffect } from 'react';
import daimon from "../assets/icons/diamond1.png"
import userAply from "../assets/icons/user.png"
import { MdOutlineDashboardCustomize, MdOutlineManageAccounts, MdSchool } from 'react-icons/md';
import LogOutModule from '../Pages/Modul/LogOutModule';
import { MeContext, userContext } from '../App';
import UserProfileImg from '../Components/AnimateButtons/UserProfileImg';
import UpdateModul from '../Pages/Modul/UpdateModul';

const Navbar = ({}) => {
    const usertoken = localStorage.getItem('accessToken')
    const [me, meLoading, meRefetch, meError] = useContext(MeContext);
    const [users, userLoading, userRefetch, userError, ApplyUsers] = useContext(userContext);
    const [logout, setLogout] = useState(null)
    const [updateModal, setUpdateModal] = useState(null)
    const [stickyClass, setStickyClass] = useState("0");
    const location = useLocation();
    const { pathname } = location;

    function stickNavbar() {
        let windowHeight = window.scrollY;
         windowHeight >= 100 ? setStickyClass("sticky-nav") : setStickyClass("");
    }

    useEffect(() => {
        window.addEventListener("scroll", stickNavbar);
    }, []);

    const menuItems = <>
        <li className='mx-1'>
            <NavLink to='/' 
                className={({ isActive }) =>
                isActive ? 'bg-transparent text-purple-500 font-bold border-0 rounded-none mx-1 sm:mx-3' : 'px-1 rounded-none mx-1 sm:mx-3 text-white'
              }
             >
                <div className='sm:flex justify-center sm:items-center'>
                    <div className='font-bold fontSize text-[18px] sm:hidden flex justify-center'>
                        { (pathname === "/")? <AiFillHome size={'20px'} /> :  <AiOutlineHome size={'20px'} /> }
                    </div>
                    <span className='ml-1 mt-0 sm:block hidden hide-p font-all sm:text-[18px] text-xs'>হোম</span>
                </div>
            </NavLink>
        </li>
        <li className='mx-1'>
            <NavLink to='/about' 
                className={({ isActive }) =>
                isActive ? 'bg-transparent text-purple-500 font-bold border-0  rounded-none mx-1 sm:mx-3' : 'px-1 rounded-none mx-1 sm:mx-3 text-white'
             }
            >
                <div className='sm:flex justify-center sm:items-center'>
                    <div className='font-bold fontSize text-[18px] sm:hidden flex justify-center'><BiMessageSquareDetail /></div>
                    <span className='ml-1 mt-0 sm:block hidden font-all hide-p sm:text-[18px] text-xs'>এবাউট </span>
                </div>
            </NavLink>
        </li>
        <li className='mx-1'>
            <NavLink to='/blog'
                className={({ isActive }) =>
                isActive ? 'bg-transparent text-purple-500 font-bold border-0  rounded-none mx-1 sm:mx-3' : 'px-1 rounded-none mx-1 sm:mx-3 text-white'
              }
             >
                <div className='sm:flex justify-center sm:items-center'>
                    <div className='font-bold fontSize text-[18px] sm:hidden flex justify-center'><FaBlogger /></div>
                    <span className='ml-1 mt-0 sm:block font-all hidden hide-p sm:text-[18px] text-xs'>ব্লগ</span>
                </div>
            </NavLink>
        </li>
    </>

    const ProfileItems = <>
        <li className='pl-2 ml-0 list-none'>
          {
              usertoken?
              <div className="dropdown p-0 dropdown-end">
                    <label tabIndex="0" className="btn btn-ghost btn-circle online avatar">
                        <div className="w-10 rounded-full ring ring-primary ring-offset-base-100 ring-offset-1">
                         { me?.imageURL && <img src={me?.imageURL} alt='profile' />}
                         { !me?.imageURL &&
                         <div className='w-full'>
                            <UserProfileImg me={me} className='w-10 h-10 bg-primary' />
                        </div>
                        }
                        </div>
                    </label>
                    <ul tabIndex="0" className="p-2 py-3 shadow-md border text-cyan-800 relative bg-[#fafbfbbc] border-[#9dbcd5a1] top-[60px] menu menu-compact dropdown-content bg-base-100 rounded-box w-48">
                        <div className="text-center border-b-2 border-blue-200 mb-3">
                            <div className="avatar online">
                                <div className="w-14 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                                 { me &&
                                    <div className='w-full'>
                                        <div className="w-14 h-14 bg-primary rounded-full flex items-center justify-center ring ring-[#91f2dc] ring-offset-base-100 ring-offset-2">
                                            { !me?.imageURL &&<h2 className='text-3xl uppercase font-bold text-white'>{me?.firstName?.slice(0, 1)}{me?.lastName?.slice(0, 1)}</h2>}
                                            { me?.imageURL && <img src={me?.imageURL} alt='profile' />}
                                        </div>
                                    </div>
                                }
                                </div>
                            </div>
                            <h1 className='text-xl py-1 text-blue-900'>{me?.firstName} {me?.lastName}</h1>
                        </div>
                        <div className="flex p-1 absolute top-0 right-0 items-center justify-between w-full">
                            { (me?.role === 'admin') &&
                            <Link to='/dashboard/applyUser' className='p-1 border bg-[#d2f8fb] border-[#b1f3fc] rounded-lg'>
                                <div className='w-7'>
                                    <img src={userAply} className='w-full' alt="" />
                                </div>
                                <h3 className='text-secondary text-center font-bold sm:text-xl'>{ApplyUsers?.length}</h3>
                            </Link>
                            }
                            <div className='p-1 border bg-[#d2f8fb] border-[#b1f3fc] rounded-lg'>
                                <div className='w-7'>
                                    <img src={daimon} className='w-full' alt="" />
                                </div>
                                <h3 className='text-secondary text-center font-bold sm:text-xl'>{me?.gam}</h3>
                            </div>
                        </div>
                        <li className='font-bold'>
                            <NavLink to='/dashboard/'
                                className={({ isActive }) =>
                                isActive ? 'border-r-[4px] border-primary rounded-l-md text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                                }
                            >
                                <MdOutlineDashboardCustomize size={'20px'} /> 
                                <h1 className={`origin-left whitespace-nowrap duration-300 font-medium`}>Dashboard</h1>
                            </NavLink>
                        </li>
                        
                        <li className='font-bold'>
                            <NavLink to='/dashboard/'
                                className={({ isActive }) =>
                                isActive ? 'border-r-[4px] border-primary rounded-l-md text-[#156c65] bg-[#d6f8f5]' : 'hover:bg-[#d6f8f5]'
                                }
                            >
                                <AiOutlineSetting size={'20px'} /> 
                                <h1 className={`origin-left whitespace-nowrap duration-300 font-medium`}>Setting</h1>
                            </NavLink>
                        </li>
                                
                        <label onClick={() => setUpdateModal(me)} htmlFor="add-update-modal" className='pl-4 font-normal mb-1 hover:text-primary text-sm flex items-center gap-2 cursor-pointer'><MdOutlineManageAccounts size={'20px'} /> Update Profile</label>
                        <label onClick={() => setLogout("logout")} htmlFor="Logout-modal" className='pl-4 hover:text-accent flex items-center gap-2 cursor-pointer'><HiOutlineLogout size={'20px'} /> Sign Out</label>
                    </ul>
                </div>
              :
              <li className='mx-1'>
                <NavLink className='' to='/login' >
                    <div className='sm:flex justify-center sm:items-center'>
                        <span className='ml-1 mt-0 block text-white sm:text-[18px] text-sm'>Login</span>
                    </div>
                </NavLink>
            </li>
          }
        </li>
    </>
    return (
        <div className={` ${stickyClass} border-b border-cyan-800 element-font-4 duration-300 z-50 font-bold bg-cyan-900 text-cyan-900 w-full`}>
            <div className="navbar px-xl max-w-7xl flex items-center justify-between mx-auto">
                <div className="cursor-pointer">
                    <img src={logo} className='w-12' alt="logo" />
                    {/* <h1 className='text-white'><MdSchool size={50} /></h1> */}
                    {/* <h1 className='text-xl text-white ml-1 uppercase font-bold'>NA</h1> */}
                </div>
                <div className="">
                    <div className="flex">
                        <ul className="menu menu-horizontal font-bold p-0">
                            {menuItems}
                        </ul>
                    </div>
                </div>
                <div>
                {ProfileItems}
                </div>
            </div>
            { 
                logout && <LogOutModule
                logout = { logout}
                setLogout = {setLogout}
                 />
            }
            {
                updateModal && <UpdateModul 
                updateModal={updateModal} 
                refetch={meRefetch} 
                setUpdateModal={setUpdateModal}
                />
            }
        </div>
    );
};

export default Navbar;
