import { useQuery } from "react-query";
import axios from "../Utils/Axios.config";

const useUsers = (me) => {
  const { data, isLoading, refetch, error } = useQuery("attendance", () =>
    axios.get("/attendance").then((res) => res.data)
  );

  const attendance = data?.find((i) => {
    if (
      me?.gender === "male" &&
      (me?.class === "Seven" ||
        me?.class === "Eight" ||
        me?.class === "Nine" ||
        me?.class === "Ten")
    ) {
      return i?.AttendanceName === `${me?.class}Boys`; // using template literal to concatenate the class name with "Boys"
    } else if (
      me?.gender === "female" &&
      (me?.class === "Seven" ||
        me?.class === "Eight" ||
        me?.class === "Nine" ||
        me?.class === "Ten")
    ) {
      return i?.AttendanceName === `${me?.class}Girls`; // using template literal to concatenate the class name with "Girls"
    } else {
      return i?.AttendanceName === me?.class;
    }
  });

  let myAttendance = [];

  attendance?.Attendance?.map((i) => {
    i.newArr.map((dates) => {
      if (dates.phoneNumber === me?.phoneNumber) {
        myAttendance.push(dates);
      }
    });
  });

  const present = myAttendance?.filter((x) => x?.status?.includes("P"));
  const absent = myAttendance?.filter((x) => x?.status?.includes("A"));

  return [data, isLoading, refetch, myAttendance, present, absent, error];
};
export default useUsers;
