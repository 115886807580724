import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import signInBg from "../../assets/images/signIn-bg.jpg";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineCodeSandbox,
} from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SiKhanacademy } from "react-icons/si";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Loading from "../../Shared/Loading";
import { userContext } from "../../App";

const Registaion = () => {
  const [, , userRefetch] = useContext(userContext);
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [sector, setData] = useState("student");
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState("male");
  const [ph, setPh] = useState();
  const search = useLocation().search;
  let refer = new URLSearchParams(search).get("refer");
  const navigate = useNavigate();

  if (loading) {
    return <Loading />;
  }

  console.log(error);

  const onSubmit = async (data) => {
    const pass = ph?.slice(7);

    const formData = {
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: "+" + ph,
      address: data.address,
      password: pass,
      role: sector,
      gender: gender,
      age: data.age ? data.age : undefined,
      fatherName: sector === "student" ? data.fatherName : undefined,
      class: sector === "student" ? data.class : undefined,
      designation: sector === "teacher" ? data.designation : undefined,
    };

    console.log(formData, "oleikdikefddf");
    // send data backend
    if (ph?.length > 11 && ph?.length <= 13) {
      setLoading(true);
      setError("");
      fetch("https://nabojagorn-academy-server.vercel.app/api/v1/user/signup", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(formData),
      })
        .then((res) => res.json())
        .then((status) => {
          console.log(status);
          setLoading(false);
          if (status.status === "success") {
            reset();
            toast.success("Thank Your apply are successful");
            userRefetch();
            navigate("/welcomeApply");
          }
          if (status.status === "fail") {
            setError("Some this is wrong please try again");
          }
        });
    } else {
      setError("Plz Enter valid phone number");
    }
  };
  return (
    <div
      style={{ backgroundImage: `url(${signInBg})` }}
      className="bg-cover  h-screen"
    >
      <div className="h-screen bg-[#111f3b75] flex items-end sm:items-center justify-center w-full">
        <div
          className="w-full sm:w-[600px] bg-base-100 rounded-3xl sm:rounded-b-3xl rounded-b-none p-3 sm:p-8 shadow-md"
          data-aos="zoom-in-up"
          data-aos-delay="100"
          data-aos-duration="800"
        >
          <div className="text-center">
            <div className="text-center flex items-center justify-center mb-1">
              <SiKhanacademy size={60} />
            </div>
            <h2 className="text-3xl sm:mb-2 font-bold font-all text-center">
              আবেদন করুন
            </h2>
            <h6 className="text-center mb-3 leading-normal element-font-5 font-bold mx-auto sm:w-[80%]">
              নবজাগরণ একাডেমিতে ভর্তি হওয়ার জন্য ফরমটি পূরণ করে আবেদন করুন।{" "}
            </h6>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex w-full justify-between gap-x-3">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-cyan-900 font-bold">
                      First Name
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    className="input input-bordered input-sm sm:input-md input-primary w-full"
                    {...register("firstName", {
                      required: {
                        value: true,
                        message: "First Name is required",
                      },
                      minLength: {
                        value: 2,
                        message: "Must be 2 characters longer",
                      },
                    })}
                  />
                  {errors.firstName && (
                    <label className="label p-0 pt-1">
                      {errors.firstName?.type === "required" && (
                        <span className="label-text-alt text-red-500">
                          {errors.firstName.message}
                        </span>
                      )}
                      {errors.firstName?.type === "minLength" && (
                        <span className="label-text-alt text-red-500">
                          {errors.firstName.message}
                        </span>
                      )}
                    </label>
                  )}
                </div>

                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-cyan-900 font-bold">
                      Last Name
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="input input-bordered input-sm sm:input-md input-primary w-full"
                    {...register("lastName", {
                      required: {
                        value: true,
                        message: "Last Name is required",
                      },
                      minLength: {
                        value: 2,
                        message: "Must be 2 characters longer",
                      },
                    })}
                  />
                  {errors.lastName && (
                    <label className="label p-0 pt-1">
                      {errors.lastName?.type === "required" && (
                        <span className="label-text-alt text-red-500">
                          {errors.lastName.message}
                        </span>
                      )}
                      {errors.lastName?.type === "minLength" && (
                        <span className="label-text-alt text-red-500">
                          {errors.lastName.message}
                        </span>
                      )}
                    </label>
                  )}
                </div>
              </div>

              <div className="flex w-full gap-x-3 justify-between">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-cyan-900 font-bold">
                      Phone Number
                    </span>
                  </label>
                  <div className="text-left">
                    <PhoneInput
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true,
                      }}
                      inputClass="sm:!py-6 border !border-primary"
                      buttonClass="border sm:!py-5 !border-primary"
                      containerClass=""
                      containerStyle={{ width: "100%" }}
                      searchClass="border border-primary"
                      inputStyle={{ width: "100%" }}
                      country={"bd"}
                      value={ph}
                      onChange={setPh}
                    />
                  </div>
                </div>
              </div>

              <div className="flex w-full gap-3 justify-between">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-cyan-900 font-bold">
                      Your Address
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="Your Address"
                    className="input input-bordered input-sm sm:input-md input-primary w-full"
                    {...register("address", {
                      required: {
                        value: true,
                        message: "Your Address is required",
                      },
                      minLength: {
                        value: 5,
                        message: "Must be 5 characters longer",
                      },
                    })}
                  />
                  {errors?.address && (
                    <label className="label p-0 pt-1">
                      {errors.address?.type === "required" && (
                        <span className="label-text-alt text-red-500">
                          {errors.address.message}
                        </span>
                      )}
                      {errors.address?.type === "minLength" && (
                        <span className="label-text-alt text-red-500">
                          {errors.address.message}
                        </span>
                      )}
                    </label>
                  )}
                </div>
                {/* <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-cyan-900 font-bold">
                      Your Age
                    </span>
                  </label>
                  <input
                    type="date"
                    placeholder="YYYY-MM-DD"
                    className="input input-bordered bg-transparent input-sm sm:input-md input-primary w-full"
                    {...register("age", {
                      required: {
                        value: true,
                        message: "Your Age is required",
                      },
                    })}
                  />
                  {errors?.age && (
                    <label className="label p-0 pt-1">
                      {errors.age?.type === "required" && (
                        <span className="label-text-alt text-red-500">
                          {errors.age.message}
                        </span>
                      )}
                    </label>
                  )}
                </div> */}
                <div className="w-full">
                  <label className="label">
                    <span className="label-text text-cyan-900 font-bold">
                      Select Your Sector
                    </span>
                  </label>
                  <select
                    className="input input-bordered input-sm sm:input-md input-primary w-full"
                    onChange={(e) => setData(e.target.value)}
                  >
                    <option value="student" select>
                      Student
                    </option>
                    <option value="teacher">Teacher</option>
                    <option value="garden">Garden</option>
                  </select>
                </div>
              </div>

              {sector === "student" && (
                <div className="flex gap-3 justify-between">
                  <div className="w-full flex gap-x-3 items-center justify-between">
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text text-cyan-900 font-bold">
                          Father Name
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Father Name"
                        className="input input-bordered input-sm sm:input-md input-primary w-full"
                        {...register("fatherName", {
                          required: {
                            value: true,
                            message: "Father Name required",
                          },
                        })}
                      />
                      {errors?.fatherName && (
                        <label className="label p-0 pt-1">
                          {errors.fatherName?.type === "required" && (
                            <span className="label-text-alt text-red-500">
                              {errors.fatherName.message}
                            </span>
                          )}
                        </label>
                      )}
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text text-cyan-900 font-bold">
                          Mother Name
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Mother Name"
                        className="input input-bordered input-sm sm:input-md input-primary w-full"
                        {...register("motherName", {
                          required: {
                            value: true,
                            message: "Mother Name required",
                          },
                        })}
                      />
                      {errors?.fatherName && (
                        <label className="label p-0 pt-1">
                          {errors.fatherName?.type === "required" && (
                            <span className="label-text-alt text-red-500">
                              {errors.fatherName.message}
                            </span>
                          )}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {sector === "teacher" && (
                <div className="flex gap-3 w-full justify-between">
                  <div className="form-control w-full">
                    <label className="label">
                      <span className="label-text text-cyan-900 font-bold">
                        Select Your Designation
                      </span>
                    </label>
                    <select
                      className="input input-bordered input-sm sm:input-md input-primary w-full"
                      {...register("designation", {
                        required: {
                          value: true,
                          message: "designation is required",
                        },
                      })}
                    >
                      <option value="Assistant Teacher" select>
                        Assistant Teacher
                      </option>
                      <option value="Assistant Head Teacher">
                        Assistant Head Teacher
                      </option>
                      <option value="MLSS">MLSS</option>
                    </select>
                    {errors?.designation && (
                      <label className="label p-0 pt-1">
                        {errors.designation?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.designation.message}
                          </span>
                        )}
                      </label>
                    )}
                  </div>
                </div>
              )}

              <div className="flex my-2 mb-4 gap-x-3 items-center justify-between">
                {sector === "student" && (
                  <div className="form-control max-w-full w-full">
                    <label className="label">
                      <span className="label-text text-cyan-900 font-bold">
                        Select Class
                      </span>
                    </label>
                    <select
                      className="input input-bordered px-1 input-sm sm:input-md input-primary w-full"
                      {...register("class", {
                        required: {
                          value: true,
                          message: "class is required",
                        },
                      })}
                    >
                      <option value="Play" select>
                        Play
                      </option>
                      <option value="Nursery">Nursery</option>
                      <option value="One">One</option>
                      <option value="Two">Two</option>
                      <option value="Three">Three</option>
                      <option value="Four">Four</option>
                      <option value="Six">Six</option>
                      <option value="Seven">Seven</option>
                      <option value="Five">Five</option>
                      <option value="Eight">Eight</option>
                      <option value="Nine">Nine</option>
                      <option value="Ten">Ten</option>
                    </select>
                    {errors?.class && (
                      <label className="label p-0 pt-1">
                        {errors.class?.type === "required" && (
                          <span className="label-text-alt text-red-500">
                            {errors.class.message}
                          </span>
                        )}
                      </label>
                    )}
                  </div>
                )}
                <div className="w-full">
                  <label className="label">
                    <span className="label-text w-full text-left sm:text-right text-cyan-900 font-bold">
                      Select Your Gender
                    </span>
                  </label>
                  <div className="flex sm:justify-end mb-3">
                    <div className="form-control pr-2">
                      <label className="cursor-pointer flex items-center">
                        <span className="label-text font-bold pr-2">Male</span>
                        <input
                          type="radio"
                          name="gender"
                          value="male"
                          onChange={(e) => setGender(e.target.value)}
                          checked={gender === "male" ? true : false}
                          className="radio radio-primary radio-sm"
                        />
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="cursor-pointer flex items-center">
                        <span className="label-text font-bold mr-2">
                          Female
                        </span>
                        <input
                          type="radio"
                          name="gender"
                          value="female"
                          onChange={(e) => setGender(e.target.value)}
                          checked={gender === "female" ? true : false}
                          className="radio radio-primary radio-sm"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {error && (
                <p className="text-red-500 mb-2">
                  <small>{error}</small>
                </p>
              )}
              <input
                className="btn w-full text-white uppercase font-bold bg-gradient-to-r from-[#2091d9] to-[#13b38f] hover:from-[#13b38f] hover:to-[#2091d9] duration-300 border-0"
                type="submit"
                value="Submit"
              />
            </form>

            <div className="divider !mb-1">OR</div>
            {refer && (
              <div className="flex items-center justify-around">
                <div className="border hover:border-primary cursor-pointer hover:text-primary rounded-lg p-2">
                  <p className="flex items-center gap-1">
                    <AiOutlineCodeSandbox size={20} />{" "}
                    <span className="">Referral Code: {refer}</span>
                  </p>
                </div>
              </div>
            )}
            <div className="flex sm:mt-5 items-center font-bold justify-between">
              <div>
                <Link to="/">
                  <h6 className="flex items-center gap-1 text-[#2091d9]">
                    <AiOutlineArrowLeft />
                    Back
                  </h6>
                </Link>
              </div>
              <div>
                <Link to="/login">
                  <h6 className="flex items-center gap-1 text-[#13b38f]">
                    Login <AiOutlineArrowRight />
                  </h6>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registaion;
