import { ClassData } from "../data/classData";
import {
  MiddleSub,
  jscSub,
  primarySub,
  secondarySub,
  sscSub,
} from "../data/subjectData";

//(x?.mark >= Math.ceil((crnSub?.maxNum / 100) * 33))

export const totalMaxMarks = (classes) => {
  let totalSub = [];
  if (
    classes === "Play" ||
    classes === "Nursery" ||
    classes === "One" ||
    classes === "Two"
  ) {
    totalSub.push(...primarySub);
  } else if (classes === "Three" || classes === "Four" || classes === "Five") {
    totalSub.push(...secondarySub);
  } else if (classes === "Six" || classes === "Seven") {
    totalSub.push(...MiddleSub);
  } else if (classes === "Eight") {
    totalSub.push([...jscSub]);
  } else if (classes === "Ten" || classes === "Nine") {
    totalSub.push(...sscSub);
  }

  const totalMarks = totalSub
    ?.slice(0, 12)
    ?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue?.maxNum;
    }, 0);
  return totalMarks;
};

const handleWMP = (i, sub) => {
  const w = Math.floor((sub?.wMax / 100) * 33);
  const m = Math.floor((sub?.mcq / 100) * 33);
  if (i?.writing < w || i?.mcq < m) {
    return "F";
  } else {
    return "P";
  }
};

export const handleGreat = (p, crnS, sub, i) => {
  const crnSub = crnS?.find((i) => i?.name === sub);
  const status = handleWMP(i, crnSub);
  if (crnSub?.mcq && status === "F") {
    return "F";
  } else if (p < (crnSub?.maxNum / 100) * 33) {
    return "F";
  } else if (
    p >= (crnSub?.maxNum / 100) * 33 &&
    p < (crnSub?.maxNum / 100) * 40
  ) {
    return "D";
  } else if (
    p >= (crnSub?.maxNum / 100) * 40 &&
    p < (crnSub?.maxNum / 100) * 50
  ) {
    return "C";
  } else if (
    p >= (crnSub?.maxNum / 100) * 50 &&
    p < (crnSub?.maxNum / 100) * 60
  ) {
    return "B";
  } else if (
    p >= (crnSub?.maxNum / 100) * 60 &&
    p < (crnSub?.maxNum / 100) * 70
  ) {
    return "A-";
  } else if (
    p >= (crnSub?.maxNum / 100) * 70 &&
    p < (crnSub?.maxNum / 100) * 80
  ) {
    return "A";
  } else if (
    p >= (crnSub?.maxNum / 100) * 80 &&
    p <= (crnSub?.maxNum / 100) * 100
  ) {
    return "A+";
  }
};

export const totalMarks = (myResult) => {
  const marks = myResult?.subResult?.reduce((accumulator, currentValue) => {
    const m = currentValue?.mcq ? currentValue?.mcq : 0;
    const w = currentValue?.writing ? currentValue?.writing : 0;
    const e = currentValue?.pecticNun ? currentValue?.pecticNun : 0;

    return accumulator + parseFloat(m) + parseFloat(w) + parseFloat(e);
  }, 0);

  return marks;
};

export const maxMark = (sub) => {
  const marks = ClassData.find((i) => i.className === sub);
  return marks?.mark;
};

const STR = (i) => {
  const m = i?.mcq ? i?.mcq : 0;
  const w = i?.writing ? i?.writing : 0;
  const p = i?.pecticNun ? i?.pecticNun : 0;
  const mark = parseFloat(m) + parseFloat(w) + parseFloat(p);
  return mark;
};

export const calculateGreat = (myResult, xmSub) => {
  const myGreats = [];
  myResult?.subResult?.map((r) => {
    const great = handleGreat(STR(r), xmSub, r?.subject, r);
    myGreats.push(great);
  });
  const subGreat = myGreats;
  const fassd = subGreat?.filter((i) => i === "F");
  const fail = xmSub?.length - subGreat?.length;
  // console.log(fail, xmSub?.slice(0, 11)?.length, subGreat?.length, "hii");
  const result = {
    myGreats: myGreats,
    status: fassd?.length + fail > 0 ? "FAIL" : "PASS",
    rejected: fail + fassd?.length,
  };
  return result;
};

// Calculate my position
function groupBy(objectArray, property) {
  return objectArray?.reduce((acc, obj) => {
    const key = obj[property];
    const curGroup = acc[key] ?? [];

    return { ...acc, [key]: [...curGroup, obj] };
  }, {});
}

export const calculateMyPosition = (xms, phone) => {
  const groupedPeople = groupBy(xms, "studentPh");

  const Totalresults = [];

  for (const property in groupedPeople) {
    let results = 0;
    let studentPh;

    groupedPeople[property]?.map((i) => {
      const sub = i?.subResult;

      sub?.map((x) => {
        const writing = x?.writing ? x?.writing : 0;
        const mcq = x?.mcq ? x?.mcq : 0;
        const pecticNun = x?.pecticNun ? x?.pecticNun : 0;
        const result = parseInt(writing) + parseInt(pecticNun) + parseInt(mcq);

        results += result;
      });
      studentPh = i?.studentPh;
    });

    const result = {
      results,
      studentPh,
    };

    Totalresults.push(result);
  }

  Totalresults.sort((a, b) => b?.results - a?.results);

  const myPosition = Totalresults?.find((i) => i?.studentPh?.includes(phone));
  const myIndex = Totalresults.indexOf(myPosition);

  return {
    myIndex: myIndex,
    Totalresults: Totalresults,
  };
};
