import React from "react";
import { useState, useContext } from "react";
import examTime from "../../assets/icons/plan (1).png";
import { AnimatePresence, motion } from "framer-motion";
import { AiOutlineDelete } from "react-icons/ai";
import { format } from "date-fns";
import { useEffect } from "react";
import {
  MdDoneAll,
  MdFileDownloadDone,
  MdOutlineDownloadDone,
  MdOutlineKeyboardReturn,
  MdRemoveDone,
} from "react-icons/md";
import Loading from "../../Shared/Loading";
import CreateExam from "../Modul/CreateExam";
import { ExamContext } from "../../App";
import { BiEdit } from "react-icons/bi";
import DeleteModalConfirm from "../Modul/DeleteModalConfirm";
import UpdateExamStatus from "../Modul/updateExamStatus";
import useCountdown from "../../Hooks/useCountdown";

const ExamControl = () => {
  const [xms, xmLoading, xmRefetch, myXm, xmError] = useContext(ExamContext);
  const [design, setDesign] = useState("xm1");
  const [exams, setExams] = useState(xms);
  const [examClass, setExamClass] = useState("Six");
  const [openCreateExam, setOpenCreateExam] = useState(null);
  const [deletingModal, setDeletingModal] = useState(null);
  const [openUpdateExam, setOpenUpdateExam] = useState(null);
  const method = "exam";

  const handleState = (i, x) => {
    setExams(i);
    setDesign(x);
  };

  const exms = xms?.filter((i) => i?.examClass?.includes(examClass));

  const xmPending = xms?.filter((i) => i?.status?.includes("pending"));
  const xmRunning = xms?.filter((i) => i?.status?.includes("running"));
  const xmProcessing = xms?.filter((i) => i?.status?.includes("processing"));
  const xmComplete = xms?.filter((i) => i?.status?.includes("complete"));

  useEffect(() => {
    setExams(xmPending);
  }, [xms]);

  if (xmLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full sm:p-0 p-2">
      <div className="text-center w-full flex items-center justify-between p-3 py-4 shadow-md sm:mb-5 mb-3 rounded-md bg-white">
        <div className="">
          <label
            onClick={() => setOpenCreateExam([null, "create"])}
            htmlFor="create-Exam"
            className="btn btn-primary rounded-3xl btn-sm text-white"
          >
            Create Exam
          </label>
        </div>
        <h1 className="font-bold text-xl">
          <span className="text-accent ">{xms?.length}</span>
        </h1>
        <select
          required
          className="input input-bordered bg-transparent input-sm input-primary w-[100px]"
          onChange={(e) => setExamClass(e.target.value)}
        >
          <option value="Six" select>
            Six
          </option>
          <option value="Play">Play</option>
          <option value="Nursery">Nursery</option>
          <option value="One">One</option>
          <option value="Two">Two</option>
          <option value="Three">Three</option>
          <option value="Four">Four</option>
          <option value="Five">Five</option>
          <option value="Seven">Seven</option>
          <option value="Eight">Eight</option>
          <option value="Nine">Nine</option>
          <option value="Ten">Ten</option>
        </select>
      </div>
      <div className="w-full bg-white p-2 sm:p-3 rounded-md shadow-md">
        <div className="w-full flex items-center justify-between ">
          <div
            onClick={() => handleState(xmPending, "xm1")}
            className={`${
              design === "xm1"
                ? "bg-gradient-to-r from-[#13b38f] to-[#2091d9] duration-100 rounded-md !text-white"
                : "border bg-slate-100"
            } relative overflow-hidden cursor-pointer w-full py-2 px-0`}
          >
            <div className="text-center">
              <h1 className="text-[16px] mt-3 sm:mt-0 py-1">Pending</h1>
            </div>
            <div
              className={`${
                design === "xm1" ? "bg-secondary" : "bg-[#a09f9f]"
              } absolute -top-1.5 grid place-content-center -left-1.5 w-10 h-7 rounded-full text-white`}
            >
              <p className="text-sm mt-1">{xmPending?.length}</p>
            </div>
          </div>
          <div
            onClick={() => handleState(xmRunning, "xm2")}
            className={`${
              design === "xm2"
                ? "bg-gradient-to-r from-[#13b38f] to-[#2091d9] duration-100 rounded-md !text-white"
                : "border bg-slate-100"
            } relative overflow-hidden cursor-pointer w-full py-2 px-0`}
          >
            <div className="text-center">
              <h1 className="text-[16px] mt-3 sm:mt-0 py-1">Running</h1>
            </div>
            <div
              className={`${
                design === "xm2" ? "bg-secondary" : "bg-[#a09f9f]"
              } absolute -top-1.5 grid place-content-center -left-1.5 w-10 h-7 rounded-full text-white`}
            >
              <p className="text-sm mt-1">{xmRunning?.length}</p>
            </div>
          </div>
          <div
            onClick={() => handleState(xmProcessing, "xm3")}
            className={`${
              design === "xm3"
                ? "bg-gradient-to-r from-[#13b38f] to-[#2091d9] duration-100 rounded-md !text-white"
                : "border bg-slate-100"
            } relative overflow-hidden cursor-pointer w-full py-2 px-0`}
          >
            <div className="text-center">
              <h1 className="text-[16px] mt-3 sm:mt-0 py-1">Processing</h1>
            </div>
            <div
              className={`${
                design === "xm3" ? "bg-secondary" : "bg-[#a09f9f]"
              } absolute -top-1.5 grid place-content-center -left-1.5 w-10 h-7 rounded-full  text-white`}
            >
              <p className="text-sm mt-1">{xmProcessing?.length}</p>
            </div>
          </div>
          <div
            onClick={() => handleState(xmComplete, "xm4")}
            className={`${
              design === "xm4"
                ? "bg-gradient-to-r from-[#13b38f] to-[#2091d9] duration-100 rounded-md !text-white"
                : "border bg-slate-100"
            } relative overflow-hidden cursor-pointer w-full py-2 px-0`}
          >
            <div className="text-center">
              <h1 className="text-[16px] mt-3 sm:mt-0 py-1">Complete</h1>
            </div>
            <div
              className={`${
                design === "xm4" ? "bg-secondary" : "bg-[#a09f9f]"
              } absolute -top-1.5 grid place-content-center -left-1.5 w-10 h-7 rounded-full text-white`}
            >
              <p className="text-sm mt-1">{xmComplete?.length}</p>
            </div>
          </div>
        </div>
      </div>
      <motion.div
        layout
        className="md:mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-5 mt-3"
      >
        <AnimatePresence>
          {exams?.map((i, index) => (
            <>
              <motion.div
                layout
                key={i?._id}
                animate={{ opacity: 1, scale: 1 }}
                initial={{ opacity: 0, scale: 0 }}
                exit={{ opacity: 0, scale: 0 }}
                className={`${
                  i?.status === "pending"
                    ? "border-[#dfdddd]"
                    : "border-secondary"
                } 
                        ${
                          i?.status === "complete"
                            ? "border-[#8df3f9] "
                            : "border-secondary"
                        }  
                        p-2 md:mt-0 w-full relative overflow-hidden cursor-pointer border-[3px] border-secondary bg-white  duration-300 shadow-md rounded-2xl`}
              >
                <div className="border-b-2 py-1.5 pb-0.5 gap-2">
                  <div className="w-full flex items-center gap-2">
                    <div>
                      <img src={examTime} className="w-10" alt="pending " />
                    </div>
                    <div>
                      <h3 className="text-[18px] capitalize text-primary font-bold">
                        {i?.examName}
                      </h3>
                      <p className="text-[12px]">{i?.description}</p>
                    </div>
                  </div>
                  <div className="flex items-center mt-2 justify-between">
                    <p className="text-[12px]">
                      {format(new Date(i?.createdAt), "PP")}
                    </p>
                    <h5 className="text-right capitalize text-[#2c536e] font-bold">
                      {i?.status}...
                    </h5>
                  </div>
                  <div
                    className={`${
                      i?.status === "pending" ? "bg-[#dedede] text-primary" : ""
                    }
                                ${
                                  i?.status === "complete"
                                    ? "bg-[#8df3f9] text-secondary"
                                    : ""
                                }
                                absolute -top-1.5 grid place-content-center -right-1.5 w-[68px] h-7 rounded-full bg-secondary text-white`}
                  >
                    <p className="text-sm mt-1">{i?.examClass}</p>
                  </div>
                </div>
                <div className="flex items-end justify-between mt-1.5">
                  <div>
                    <p className="text-[16px] font-bold text-secondary">
                      Start Exam {format(new Date(i?.examDate), "PP")}
                    </p>
                  </div>
                  <div className="flex items-center">
                    {i?.status === "pending" && (
                      <label
                        onClick={() => setOpenUpdateExam([i, "running"])}
                        htmlFor="Update-Exam"
                        className="text-primary mr-2 cursor-pointer"
                      >
                        <span className="text-gray-500">
                          <MdRemoveDone size={20} />
                        </span>
                      </label>
                    )}
                    {i?.status === "running" && (
                      <label
                        onClick={() => setOpenUpdateExam([i, "processing"])}
                        htmlFor="Update-Exam"
                        className="text-primary mr-2 cursor-pointer"
                      >
                        <span className="text-gray-500">
                          <MdRemoveDone size={20} />
                        </span>
                      </label>
                    )}
                    {i?.status === "processing" && (
                      <label
                        onClick={() => setOpenUpdateExam([i, "complete"])}
                        htmlFor="Update-Exam"
                        className="text-primary mr-2 cursor-pointer"
                      >
                        <span className="text-gray-500">
                          <MdRemoveDone size={20} />
                        </span>
                      </label>
                    )}
                    {i?.status === "complete" && (
                      <div className="mr-2">
                        <span className="text-green-500">
                          <MdOutlineDownloadDone size={20} />
                        </span>
                      </div>
                    )}
                    {(i?.status === "running" ||
                      i?.status === "complete" ||
                      i?.status === "processing") && (
                      <div className="mr-2">
                        <span className="text-green-500">
                          <MdDoneAll size={20} />
                        </span>
                      </div>
                    )}
                    {i?.status === "processing" && (
                      <div className="mr-2">
                        <span className="text-green-500">
                          <MdDoneAll size={20} />
                        </span>
                      </div>
                    )}
                    <label
                      onClick={() => setOpenCreateExam([i, "update"])}
                      htmlFor="create-Exam"
                      className="text-primary cursor-pointer"
                    >
                      <BiEdit size={20} />
                    </label>
                    {(i?.status === "pending" || i?.status === "complete") && (
                      <label
                        onClick={() => setDeletingModal(i)}
                        htmlFor="delete-confirm-modal"
                        className="text-accent ml-2 cursor-pointer"
                      >
                        <AiOutlineDelete size={20} />
                      </label>
                    )}
                  </div>
                </div>
              </motion.div>
            </>
          ))}
        </AnimatePresence>
      </motion.div>
      {openCreateExam && (
        <CreateExam
          openCreateExam={openCreateExam}
          refetch={xmRefetch}
          setOpenCreateExam={setOpenCreateExam}
        />
      )}
      {openUpdateExam && (
        <UpdateExamStatus
          openUpdateExam={openUpdateExam}
          refetch={xmRefetch}
          setOpenUpdateExam={setOpenUpdateExam}
          method={method}
        />
      )}
      {deletingModal && (
        <DeleteModalConfirm
          deletingModal={deletingModal}
          setDeletingModal={setDeletingModal}
          refetch={xmRefetch}
          method={method}
        />
      )}
    </div>
  );
};

export default ExamControl;
