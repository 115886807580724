import React from 'react';
import { useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { MdRemoveDone } from 'react-icons/md';

const ApplyUserCard = ({user, index, setDeletingModal, setAddModal}) => {
    const [roles, setRole] = useState('')
    const {firstName, lastName, phoneNumber, role, subject, address, gender, age, gpa,} = user;
    const classe = user.class;

    const data = {
         roleNo: roles,
         status: "active"
        }
 
    return (
        <div className='rounded-xl relative overflow-hidden bg-[#f3f9fb] border p-3 border-[#eddeff]'>
            <div className="flex items-end justify-between">
                <div className='flex items-center gap-x-2'>
                    <h1 className='font-bold'>{index + 1 }</h1>
                    <div className='w-10 h-10 grid place-content-center rounded-full bg-[#7e16a0] ring ring-[#d583f5] ring-offset-base-100 ring-offset-1'>
                        <h2 className='text-white font-bold'>{firstName?.slice(0, 1)}{lastName?.slice(0, 1)}</h2>
                    </div>
                    <div>
                        <h1 className='flex font-bold items-center'>{firstName} {lastName}</h1>
                        <h1 className='flex text-sm items-center'>{address}</h1>
                    </div>
                </div>
                { user?.class &&
                <div>
                    <h1 className='flex items-center'>class: {user?.class}</h1>
                </div>
                }
                <div className='absolute -top-1.5 grid place-content-center -right-1.5 w-20 h-8 rounded-full bg-[#eddeff]  text-purple-700'>
                    <p className='text-sm font-bold mt-1'>{role}</p>
                </div>
            </div>
            <div className="flex border-b text-xs mt-2 pb-1 border-[#eddeff] items-center justify-between">
                <h3>Gender: {gender}</h3>
                <h3>Age: {age}</h3>
            </div>
            <div className="flex items-center mt-2 justify-between">
                <div className='flex items-center gap-x-2'>
                    <div className="">
                        <a className='block' href={`tel:${phoneNumber}`} ><button className="bg-[#eddeff] text-purple-700 rounded-md px-1">{phoneNumber}</button></a>
                    </div>
                    { (role == 'student') && <div className="">
                        <input type="text" required value={roles} onChange={e => setRole(e.target.value)} className='bg-purple-50 border border-purple-200 text-sm w-16 p-0.5 rounded-md outline-none' placeholder='Role No' />
                    </div>}
                </div>
                <div className="card-actions flex justify-end">
                    <label onClick={() => setDeletingModal(user)} htmlFor="delete-confirm-modal" className="text-accent cursor-pointer"><AiFillDelete size={22} /></label>
                    <label onClick={() => setAddModal([user, data])} htmlFor="add-confirm-modal" className="text-purple-700 cursor-pointer font-bold btn btn-xs btn-ghost" disabled={!roles && (user?.role === "student") }><MdRemoveDone size={22} /></label>
                </div>
            </div>
        </div>
    );
};

export default ApplyUserCard;