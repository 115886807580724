import React, { useState } from 'react';
import { toast} from 'react-toastify';
import { ProgressBar } from 'react-loader-spinner';
import {AiOutlineSchedule, AiOutlineSend} from "react-icons/ai"
import axios from "../../Utils/Axios.config";
import { useContext } from 'react';
import { userContext } from '../../App';


const LateAttendance = ({setLate, late, refetch, id}) => {
    const [disable, setDisable] = useState(false);
    const [error, setError] = useState();
    const [users, userLoading, userRefetch, userError] = useContext(userContext);

    const userNm = (Phone) => {
        const user = users?.find(i => i?.phoneNumber?.includes(Phone));
        return user
    }


    const handleQuickSms = () => {
        setDisable(true)

        axios.patch(`/attendance/late/${id}`, late)
        .then( (response) => {
            setDisable(false)
            setLate(null);
            refetch()
            toast.success("Created the Late Attendance");
            console.log(response)
        } )
        .catch( (error) => {
            setDisable(false)
            toast.error("Ops No..! Some thing is Wrong");
            console.log(error)
        })
       
    }


    return (
        <div>
            <input type="checkbox" id="late-attendance" className="modal-toggle" />
            <div className="modal h-screen modal-bottom sm:modal-middle">
                <div className="modal-box pb-8 mb-10 bg-blue-100 h-auto px-2 py-4 sm:py-8 sm:px-4">
                    <div className='text-xl text-purple-700 text-center'>
                       <div className='w-full flex justify-center'>
                            <AiOutlineSchedule size={44} />
                       </div>

                       <div className="my-4 text-black">
                           <p className='ext-xl font-bold'>Are You sure Late Attendance submit for</p>
                           <h3 className='text-xl text-secondary font-bold'>{userNm(late?.phoneNumber)?.firstName} {userNm(late?.phoneNumber)?.lastName}</h3>
                           <p>Class {userNm(late?.phoneNumber)?.class}</p>
                           <p>Role No: {userNm(late?.phoneNumber)?.roleNo}</p>
                        </div>
                    
                    </div>
                    <div className='p-4 mt-5 shadow-md rounded-md bg-white'>
                        { disable &&

                            <div className='w-full flex items-center justify-center'>
                                <ProgressBar
                                    height="80"
                                    width="80"
                                    ariaLabel="progress-bar-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="progress-bar-wrapper"
                                    borderColor = '#F4442E'
                                    barColor = '#51E5FF'
                                />
                            </div>

                        }
                        <p className='text-sm text-accent mb-2 text-center'>{error}</p>
                        <div className='flex items-center justify-center gap-x-3'>
                            <button onClick={handleQuickSms} disabled={disable} className="btn w-[100px] btn-primary text-white btn-sm">Submit</button>
                            <label htmlFor="late-attendance" className="btn btn-sm w-[100px]">Cancel</label>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    );
};

export default LateAttendance;

// disabled={ ((new Date(ClassAttendance?.updatedAt).toLocaleDateString() === date.toLocaleDateString()) || disable || (confirmAttendance?.newArr?.length <= 0) )}