import React from "react";
import { MdOutlineSchool } from "react-icons/md";
import { userContext } from "../../App";
import { useContext } from "react";
import logo from "../../assets/images/log03.png";
import xmR from "../../assets/images/xm (1).jpg";
import pen from "../../assets/images/pen.png";
import { useState } from "react";
import { useEffect } from "react";
import {
  MiddleSub,
  jscSub,
  primarySub,
  secondarySub,
  sscSub,
} from "../../data/subjectData";
import { calculateGreat } from "../../Utils/CalculateFunction";

const AllResultCard = ({ xm, classe, xms, data }) => {
  const [users] = useContext(userContext);
  const [xmSub, setXmSub] = useState([...sscSub]);

  // Subject select
  useEffect(() => {
    if (
      classe === "Play" ||
      classe === "Nursery" ||
      classe === "One" ||
      classe === "Two"
    ) {
      setXmSub(primarySub);
    } else if (classe === "Three" || classe === "Four" || classe === "Five") {
      setXmSub(secondarySub);
    } else if (classe === "Six" || classe === "Seven") {
      setXmSub(MiddleSub);
    } else if (classe === "Eight") {
      setXmSub(jscSub);
    } else if (classe === "Ten" || classe === "Nine") {
      setXmSub([...sscSub]);
    }
  }, [classe]);

  const MyXm = xms?.find((i) => i?.examName?.includes(xm));
  const classXm = MyXm?.Results?.filter((i) => i?.class === classe);
  const GenderXm = classXm?.filter((x) => {
    const stXmUser = data?.find((i) => i?.phoneNumber === x?.studentPh);
    if (stXmUser) {
      return x;
    }
  });

  function groupBy(objectArray, property) {
    return objectArray?.reduce((acc, obj) => {
      const key = obj[property];
      const curGroup = acc[key] ?? [];

      return { ...acc, [key]: [...curGroup, obj] };
    }, {});
  }

  const groupedPeople = groupBy(GenderXm, "studentPh");
  const Totalresults = [];
  const GpaResults = [];

  for (const property in groupedPeople) {
    let results = 0;
    let resultsGpa = [];
    let studentPh;

    groupedPeople[property]?.map((i) => {
      const sub = i?.subResult;
      sub?.map((x) => {
        const writing = x?.writing ? x?.writing : 0;
        const mcq = x?.mcq ? x?.mcq : 0;
        const pecticNun = x?.pecticNun ? x?.pecticNun : 0;
        const result = parseInt(writing) + parseInt(pecticNun) + parseInt(mcq);

        results += result;
        resultsGpa.push({ mark: result, subMa: x });
      });
      studentPh = i?.studentPh;
    });

    const result = {
      results,
      studentPh,
    };
    const GParesult = {
      resultsGpa,
      studentPh,
    };

    Totalresults.push(result);
    GpaResults.push(GParesult);
  }

  Totalresults.sort((a, b) => b?.results - a?.results);

  //   Find the Gap and great points
  const Gpa = (ph) => {
    const gpaFind = GpaResults.find((i) => i.studentPh === ph);
    return gpaFind;
  };

  // console.log(Totalresults, "hello");
  function calculateGPA(marks) {
    const gradePoints = marks?.map((x) => {
      const crnSub = xmSub?.find((i) => i?.name === x?.subMa?.subject);
      if (crnSub?.name === "Higher Math" || crnSub?.name === "Agricultural") {
        const optionalSub =
          x?.mark >= 80
            ? 3
            : x?.mark >= 70
            ? 2
            : x?.mark >= 60
            ? 1.5
            : x?.mark >= 50
            ? 1
            : 0;
        return optionalSub;
      } else if (x?.mark >= Math.ceil((crnSub?.maxNum / 100) * 80)) {
        return 5;
      } else if (x?.mark >= Math.ceil((crnSub?.maxNum / 100) * 70)) {
        return 4;
      } else if (x?.mark >= Math.ceil((crnSub?.maxNum / 100) * 60)) {
        return 3.5;
      } else if (x?.mark >= Math.ceil((crnSub?.maxNum / 100) * 50)) {
        return 3;
      } else if (x?.mark >= Math.ceil((crnSub?.maxNum / 100) * 40)) {
        return 2;
      } else if (x?.mark >= Math.ceil((crnSub?.maxNum / 100) * 33)) {
        return 1;
      } else {
        return 0;
      }
    });

    const great = (gpa) => {
      if (gpa >= 5) {
        return "A+";
      } else if (gpa >= 4) {
        return "A";
      } else if (gpa >= 3.5) {
        return "A-";
      } else if (gpa >= 3) {
        return "B";
      } else if (gpa >= 2) {
        return "C";
      } else if (gpa >= 1) {
        return "D";
      } else {
        return "F";
      }
    };

    const totalGradePoints = gradePoints.reduce((acc, curr) => acc + curr, 0);
    const subLength =
      classe === "Ten" || classe === "Nine" ? xmSub?.length - 2 : xmSub?.length;
    const gpa = totalGradePoints / subLength;

    return [gpa.toFixed(2), great(gpa)];
  }

  const userNm = (Phone) => {
    const user = users?.find((i) => i?.phoneNumber?.includes(Phone));
    return user;
  };

  const findMyXm = (phone) => {
    const myResult = MyXm?.Results?.find((i) => i?.studentPh === phone);
    console.log(myResult);
    return myResult;
  };

  return (
    <div className="font-reem">
      <div className="flex items-center justify-between">
        <div className="flex gap-x-3 items-center">
          <div className="">
            <img src={logo} className="w-[110px] mx-auto" alt="logo" />
          </div>
          <div>
            <h1 className="text-4xl text-purple-700 font-bold">
              Nabojagoron Academy
            </h1>
            <h3 className="text-[24px] text-purple-600 mt-2">
              Nanni Bazar, Nalitabari, Sherpur.
            </h3>
          </div>
        </div>
        <div className="">
          <img src={xmR} className="w-[120px] mx-auto" alt="logo" />
        </div>
      </div>
      <div className="w-full mb-3 -mt-1 flex items-center">
        <div className="h-[2px] w-[50%] bg-[#da9ffb]"></div>
        <div className="font-reem w-full text-center text-purple-700 bg-[#f1e5fb] !border-[#da9ffb] font-bold px-2 py-0.5 capitalize text-[24px] rounded-[20px] border-[2px]">
          Class: {classe} Results - {new Date().getFullYear()}
        </div>
        <div className="h-[2px] w-[50%] bg-[#da9ffb]"></div>
      </div>

      <table className="w-full text-center">
        <thead>
          <tr className="font-reem text-md border bg-[#f1e5fb] !border-[#da9ffb] text-purple-700">
            <th className="!border-[#da9ffb] pl-2 border">S-No</th>
            <th className="!border-[#da9ffb] pl-2 border">Name</th>
            <th className="!border-[#da9ffb] pl-2 border">Marks</th>
            <th className="!border-[#da9ffb] pl-2 border">GPA</th>
            <th className="!border-[#da9ffb] pl-2 border">Grate</th>
          </tr>
        </thead>
        <tbody>
          {Totalresults?.map((i, index) => (
            <tr
              key={index}
              className={`${
                index % 2 !== 0 ? "bg-[#f4f1f8] text-[#602c91]" : "bg-[#f3f9fb]"
              }`}
            >
              <td className="border pl-2 !border-[#f0d2fc]">{index + 1}</td>
              <td className="border pl-2 flex justify-center">
                {userNm(i?.studentPh)?.firstName}{" "}
                {userNm(i?.studentPh)?.lastName}{" "}
                <span className="font-bold ml-2 text-purple-700">
                  <MdOutlineSchool size={18} />
                </span>
              </td>
              <td className="border pl-2 !border-[#f0d2fc]">{i?.results}</td>
              <td className="border pl-2 !border-[#f0d2fc]">
                {calculateGPA(Gpa(i?.studentPh).resultsGpa)[0]}
              </td>
              <td className="border pl-2 !border-[#f0d2fc]">
                {calculateGPA(Gpa(i?.studentPh).resultsGpa)[1]}
                {/* calculateGreat(findMyXm(i?.studentPh), xmSub)?.status ===
                "FAIL"
                  ? "F"
                  : */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="w-full text-purple-800 flex mt-4 justify-between">
        <div className="text-center font-reem text-sm w-[180px]">
          <h3 className="border-b pb-1 font-bold border-[#8b8b8b]">
            <img
              src="https://fontmeme.com/permalink/230520/ae1df4b56eda0344305a0437b6fe4a89.png"
              alt="signature-fonts"
              className="w-[150px] text-center -mb-1 mx-auto"
            />
          </h3>
          <h3 className="font-bold pt-1">( Exam Controller )</h3>
        </div>
        <div className="w-18 -mt-2 h-14">
          <img src={pen} alt="pne" className="w-18 h-14" />
        </div>
        <div className="text-center font-reem text-sm w-[130px]">
          <h3 className="border-b pb-1 text-center font-bold border-[#8b8b8b]">
            <img
              src="https://fontmeme.com/permalink/230520/e1e16894902aeb97b29bf4b97d6f5ae8.png"
              alt="signature-fonts"
              className="w-[100px] -mb-2 text-center mx-auto"
            />
          </h3>
          <h3 className="font-bold pt-1">( Head Teacher )</h3>
        </div>
      </div>
    </div>
  );
};

export default AllResultCard;
