import React, { useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import XmReport from "../Components/AnimateButtons/XmReport";
import SelectStudent from "../Components/AnimateButtons/SelectStudent";
import { useState } from "react";
import { ExamContext, MeContext, attendanceContext, userContext } from "../App";
import { useContext } from "react";

const PrintButton = () => {
  const componentRef = useRef();
  const [Attendances] = useContext(attendanceContext);
  const [me] = useContext(MeContext);
  const [users] = useContext(userContext);
  const [xms] = useContext(ExamContext);
  const students = users?.filter((i) => i?.role?.includes("student"));
  const compExam = xms?.filter((i) => i?.status?.includes("complete"));
  const porsXm = xms?.filter((i) => i?.status?.includes("processing"));
  const [compXm, setCompXm] = useState(compExam);
  const [prosActive, setProsActive] = useState(false);
  const [xm, setXm] = useState(
    compXm !== undefined ? compXm[0]?.examName : "Text-Exam"
  );
  const [classe, setClasse] = useState(me?.class ? me?.class : "Six");

  const studentValue = students?.filter((i) => i?.class === classe);
  const sorted = studentValue?.sort(function (a, b) {
    return a.roleNo - b.roleNo;
  });

  const [data, setData] = useState(sorted);
  const MyXm = xms?.find((i) => i?.examName === xm);
  const classXm = MyXm?.Results?.filter((i) => i?.class === classe);
  const GenderXm = classXm?.filter((x) => {
    const stXmUser = data?.find((i) => i?.phoneNumber === x?.studentPh);
    if (stXmUser) {
      return x;
    }
  });

  // ========= Role ===========
  const handleRole = (role) => {
    const student = data?.find((i) => i?.roleNo?.includes(role));
    setRole(student);
  };

  const student = data?.find((i) => i?.roleNo?.includes("01"));
  const [Role, setRole] = useState(student);

  useEffect(() => {
    if (me?.role === "student") {
      setRole(me);
    }
    setXm(compXm !== undefined ? compXm[0]?.examName : "Text-Exam");
  }, [me, compXm]);

  // ============ find Attendance  =================
  const attendance = Attendances?.find((i) => {
    if (
      Role?.gender === "male" &&
      (Role?.class === "Seven" ||
        Role?.class === "Eight" ||
        Role?.class === "Nine" ||
        Role?.class === "Ten")
    ) {
      return i?.AttendanceName === `${Role?.class}Boys`; // using template literal to concatenate the class name with "Boys"
    } else if (
      Role?.gender === "female" &&
      (Role?.class === "Seven" ||
        Role?.class === "Eight" ||
        Role?.class === "Nine" ||
        Role?.class === "Ten")
    ) {
      return i?.AttendanceName === `${Role?.class}Girls`; // using template literal to concatenate the class name with "Girls"
    } else {
      return i?.AttendanceName === Role?.class;
    }
  });

  let myAttendance = [];

  attendance?.Attendance?.map((i) => {
    i.newArr.map((dates) => {
      if (dates.phoneNumber === Role?.phoneNumber) {
        myAttendance.push(dates);
      }
    });
  });

  const present = myAttendance?.filter((x) => x?.status?.includes("P"));

  const handleActive = () => {
    if (!prosActive) {
      setProsActive(true);
      setCompXm(porsXm);
    } else {
      setCompXm(compExam);
      setProsActive(false);
    }
  };

  return (
    <div>
      <div className="px-2 my-3 sm:px-0">
        <div className="mb-3 text-purple-700 w-full bg-white p-3 rounded-xl">
          <div>
            <SelectStudent
              me={me}
              compXm={compXm}
              setClasse={setClasse}
              setXm={setXm}
              xm={xm}
              classe={classe}
              role={Role}
              students={students}
              setData={setData}
              data={data}
              handleRole={handleRole}
            />
          </div>
          <div className="flex justify-between pt-2 items-center gap-x-2">
            <div className="flex items-center">
              <h2 className="font-bold text-xl font-reem">{xm}</h2>
              {me?.role === "admin" && (
                <input
                  type="checkbox"
                  onClick={handleActive}
                  className="toggle toggle-xs ml-1 toggle-secondary"
                  checked={prosActive}
                />
              )}
            </div>
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-sm border-purple-700 bg-purple-700 text-white">
                  Download / Print
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </div>
      <div className="w-full mb-4 overflow-x-auto">
        <div
          ref={componentRef}
          className="p-3 min-w-[780px] rounded-md h-full w-full bg-white"
        >
          <div className="p-3 border water-mark rounded-md">
            <XmReport
              me={Role}
              xm={xm}
              MyXm={MyXm}
              porsXm={porsXm}
              setCompXm={setCompXm}
              compExam={compExam}
              myAttendance={myAttendance}
              present={present}
              GenderXm={GenderXm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const PrintDownload = () => {
  return (
    <div>
      <PrintButton />
    </div>
  );
};

export default PrintDownload;
