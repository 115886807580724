import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from "../../Utils/Axios.config"
import { BiHide, BiShow } from 'react-icons/bi';
import { toast } from 'react-toastify';
import resetPass from '../../assets/icons/reset-password.png'
import { ProgressBar } from 'react-loader-spinner';


const UpdatePass = ({setUpdatePassModal, refetch, updatePassModal}) => {
    const { register, reset, formState: { errors }, handleSubmit } = useForm();
    const [showOldPass, setshowOldPass] = useState(false);
    const [showNewPass, setshowNewPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const [oldPassType, setOldPassType] = useState("password");
    const [newPassType, setNewPassType] = useState("password");
    const [error, setError] = useState("");

    const handleShowPass = (old) => {
        if(old === "oldPass"){
            setshowOldPass(!showOldPass);
            setOldPassType(oldPassType === "password"? "text" : "password")
        }else{
            setshowNewPass(!showNewPass);
            setNewPassType(newPassType === "password"? "text" : "password")
        }
        
    }

    const onSubmit = (data) => {
        setLoading(true)
        setError('')
        const PassData = {
          oldPass: data.OldPass,
          newPass : data.NewPass,
        }   

        axios.patch(`/user/changePass/${updatePassModal?._id}`, PassData)
        .then((response) => {
            toast.success("successfully Change the user")
            refetch();
            setLoading(false)
            setUpdatePassModal(null)
        })
        .catch((error) => {
            if(error?.response?.data?.error){
                toast.error(error?.response?.data?.error)
                setError(error?.response?.data?.error)
            }else{
                toast.error("Ops No..!! Something is wrong")
            }
            setLoading(false)
        });

    }
    
    return (
        <div>
            <input type="checkbox" id="update-password" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <form onSubmit={handleSubmit(onSubmit)} className="modal-box mb-12 pb-8 sm:pb-5 p-3 py-5 sm:p-4">
                    <div className='w-full text-center'>
                        <img src={resetPass} className='w-20 mx-auto' alt="create" />
                        <h2 className='text-xl uppercase text-center font-bold text-primary'>Change Password</h2>
                    </div>
                    <div className='w-full mt-3'>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text text-cyan-900 font-bold">Old Password</span>
                            </label>
                            <div className='relative'>
                            <input 
                                type={oldPassType} 
                                placeholder="Old Password" 
                                className="input input-bordered !py-4 sm:!py-6 !rounded-md input-sm sm:input-md input-primary w-full" 
                                {...register("OldPass", {
                                    required: {
                                        value: true,
                                        message: 'Old Password is required'  
                                    },
                                    minLength: {
                                        value: 6,
                                        message: 'Must be 6 characters longer'
                                    }
                                })}
                            />
                            <div onClick={() => handleShowPass('oldPass')} className={`${showOldPass? "text-primary" : "text-gray-400 "} cursor-pointer  absolute top-[5px] sm:top-[15px] right-1 sm:right-2`}>
                                {showOldPass? <BiShow size={24} /> : <BiHide size={24} />}
                            </div>
                            </div>
                            {errors?.OldPass && 
                                <label className="label p-0 pt-1">
                                    {errors.OldPass?.type === 'required' && <span className="label-text-alt text-red-500">{errors.OldPass.message}</span>}
                                    {errors.OldPass?.type === 'minLength' && <span className="label-text-alt text-red-500">{errors.OldPass.message}</span>}
                                </label>
                            }
                        </div>

                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text text-cyan-900 font-bold">New Password</span>
                            </label>
                            <div className='relative'>
                            <input 
                                type={newPassType} 
                                placeholder="New Password" 
                                className="input input-bordered !py-4 sm:!py-6 !rounded-md input-sm sm:input-md input-primary w-full" 
                                {...register("NewPass", {
                                    required: {
                                        value: true,
                                        message: 'New Password is required'  
                                    },
                                    minLength: {
                                        value: 6,
                                        message: 'Must be 6 characters longer'
                                    }
                                })}
                            />
                            <div onClick={() => handleShowPass("newPass")} className={`${showNewPass? "text-primary" : "text-gray-400 "} cursor-pointer  absolute top-[5px] sm:top-[15px] right-1 sm:right-2`}>
                                {showNewPass? <BiShow size={24} /> : <BiHide size={24} />}
                            </div>
                            </div>
                            {errors?.NewPass && 
                                <label className="label p-0 pt-1">
                                    {errors.NewPass?.type === 'required' && <span className="label-text-alt text-red-500">{errors.NewPass.message}</span>}
                                    {errors.NewPass?.type === 'minLength' && <span className="label-text-alt text-red-500">{errors.NewPass.message}</span>}
                                </label>
                            }
                        </div>
                    </div>
                    
                    <div className=" gap-3 mt-5">
                        { loading &&
                            <div className='w-full flex items-center justify-center'>
                                <ProgressBar
                                height="80"
                                width="80"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{}}
                                wrapperClass="progress-bar-wrapper"
                                borderColor = '#F4442E'
                                barColor = '#51E5FF'
                                />
                            </div>
                        }
                        <p className='text-center text-sm text-accent mb-2'>{error}</p>
                        <div className='flex items-center justify-center gap-x-3'>
                            <input type="submit" value="Save" className="btn w-[100px] btn-primary text-white btn-sm" />
                            <label for="update-password" className="btn btn-sm w-[100px] ">cancel</label>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UpdatePass;