import React, { useContext, useState } from "react";
import { MdOutlineSchool } from "react-icons/md";
import { ExamContext, MeContext, userContext } from "../../App";
import AllResultCard from "../../Components/AnimateButtons/AllResultCard";
import { allClassData } from "../../data/classData";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import { useEffect } from "react";

const AllResults = () => {
  const componentRef = useRef();
  const [users] = useContext(userContext);
  const [me, meLoading] = useContext(MeContext);
  const [xms] = useContext(ExamContext);
  const students = users?.filter((i) => i?.role?.includes("student"));
  const compExam = xms?.filter((i) => i?.status?.includes("complete"));
  const porsXm = xms?.filter((i) => i?.status?.includes("processing"));
  const [compXm, setCompXm] = useState(compExam);
  const [prosActive, setProsActive] = useState(false);
  const [xm, setXm] = useState(
    compXm !== undefined ? compXm[0]?.examName : "Text-Exam"
  );

  useEffect(() => {
    setXm(compXm !== undefined ? compXm[0]?.examName : "Text-Exam");
  }, [compXm]);

  // set class function
  const [classe, setClasse] = useState("Play");
  const studentValue = students?.filter((i) => i?.class?.includes(classe));
  const sorted = studentValue?.sort(function (a, b) {
    return a.roleNo - b.roleNo;
  });
  const [data, setData] = useState(sorted);
  const handleClass = (classData) => {
    if (
      classData === "Eight-Boys" ||
      classData === "Eight-Girls" ||
      classData === "Nine-Boys" ||
      classData === "Nine-Girls" ||
      classData === "Ten-Boys" ||
      classData === "Ten-Girls"
    ) {
      const studentValue = students?.filter(
        (i) => i?.class === classData?.split("-")[0]
      );

      const st = studentValue?.filter((i) => {
        if (classData?.split("-")[1] === "Boys") {
          return i?.gender === "male";
        } else {
          return i?.gender === "female";
        }
      });
      const sorted = st?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      setData(sorted);
      setClasse(classData?.split("-")[0]);
    } else if (classData === "Seven-Boys" || classData === "Seven-Girls") {
      const studentValue = students?.filter((i) => i?.class?.includes("Seven"));
      const sorted = studentValue?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      setData(sorted);
      setClasse("Seven");
    } else {
      const studentValue = students?.filter((i) =>
        i?.class?.includes(classData)
      );
      const sorted = studentValue?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      setData(sorted);
      setClasse(classData);
    }
  };

  const handleActive = () => {
    if (prosActive === false) {
      setProsActive(true);
      setCompXm(porsXm);
    } else if (prosActive === true) {
      setCompXm(compExam);
      setProsActive(false);
    }
  };

  return (
    <div>
      <input type="checkbox" id="All-result" className="modal-toggle" />
      <div className="modal h-screen modal-middle">
        <div className="modal-box w-[95%] max-w-4xl h-auto p-2">
          <div className="p-3 rounded-xl bg-[#f7f0ff] gap-x-5">
            <div className="flex gap-x-3 item-center justify-between">
              <div className="w-full">
                <select
                  onChange={(e) => setXm(e.target.value)}
                  className="select sm:select-md select-sm select-bordered !border-[#c9a2f9] px-1 select-[#c9a2f9] bg-[#faf9fd] rounded-md w-full"
                >
                  {compXm?.map((item, index) => (
                    <option
                      key={index}
                      value={item?.examName.toString()}
                      select
                    >
                      {item?.examName.toString()}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-[130px]">
                <select
                  onChange={(e) => handleClass(e.target.value)}
                  className="select sm:select-md select-sm select-bordered !border-[#c9a2f9] !px-2 select-[#c9a2f9] bg-[#faf9fd] rounded-md w-full"
                >
                  {allClassData?.map((item, index) => (
                    <option key={index} value={item?.className} select>
                      {item?.className}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex mt-3 justify-between items-center">
              <label htmlFor="All-result" className="btn btn-sm">
                Cancel
              </label>
              {me?.role === "admin" && (
                <input
                  type="checkbox"
                  onClick={handleActive}
                  className="toggle toggle-sm ml-1 toggle-secondary"
                  checked={prosActive}
                />
              )}
              <ReactToPrint
                trigger={() => (
                  <button className="btn btn-sm border-purple-700 bg-purple-700 text-white">
                    Download / Print
                  </button>
                )}
                content={() => componentRef.current}
              />
            </div>
          </div>

          <div className="w-full mb-4 overflow-x-auto">
            <div
              ref={componentRef}
              className="p-4 min-w-[780px] rounded-md h-full w-full bg-white"
            >
              <div
                className={`${
                  data?.length < 29 ? "water-mark" : ""
                } p-3 border rounded-md border-[#f2e7fc]`}
              >
                <AllResultCard xm={xm} classe={classe} xms={xms} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllResults;
