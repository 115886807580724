import React, { useContext, useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdOutlineSchool } from "react-icons/md";
import { ExamContext, MeContext, userContext } from "../../App";
import Loading from "../../Shared/Loading";
import { Link } from "react-router-dom";
import { allClassData } from "../../data/classData";
import { handleClassData } from "../../Utils/handleClassData";

const LeardBoard = ({ setLeaderBoardInfo, setLeaderBoardPrint }) => {
  const [me, meLoading] = useContext(MeContext);
  const [users] = useContext(userContext);
  const students = users?.filter((i) => i?.role?.includes("student"));
  const [xms] = useContext(ExamContext);
  const compExam = xms?.filter((i) => i?.status?.includes("complete"));
  const porsXm = xms?.filter((i) => i?.status?.includes("processing"));
  const [compXm, setCompXm] = useState(compExam);
  const [prosActive, setProsActive] = useState(false);
  const [xm, setXm] = useState(
    compXm !== undefined ? compXm[0]?.examName : "Text-Exam"
  );

  useEffect(() => {
    setXm(compXm !== undefined ? compXm[0]?.examName : "Text-Exam");
  }, [compXm]);

  // set class function
  const [classe, setClasse] = useState(me?.class ? me?.class : "Play");
  useEffect(() => {
    if (me?.role === "student") {
      handleClassData(me, setData, students);
    }
  }, [me]);
  const studentValue = students?.filter((i) => i?.class?.includes(classe));
  const sorted = studentValue?.sort(function (a, b) {
    return a.roleNo - b.roleNo;
  });
  const [data, setData] = useState(sorted);
  const handleClass = (classData) => {
    if (
      classData === "Eight-Boys" ||
      classData === "Eight-Girls" ||
      classData === "Nine-Boys" ||
      classData === "Nine-Girls" ||
      classData === "Ten-Boys" ||
      classData === "Ten-Girls"
    ) {
      const studentValue = students?.filter(
        (i) => i?.class === classData?.split("-")[0]
      );

      const st = studentValue?.filter((i) => {
        if (classData?.split("-")[1] === "Boys") {
          return i?.gender === "male";
        } else {
          return i?.gender === "female";
        }
      });
      const sorted = st?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      setData(sorted);
      setClasse(classData?.split("-")[0]);
    } else if (classData === "Seven-Boys" || classData === "Seven-Girls") {
      const studentValue = students?.filter((i) => i?.class?.includes("Seven"));
      const sorted = studentValue?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      setData(sorted);
      setClasse("Seven");
    } else {
      const studentValue = students?.filter((i) =>
        i?.class?.includes(classData)
      );
      const sorted = studentValue?.sort(function (a, b) {
        return a.roleNo - b.roleNo;
      });
      setData(sorted);
      setClasse(classData);
    }
  };

  const MyXm = xms?.find((i) => i?.examName === xm);
  const classXm = MyXm?.Results?.filter((i) => i?.class === classe);
  const GenderXm = classXm?.filter((x) => {
    const stXmUser = data?.find((i) => i?.phoneNumber === x?.studentPh);
    if (stXmUser) {
      return x;
    }
  });

  function groupBy(objectArray, property) {
    return objectArray?.reduce((acc, obj) => {
      const key = obj[property];
      const curGroup = acc[key] ?? [];

      return { ...acc, [key]: [...curGroup, obj] };
    }, {});
  }

  const groupedPeople = groupBy(GenderXm, "studentPh");

  const Totalresults = [];

  for (const property in groupedPeople) {
    let results = 0;
    let studentPh;

    groupedPeople[property]?.map((i) => {
      const sub = i?.subResult;

      sub?.map((x) => {
        const writing = x?.writing ? x?.writing : 0;
        const mcq = x?.mcq ? x?.mcq : 0;
        const pecticNun = x?.pecticNun ? x?.pecticNun : 0;
        const result = parseInt(writing) + parseInt(pecticNun) + parseInt(mcq);

        results += result;
      });
      studentPh = i?.studentPh;
    });

    const result = {
      results,
      studentPh,
    };

    Totalresults.push(result);
  }

  Totalresults.sort((a, b) => b?.results - a?.results);

  const myPosition = Totalresults?.find((i) =>
    i?.studentPh?.includes(me?.phoneNumber)
  );
  const myIndex = Totalresults.indexOf(myPosition);

  const userNm = (Phone) => {
    const user = users?.find((i) => i?.phoneNumber?.includes(Phone));
    return user;
  };

  const handleActive = () => {
    if (prosActive === false) {
      setProsActive(true);
      setCompXm(porsXm);
    } else if (prosActive === true) {
      setCompXm(compExam);
      setProsActive(false);
    }
  };

  return (
    <div className="full">
      {(me?.role === "admin" || me?.role === "teacher") && (
        <div className="my-4 w-full max-w-[500px] mx-auto p-4 bg-[#fffcff] rounded-xl">
          <Link
            to="/dashboard/result-card"
            className="btn capitalize rounded-3xl py-1 border-[3px] border-purple-500 w-full text-lg font-reem bg-purple-700 text-white"
          >
            Result Download / Print
          </Link>
        </div>
      )}
      <div
        className={` ${
          !(me?.role === "student") ? "block" : "flex"
        } mb-3 items-center justify-between`}
      >
        <h3
          className={`${
            !(me?.role === "student") ? "mb-3" : ""
          } text-purple-700 font-bold text-2xl`}
        >
          {xm}
        </h3>
        <div className="flex gap-x-2 item-center justify-between">
          {compXm?.length > 0 && (
            <div className="w-full">
              <select
                onChange={(e) => setXm(e.target.value)}
                className="select sm:select-md select-sm select-bordered !border-[#c9a2f9] !px-2 select-[#c9a2f9] bg-[#faf9fd] rounded-md w-full"
              >
                {compXm?.map((item, index) => (
                  <option key={index} value={item?.examName.toString()} select>
                    {item?.examName.toString()}
                  </option>
                ))}
              </select>
            </div>
          )}
          {!(me?.role === "student") && (
            <div className="w-[150px]">
              <select
                onChange={(e) => handleClass(e.target.value)}
                className="select sm:select-md select-sm select-bordered !border-[#c9a2f9] !px-2 select-[#c9a2f9] bg-[#faf9fd] rounded-md w-full"
              >
                {allClassData?.map((item, index) => (
                  <option key={index} value={item?.className} select>
                    {item?.className}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>
      {me?.role === "student" && Totalresults?.length > 0 && (
        <div className="p-3 bg-[#e3f5ff] border-[#c7f7fe] border rounded-xl">
          <h1 className="text-secondary mb-3 mt-1 font-reem font-bold text-[24px]">
            My Position {myIndex + 1}
          </h1>

          <div className="p-2 py-4 mb-3 px-3 flex items-center justify-between bg-[#d2f8fb] border-[#b1f3fc] border rounded-xl">
            <div className="flex items-center gap-x-2">
              <h1 className="font-bold">{myIndex + 1}</h1>
              <div className="w-8 h-8 grid place-content-center rounded-full bg-[#16a08b] ring ring-[#83def5] ring-offset-base-100 ring-offset-1">
                {!userNm(myPosition?.studentPh)?.imageURL && (
                  <h2 className="text-white font-bold">
                    {userNm(myPosition?.studentPh)?.firstName?.slice(0, 1)}
                    {userNm(myPosition?.studentPh)?.lastName?.slice(0, 1)}
                  </h2>
                )}
                {userNm(myPosition?.studentPh)?.imageURL && (
                  <img
                    src={userNm(myPosition?.studentPh)?.imageURL}
                    className="w-8 h-8 overflow-hidden rounded-full"
                    alt="pic"
                  />
                )}
              </div>
              <h1>
                {userNm(myPosition?.studentPh)?.firstName}{" "}
                {userNm(myPosition?.studentPh)?.lastName}
              </h1>
            </div>
            <div className="flex items-center gap-x-2">
              <h1 className="font-bold text-lg text-secondary">
                {myPosition?.results}
              </h1>
              <label
                htmlFor="Leader-board-info"
                onClick={() => setLeaderBoardInfo("Leader board info")}
                className="text-[#84e5f6] cursor-pointer font-bold"
              >
                <AiOutlineInfoCircle size={20} />
              </label>
            </div>
          </div>
        </div>
      )}

      <div className="p-3 mt-3 bg-[#f3f9fb] border rounded-xl border-[#eddeff]">
        <div className="flex justify-between items-center">
          <h1 className="text-purple-700 mb-3 mt-1 font-bold text-[24px]">
            Top Position
          </h1>
          {me?.role === "admin" && (
            <input
              type="checkbox"
              onClick={handleActive}
              className="toggle toggle-sm ml-1 toggle-secondary"
              checked={prosActive}
            />
          )}
        </div>
        <div className="">
          {Totalresults?.slice(0, 10).map((i, index) => (
            <div
              key={index}
              className="p-2 py-4 mb-3 px-3 flex items-center justify-between bg-[#f6eafa] border rounded-xl border-[#f0d2fc]"
            >
              <div className="flex items-center gap-x-2">
                <h1 className="font-bold">{index + 1}</h1>
                <div className="w-8 h-8 grid place-content-center rounded-full bg-[#7e16a0] ring ring-[#d583f5] ring-offset-base-100 ring-offset-1">
                  {!userNm(i?.studentPh)?.imageURL && (
                    <h2 className="text-white font-bold">
                      {userNm(i?.studentPh)?.firstName?.slice(0, 1)}
                      {userNm(i?.studentPh)?.lastName?.slice(0, 1)}
                    </h2>
                  )}
                  {userNm(i?.studentPh)?.imageURL && (
                    <img
                      src={userNm(i?.studentPh)?.imageURL}
                      className="w-8 h-8 overflow-hidden rounded-full"
                      alt="pic"
                    />
                  )}
                </div>
                <h1 className="flex items-center">
                  {userNm(i?.studentPh)?.firstName}{" "}
                  {userNm(i?.studentPh)?.lastName}{" "}
                  <span className="font-bold ml-2 text-purple-700">
                    <MdOutlineSchool size={18} />
                  </span>
                </h1>
              </div>
              <div className="flex items-center gap-x-2">
                <h1 className="font-bold text-lg text-purple-700">
                  {i?.results}
                </h1>
                <label
                  htmlFor="Leader-board-info"
                  onClick={() => setLeaderBoardInfo("hello")}
                  className="text-[#e6adfd] cursor-pointer font-bold"
                >
                  <AiOutlineInfoCircle size={20} />
                </label>
              </div>
            </div>
          ))}
          {Totalresults?.length <= 0 && (
            <p className="text-center text-xl font-bold text-purple-700">
              No Position Available
            </p>
          )}
          {Totalresults?.length > 0 && me?.role !== "student" && (
            <div>
              <label
                htmlFor="LeaderBoard-Print"
                onClick={() => setLeaderBoardPrint([Totalresults, xm])}
                className="btn capitalize rounded-3xl border-[3px] border-purple-500 w-full text-lg font-reem bg-purple-700 text-white"
              >
                Download / Print Leader Board
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeardBoard;
