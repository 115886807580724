import React from 'react';
import './Home.css'

const ClassCard = ({classes, setClassDetails}) => {

    return (
        <div className="p-3" data-aos="zoom-in-up" data-aos-delay="100" data-aos-duration="800">
            <div className='rounded-xl p-3 bg-white shadow-box duration-200 hover:-translate-y-1'>
                <div className="rounded-lg">
                    <img src={classes.img} className='rounded-md' alt="" />
                </div>
                <div className="mt-2 font-all">
                    <div className="flex items-center justify-between">
                        <h1 className='text-purple-700 text-xl font-bold '>{classes?.class} শ্রেণী</h1>
                        <h1 className='text-purple-700 font-bold rounded-md px-2 bg-purple-100'>{classes?.students?.length}</h1>
                    </div>
                    <p className='text-sm'>{classes.tittle}</p> 
                    <label onClick={() => setClassDetails(classes)} htmlFor='class-details' className='btn-sm mt-2 btn w-full bg-purple-700 hover:bg-purple-600 border-0 text-white'>বিস্তারিত দেখুন</label>
                </div>
            </div>
        </div>
    );
};

export default ClassCard;